import React, { useEffect, useState } from "react";
import "./OperationsProductInvetoryDetail.scss";
import {
  createMassiveTransferOfProducts,
  createMovementOfProductInventory,
  getProductInventoryDetail,
} from "../../../services/utilsProductInventory";
import AddTaskIcon from '@mui/icons-material/AddTask';

const OperationsProductInvetoryDetail = (props) => {
  const selectedSalePoint = useState(props.selectedSalePoint);
  const productInfo = props.productInfo;
  const [productInventoryInfo, setProductInventoryInfo] = useState(
    props.productInfo
  );
  const product = props.product;
  const salePoints = props.salePoints;
  const [inputValue, setInputValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState("adicion");
  const [salepointsOut, setSalepointsOut] = useState([]);
  const [comment, setComment] = useState("");
  const [selectedDestinySalePoint, setSelectedDestinySalePoint] = useState(
    props.selectedSalePoint
  );
  const [selectedSalePointName, setSelectedSalePointName] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [effective, setEffective] = useState(false);

  const [supervisorProfile, setSupervisorProfile] = useState(
    () => JSON.parse( window.localStorage.getItem("profile")) 
  )

  const verbs = {
    adicion: "Adicionar",
    traslado: "Trasladar",
    baja: "Retirar",
  };

  const handleChangeDestinySalePoint = (event) => {
    console.log(event.target.value);
    for (let i = 0; i < salePoints.length; i++) {
      if (salePoints[i].id === parseInt(event.target.value)) {
        setSelectedDestinySalePoint(salePoints[i]);
        getProductInventoryDetail(
          props.productInfo.fk_product,
          salePoints[i].id
        ).then((data) => {
          setProductInventoryInfo(data);
          console.log(data);
        });
      }
    }
  };

  const handleOnClick = async (event) => {
    setIsLoading(true);
    if (selectedOption === "adicion") {
      let jsonBody = {
        fk_sale_point: props.selectedSalePoint.id,
        fk_product: productInfo.fk_product,
        type: 1,
        quantity: inputValue,
        description: comment,
        fk_worker: supervisorProfile.profile.user.supervisor.id,
      };
      await createMovementOfProductInventory(JSON.stringify(jsonBody)).then(
        (data) => {
          setEffective(true);
        }
      );
    } else if (selectedOption === "traslado") {
      let jsonBody = {
        fk_sale_point_source: props.selectedSalePoint.id,
        fk_sale_point_destination: selectedDestinySalePoint.id,
        transaction_type: 0,
        fk_supervisor: supervisorProfile.profile.user.supervisor.id,
        products: [
          {
            fk_product: props.productInfo.fk_product,
            quantity: inputValue,
          },
        ],
      };
      await createMassiveTransferOfProducts(JSON.stringify(jsonBody)).then(
        (data) => {
          setEffective(true);
        }
      );
    } else {
      let jsonBody = {
        fk_sale_point: props.selectedSalePoint.id,
        fk_product: productInfo.fk_product,
        type: 2,
        quantity: -inputValue,
        description: comment,
        fk_worker: supervisorProfile.profile.user.supervisor.id,
      };
      console.log("Aaaaaaaaaaaaaaa");
      await createMovementOfProductInventory(JSON.stringify(jsonBody)).then(
        (data) => {
          setEffective(true);
        }
      );
    }
    console.log("tiomeout");
    timeOut();
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  function timeOut() {
    setTimeout(function() {
        window.location.reload();
    }, 3000);
}

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      const numericValue = parseFloat(value);
      if (numericValue >= 0) {
        setInputValue(numericValue);
      }
    }
    console.log(inputValue);
  };

  useEffect(() => {
    var outSalePoints = salePoints.filter(function (object) {
      return object !== selectedSalePoint;
    });
    setSalepointsOut(outSalePoints);
  }, []);

  return (
    <div className="main-option-inventory-product">
      <FloatingLoadingMessage isLoading={isLoading} effective={effective}/>
      <div className="option-inventory-product-options">
        <label className="input-label-inventory-product">
          <input
            type="radio"
            value="adicion"
            checked={selectedOption === "adicion"}
            onChange={() => setSelectedOption("adicion")}
            className="input-radio-pi"
          />
          Adición
        </label>
        <label className="input-label-inventory-product">
          <input
            type="radio"
            value="traslado"
            checked={selectedOption === "traslado"}
            onChange={() => setSelectedOption("traslado")}
            className="input-radio-pi"
          />
          Traslado
        </label>
        <label className="input-label-inventory-product">
          <input
            type="radio"
            value="baja"
            checked={selectedOption === "baja"}
            onChange={() => setSelectedOption("baja")}
            className="input-radio-pi"
          />
          Baja
        </label>
      </div>
      <div className="p-i-o-quantity">
        <h9>Cantidad Actual</h9>
        <h6>{productInfo.quantity} UND</h6>
      </div>
      <div className="p-i-o-quantity-change">
        <div className="p-i-o-quantity-change-name">
          Cantidad a {verbs[selectedOption]}
        </div>
        <div className="p-i-o-quantity-change-input">
          <input
            type="number"
            className="small-input"
            value={inputValue}
            onChange={handleInputChange}
          />
          <h6>UND</h6>
        </div>
      </div>
      {selectedOption === "traslado" && (
        <>
          <div className="product-inventory-sale-points">
            <div className="product-inventory-sale-points-content">
              Puntos de Destino
              <select
                value={selectedDestinySalePoint.id}
                onChange={handleChangeDestinySalePoint}
                className="sale-point-form-select"
              >
                {salePoints
                  .filter(function (object) {
                    return object !== selectedSalePoint;
                  })
                  .map((salePoint, index) => (
                    <option key={index} value={salePoint.id}>
                      {salePoint.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </>
      )}

      <div className="p-i-o-quantity-comment">
        Comentario
        <textarea
          className="p-i-o-quantity-comment-space"
          value={comment}
          onChange={handleCommentChange}
        />
      </div>
      <div>
        <div className="p-i-o-quantity-new">
          <h6>Nueva cantidad en {props.selectedSalePoint.name}</h6>
          <span>
            {selectedOption === "adicion"
              ? productInfo.quantity + inputValue
              : productInfo.quantity - inputValue}{" "}
            UND
          </span>
        </div>
        {selectedOption === "traslado" && (
          <div className="p-i-o-quantity-new">
            <h6>Nueva cantidad en {selectedDestinySalePoint.name}</h6>
            <span>{(productInventoryInfo.quantity?productInventoryInfo.quantity:0) + inputValue} UND</span>
          </div>
        )}
      </div>
      <div className="p-i-buttonaction" onClick={handleOnClick}>
        <button>Efectuar Acción</button>
      </div>
    </div>
  );
};

const FloatingLoadingMessage = ({ isLoading , effective}) => {
  return (
      isLoading && (
          <div className="floating-loading-message-prod">
              {effective ? <div><AddTaskIcon></AddTaskIcon></div>:<div className="loading-bar-prod"></div>}
              {effective ? <p>Acción Exitosa</p> : <p>Efectuando Acción...</p>}
          </div>
      )
  );
};

export default OperationsProductInvetoryDetail;
