import React from "react";
import { Link } from "react-router-dom";
import WorkerDefaultImg from "../../../assets/market-icons/avatar-worker.jpg";
import EditIcon from "../../../assets/icons/edit-icon-24px.svg";
import SeeIcon from "../../../assets/icons/baseline-info-24px.svg";
import "./OperatorCard.scss";

const WorkerCard = ({worker}) => {
  console.log(worker)
  let name = worker["user"].last_name
    ? worker["user"].first_name +
      " " +
      worker["user"].last_name
    : worker["user"].first_name;
  let profileImg = worker.image
    ? worker.image
    : WorkerDefaultImg;

  let goWorker = () => {
    localStorage.setItem("fk_worker", worker.id);
  };
  return (
    <div className="gridempleado card center">
      <img src={profileImg} className="card-img-top round" alt={name + "_img"} />
      <div className="card-body informacionempleado row">
        <div className="textotarjetaempleado col">
          <div className="nombreempleado">{name}</div>

        </div>
        <div className="icontarjeta col-3">
{/**        <Link className="" to={`/trabajadores/${name}`} onClick={goOperator}>
            <img src={SeeIcon} alt="see_icon" />
          </Link>  */}
          <Link className="" to={`/trabajadores/${name}/edit`} onClick={goWorker}>
            <img src={EditIcon} alt="edit_icon" />
          </Link>
          
        </div>
      </div>
    </div>
  );
};

export default WorkerCard;
