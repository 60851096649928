import * as momentTZ from "moment-timezone";

function convertUTCtoLocalTimeString(date) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let utcDate = momentTZ.utc(date);
  let clientDate = momentTZ.tz(utcDate, tz);
  return clientDate.format("YYYY-MM-DD hh:mm A").toString();
}

function convertUTCtoLocalTimeStringMovements(date) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let utcDate = momentTZ.utc(date);
  let clientDate = momentTZ.tz(utcDate, tz);
  return clientDate.format("DD MMM YYYY (hh:mm A)").toString();
}
// function convertUTCtoLocalTimeString(utcDateString) {

//   const utcDate = new Date(utcDateString);
//   const timeZoneOffset = utcDate.getTimezoneOffset() * 60 * 1000;
//   const localDate = new Date(utcDate.getTime() + timeZoneOffset);

//   const options = {
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit',
//     hour: 'numeric',
//     minute: '2-digit',
//     hour12: true
//   };

//   const formattedDateString = new Intl.DateTimeFormat(undefined, options).format(localDate);
//   return formattedDateString;
//   }
export { convertUTCtoLocalTimeString, convertUTCtoLocalTimeStringMovements };
