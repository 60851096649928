import { FormattedMessage } from "react-intl";
import "./RelatedCoursesComponent.scss";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import MultiSelectAutocomplete from "../../../components/MultiSelectAutocomplete/MultiSelectAutocomplete";
import {
  associateCourseSalePoint,
  disassociateCourseSalePoint,
} from "../../../services/utilsCourses/utilsCourses";
import { getCoursesBySalePoint } from "../../../services/utilsSalePoint";
import { set } from "date-fns";

const RelatedCoursesComponent = (props) => {
  const courses = props.courses;

  const organizationCourses = props.organizationCourses;

  const [loading, setLoading] = React.useState(false);

  function uniques(listA, listB) {
    return listA.filter(objectA => {
      return !listB.some(objectB => objectB.id === objectA.id);
    });
  }

  const orgCourses = () => {
    const list1 = uniques(courses.courses, organizationCourses.courses);

    let transformedData = list1.map((spt) => {
      return { label: spt.name, value: spt.id + "" };
    });
    props.setOrganizationCourses(transformedData);
  };

  const handleRemoveCourse = (indexToRemove) => {
    setLoading(true);
    let body = {
      fk_course: courses[indexToRemove].id,
      fk_sale_point: props.salePointId,
    };
    disassociateCourseSalePoint(JSON.stringify(body)).then((data) => {
      if (data.hasOwnProperty("error")) {
        // console.log("El objeto tiene la llave 'error'");
      } else {
        // console.log("El objeto NO tiene la llave 'error'");
        getCoursesBySalePoint(props.salePointId).then((data) => {
          props.setCourses(data.courses);
          console.log(data.courses);
          setLoading(false);
          // orgCourses();
        });
      }
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    // console.log(props.selectedCourses);
    for (let i = 0; i < props.selectedCourses.length; i++) {
      const course = props.selectedCourses[i];
      // console.log(course.value);
      let body = {
        fk_course: course.value,
        fk_sale_point: props.salePointId,
      };
      associateCourseSalePoint(JSON.stringify(body))
        .then((data) => {
          if (data.hasOwnProperty("error")) {
            // console.log("El objeto tiene la llave 'error'");
          } else {
            // console.log("El objeto NO tiene la llave 'error'");
            getCoursesBySalePoint(props.salePointId).then((data) => {
              props.setCourses(data.courses);
              console.log(data.courses);
              setLoading(false);
              // orgCourses();
            });
          }
        })
        .catch((error) => {
          console.log("fallo");
        });
      props.setSelectedCourses(null);
    }
  };

  return (
    <div className="col">
      <div className="row">
        <div className="col-12">
          <span className="section-title">Cursos Relacionados</span>
        </div>
      </div>
      <div className="row">
        <div className="col-9">
          <MultiSelectAutocomplete
            options={organizationCourses}
            setSelectedOptions={props.setSelectedCourses}
          />
        </div>
        <div className="col-3">
          <button className="btn btn-add" onClick={handleSubmit}>
            <FormattedMessage id="addIngredientLbl" />
          </button>
        </div>
      </div>
      <div className="row courses-list">
        {loading ? (
          <>
            <div className="d-flex justify-content-center spinner-container">
              <div className="spinner-border spinner-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            {courses.length > 0 ? (
              <ul>
                {courses.map((item, index) => (
                  <li>
                    <div className="row course-item">
                      <div className="col-9">
                        <span>{item.name}</span>
                      </div>
                      <div className="col-3">
                        <span onClick={() => handleRemoveCourse(index)}>
                          <ClearIcon />
                        </span>
                      </div>
                    </div>
                    {/* <hr className="hr-null"></hr> */}
                  </li>
                ))}
              </ul>
            ) : (
              <span>No hay cursos relacionados</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RelatedCoursesComponent;
