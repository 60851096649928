import {
  URL_DOMAIN,
  URL_DOMAIN_MARKET,
  getJSON,
  URL_P2,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut
} from "../globals";

const URL_FRANCHISES = URL_DOMAIN + URL_P2[0];

const supervisorId = localStorage.getItem("fk_supervisor");

const organizationId = localStorage.getItem("fk_organization")
/**
 * Franchises
 * @returns    
 */

const getSalePointsOfOrganization = async () => {
  
  return fetch(
    URL_DOMAIN +
      "sale-point/get-sale-points?fk_organization=" +
      organizationId,
    requestOptionsGet
  ).then(getJSON);
};

const getFranchisesById = async (id) => {
  return fetch(
    URL_DOMAIN + "sale-point/get-sale-point-detail?fk_sale_point=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const postFranchise = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(URL_DOMAIN  + "sale-point/create-sale-point", requestOptionsPost).then(
    getJSON
  );
};

const putFranchise = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_DOMAIN + "sale-point/edit-sale-point", requestOptionsPut).then(
    getJSON
  );
};

const put2forTestFranchise = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_FRANCHISES + "/edit-franchise", requestOptionsPut).then(
    getJSON
  );
};
export { getSalePointsOfOrganization, getFranchisesById, postFranchise, putFranchise };
