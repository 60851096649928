import { useContext, useCallback, useState } from "react";
import Context from "../../context/UserContext";
import { logInService, logOut } from "./utilsUser";

export const useUser = () => {
  const { token, setToken, supervisorId, setSupervisorId } = useContext(Context);

  const [worngCredential, setCredentialStatus] = useState(null);

  const logIn = useCallback(
    (inputs) => {
      //setToken("test");

      var formdata = new FormData();
      formdata.append("username", inputs.username);
      formdata.append("password", inputs.password);
      logInService(formdata).then((data) => {
        if (data.error !== undefined) {
          //console.log("Uusario y contraseñan erroneos");
          setCredentialStatus(true);
        } else {
          setToken(data.token);
          
          localStorage.setItem("bckt", data.token);

          localStorage.setItem("profile", JSON.stringify(data));
          if(data["profile"].role === 1){
            // localStorage.setItem(
            //   "fk_supervisor",
            //   data["profile"]["companies"][0]["supervisor"].id
            // );
            localStorage.setItem("fk_organization",  data["profile"]["organization"]["id"])
            localStorage.setItem("profilePicture", data.profile.user.supervisor.image);
            localStorage.setItem("demo_progress",data["profile"]["organization"].demo_progress );
            localStorage.setItem("suspension",data["profile"]["organization"].suspension )

          }
          
          localStorage.setItem("role", data["profile"].role)
          window.location.reload();
        }
      });
    },
    [setToken]
  );

  const logout = useCallback(() => {
    //Aca vamos a llamar al login in api jruuah back
    // Borrar el token de la base de datos
    logOut();
    setToken(null);
    localStorage.clear();
    window.location.pathname = "/";
  }, [setToken]);

  return {
    isLogged: Boolean(token),
    logIn,
    logout,
    worngCredential,
    setCredentialStatus
  };
};

export default useUser;
