import {
    URL_DOMAIN_MARKET,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut, getJSON, URL_DOMAIN, URL_DOMAIN_SUPERADMIN
} from './globals';

const getOrganizationById = async (id) =>{
    return fetch( URL_DOMAIN_SUPERADMIN + "organizations/get-organization?id="+ id,
        requestOptionsGet).then(getJSON);
};

const getOrganizations = async (page, rowsPerPage, searchQuery) => {
    let url = `${URL_DOMAIN_SUPERADMIN}organizations/get-all-organization?page_size=${rowsPerPage}&page_number=${page+1}`
    if (searchQuery !== null) {
        url += `&query_string=${searchQuery}`
    }
    return fetch(
        url,
        requestOptionsGet
    ).then(getJSON);
};

const postOrganization = async (formData) => {
    requestOptionsPost.body = formData;
    return fetch(URL_DOMAIN_SUPERADMIN + "organizations/create-organization", requestOptionsPost).then(
        getJSON
    );
};

const putOrganization = async (formData) => {
    requestOptionsPut.body = formData;
    return fetch(URL_DOMAIN_SUPERADMIN + "organizations/edit-organization", requestOptionsPut).then(
        getJSON
    );
}; 

const getOrganizationsSimple= async () => {
    return fetch(
        URL_DOMAIN_SUPERADMIN + "organizations/get-organizations-simple",
      requestOptionsGet
    ).then(getJSON);
  };


export { getOrganizationById, getOrganizations, postOrganization, putOrganization, getOrganizationsSimple }