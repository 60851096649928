let labelCustomerType = (customerType) => {
    switch (customerType) {
      case 0:
        return "Acudiente";
      case 1:
        return "Empleado";
      case 3:
        return "Empleado + Papa";
      case 4:
        return "Externo";
      default:
        return "Acudiente";
    }
  };

  let getRangeDate = (startDate = "", endDate = "") => {
    console.log(startDate, endDate);
    if (startDate && endDate) {
      let sDate = startDate.split(" ");
      let eDate = endDate.split(" ");
      // Replacing hyphens with slashes
      let formattedStartDate = sDate[0].replace(/-/g, "/");
      let formattedEndDate = eDate[0].replace(/-/g, "/");
      return formattedStartDate + " - " + formattedEndDate;
    }
  };

  let formatCurrency = (number) => {
    // Convert number to a string, add commas for thousands separator, and fix to 2 decimal places
    let formattedNumber = Number(number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    // Add dollar sign
    return "$" + formattedNumber;
  };

  export {formatCurrency, getRangeDate, labelCustomerType}