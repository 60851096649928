import {
  URL_DOMAIN,
  URL_DOMAIN_MARKET,
  getJSON,
  URL_P2,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsGet,
} from "./globals";

const getTotalSalesSalePointType = async (
  fkOrganization,
  startDate = "2023-02-16 05:00:00",
  endDate = "2023-02-17 04:59:59"
) => {
  try {
    const response = await fetch(
      URL_DOMAIN +
        "information/total-sales-for-sale-point-type?fk_organization=" +
        fkOrganization +
        "&start_date=" +
        startDate +
        "&end_date=" +
        endDate,
      requestOptionsGet
    );

    if (!response.ok) {
      throw new Error("Error al obtener los datos de ventas");
    }

    return getJSON(response);
  } catch (error) {
    console.log("qwerty");
    throw new Error("Error al obtener los datos de ventas: " + error.message);
  }
};

const getBestSellingProduct = async (fkSalePointType, startDate, endDate) => {
  return fetch(
    URL_DOMAIN +
      "information/best-selling-product?fk_sale_point_type=" +
      fkSalePointType +
      "&start_date=" +
      startDate +
      "&end_date=" +
      endDate,
    requestOptionsGet
  ).then(getJSON);
};

const getSalePointTypeTransaction = async (
  fkSalePointType,
  startDate,
  endDate
) => {
  try {
    const response = await fetch(
      URL_DOMAIN +
        "information/transactions-for-sale-point-type?" +
        "start_date=" +
        startDate +
        "&end_date=" +
        endDate +
        "&fk_sale_point_type=" +
        fkSalePointType,
      requestOptionsGet
    );
    if (!response.ok) {
      throw new Error("Error al obtener los datos de ventas");
    }

    return getJSON(response);
  } catch {
    throw new Error("Error al obtener los datos de ventas: " );
  }
};

const getTransactionForSalePointType = async (
  fkSalePointType,
  startDate,
  endDate
) => {
  return fetch(
    URL_DOMAIN +
      "information/transactions-for-sale-point-type?start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&fk_sale_point_type=" +
      fkSalePointType,
    requestOptionsGet
  ).then(getJSON);
};

const getTotalSales = async (fkOrganization, fkSalePointType = null, startDate, endDate) => {
  const url =
    URL_DOMAIN +
    "information/get-total-sales?start_date=" +
    startDate +
    "&end_date=" +
    endDate +
    (fkSalePointType !== null ? "&fk_sale_point_type=" + fkSalePointType : "") +
    "&fk_organization=" +
    fkOrganization;

  return fetch(url, requestOptionsGet).then(getJSON);
};

const getTotalPreSales = async (fkOrganization, fkSalePointType = null, startDate, endDate) => {
  const url =
    URL_DOMAIN +
    "information/get-total-presales?start_date=" +
    startDate +
    "&end_date=" +
    endDate +
    (fkSalePointType !== null ? "&fk_sale_point_type=" + fkSalePointType : "") +
    "&fk_organization=" +
    fkOrganization;

  return fetch(url, requestOptionsGet).then(getJSON);
};

const getBestSellingProducts = async (fkOrganization, fkSalePointType = null, startDate, endDate) => {
  const url =
    URL_DOMAIN +
    "information/get-best-selling-products?start_date=" +
    startDate +
    "&end_date=" +
    endDate +
    (fkSalePointType !== null ? "&fk_sale_point_type=" + fkSalePointType : "") +
    "&fk_organization=" +
    fkOrganization;

  return fetch(url, requestOptionsGet).then(getJSON);
};

export {
  getTotalSalesSalePointType,
  getBestSellingProduct,
  getSalePointTypeTransaction,
  getTransactionForSalePointType,
  getTotalSales,
  getTotalPreSales,
  getBestSellingProducts
};
