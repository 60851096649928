import {
  URL_DOMAIN,
  URL_DOMAIN_MARKET,
  URL_AUTH,
  URL_HELP,
  getJSON,
  URL_P2,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsGet,
} from "./globals";

const getAllProductInventoryLastMovement = async (fkSalePoint) => {
  return fetch(
    URL_HELP +
      "inventory/web/all-product-current-inventory-last-movement?fk_sale_point=" +
      fkSalePoint,
    requestOptionsGet
  ).then(getJSON);
};

const getAllProductInventoryForTransfer = async (
  fkSourceSP,
  fkDestinationSP
) => {
  return fetch(
    URL_HELP +
      "inventory/web/get-product-inventory-for-transfer?fk_sale_point_source=" +
      fkSourceSP +
      "&fk_sale_point_destination=" +
      fkDestinationSP,
    requestOptionsGet
  ).then(getJSON);
};
const createMovementOfProductInventory = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "inventory/web/create-movement-of-product-inventory",
    requestOptionsPost
  ).then(getJSON);
};

const createMassiveProductInventoryMovement = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "inventory/web/masive-movement-of-products-inventory",
    requestOptionsPost
  ).then(getJSON);
};
// createMassiveProductInventoryAdd
const createMassiveProductInventoryAdd = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "inventory/web/masive-add-of-products-inventory",
    requestOptionsPost
  ).then(getJSON);
};

const createMassiveTransferOfProducts = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "inventory/web/masive-transfer-of-product-inventory",
    requestOptionsPost
  ).then(getJSON);
};

const getProductInventoryDetail = async (fkProduct, fkSalePoint) => {
  return fetch(
    URL_HELP +
      "inventory/web/get-product-inventory-detail?fk_sale_point=" +
      fkSalePoint +
      "&fk_product=" +
      fkProduct,
    requestOptionsGet
  ).then(getJSON);
};

const getProductInfo = async (fk_product) =>{
  return fetch(
    URL_HELP +
      "inventory/web/get-product-info?fk_product="+fk_product,
    requestOptionsGet
  ).then(getJSON);
}
const getProductInventoryMovements = async (fk_product,fk_sale_point,start_date,end_date) =>{
  return fetch(
    URL_HELP +
      "inventory/web/get-product-inventory-movements?fk_sale_point="+fk_sale_point+"&fk_product="+fk_product+"&start_date="+start_date+"&end_date="+end_date,
    requestOptionsGet
  ).then(getJSON);
}

const createInventoryTemplate = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_DOMAIN + "templates/create-inventory-template",
    requestOptionsPost
  ).then(getJSON);
};
const getTemplatesForcedUpdate = async  (fkSalePoint) => {
  return fetch(
    URL_DOMAIN +
      "templates/get-templates-forced-update?fk_sale_point=" +
      fkSalePoint,
    requestOptionsGet
  ).then(getJSON);
};

const getTemplatesTransfer = async (fk_sale_point_origin,fk_destiny_sale_point) => {
  return fetch(
    URL_DOMAIN + "templates/get-templates-transfer?fk_sale_point_origin="+fk_sale_point_origin+"&fk_sale_point_destiny="+fk_destiny_sale_point,
    requestOptionsGet
  ).then(getJSON);
};

const getTemplateById = async (id) => {
  return fetch(
    URL_DOMAIN + "templates/get-templates-by-id?id=" + id,
    requestOptionsGet
  ).then(getJSON);
};

export {
  getAllProductInventoryLastMovement,
  createMassiveProductInventoryMovement,
  getAllProductInventoryForTransfer,
  getProductInventoryDetail,
  createMassiveTransferOfProducts,
  createMassiveProductInventoryAdd,
  getProductInfo,
  getProductInventoryMovements,
  createMovementOfProductInventory,
  createInventoryTemplate,
  getTemplatesForcedUpdate,
  getTemplatesTransfer,
  getTemplateById
};
