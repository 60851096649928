const RowProduct = ({ product }) => {
    return (
      <div className="row-product">
        <img
          className="row-product__image"
          src={product.image_url}
          alt={product.name}
        />
        <p className="row-product__name">{product.name}</p>
      </div>
    );
  };
  

export default RowProduct;