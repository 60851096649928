import { render } from "@testing-library/react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import FranchiseDefaultImg from "../../../assets/icons/DEFAULT IMG/Franchise_img.jpg";
import DefaultImg from "../../../assets/market-icons/punto-de-venta.jpg";
import "./FranchiseCard.scss";

const SalePointCard = (salePoint) => {
  let name = salePoint["salePoint"].name;
  let image = salePoint["salePoint"].image
    ? salePoint["salePoint"].image
    : DefaultImg;
 
  let salePointTypes;
  if(salePoint["salePoint"]["sale_point_type"].length === 0){
      salePointTypes = "Sin pasillo asignado";
  }else{
      salePointTypes = salePoint["salePoint"]["sale_point_type"][0].name + " +" + (salePoint["salePoint"]["sale_point_type"].length-1)
  }
  console.log(salePoint)
  let goSalePoint =() =>{
    localStorage.setItem("fk_sale_point", salePoint['salePoint'].id);
  };
  return (
    <article className="card franchise-card">
      <Link className="" to ={`/puntos-de-venta/${name}/edit`} onClick={goSalePoint}>
      <img src={image} className="card-img-top" alt="franchise_name_photo" />
      </Link>
      <div className="card-body">
        <h5 className="franchise_name_title">{name}</h5>
          <p className="card-text franchise_type_name">{salePointTypes}</p>

      </div>
    </article>
  );
};

export default SalePointCard;
