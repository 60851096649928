import React, { Component, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import "../../../index.scss";
import "./SalePointListPage.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getSalePointsOfOrganization } from "../../../services/utilsFranchise/utilsFranchise";

import SearchBar from "../../../components/searchBar/searchBar";
import SalePointCard from "../../../components/supervisor/FranchiseCard/SalePointCard";
import FranchiseTypeListPage from "../../FranchiseType/FranchiseTypeListPage/FranchiseTypeListPage";
import InvoiceSchemaListPage from "../../Invoice Schemas/InvoiceSchemaListPage/InvoiceSchemaListPage";
import SignalWifiConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiConnectedNoInternet4";

const SalePointListPage = () => {
  //Lest make the franchise fetch
  // This is info that is gonna change with the dinnaymic is only for know

  const [inputSalePoint, setInputSalePoint] = useState("");
  const [salePointListDefault, setSalePointListDefault] = useState([]);
  const [salePointList, setSalePointList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const fetchData = async () => {
    getSalePointsOfOrganization()
      .then((data) => {
        //setFranchisesList(data["franchises"]);
        setSalePointList(data["sale_points"]);
        setSalePointListDefault(data["sale_points"]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoadingError(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateInputFranchise = async (input) => {
    const filtered = salePointListDefault.filter((franchise) => {
      return franchise.name.toLowerCase().includes(input.toLowerCase());
    });
    setInputSalePoint(input);
    setSalePointList(filtered);
  };
  return (
    <div>
      {!loading && !loadingError &&(
        <div>
          {salePointListDefault && (
            <SearchBar
              input={inputSalePoint}
              onChange={updateInputFranchise}
              nombre="Puntos de venta"
            />
          )}
          <div className="franchises-continer">
            {salePointList &&
              salePointList.map((salePoint, i) => (
                <SalePointCard salePoint={salePoint} key={i}></SalePointCard>
              ))}
          </div>
        </div>
      )}
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loading && loadingError && (
        <div className="center-container-wifi">
          <div className="wifi">
            <SignalWifiConnectedNoInternet4Icon />
          </div>
          <p>
            Lo sentimos, parece que no tienes conexión a Internet en este
            momento.
          </p>
        </div>
      )}
    </div>
  );
};

export default SalePointListPage;
