import { useState } from "react";
import Select from "react-select";

const MultiSelectAutocomplete = ({ options, setSelectedOptions }) => {
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };
  const customStyles = {
    option: (base, state) => ({
      ...base,
      borderRadius: state.isSelected ? "20px" : "3px",
      backgroundColor: state.isSelected ? "#3f51b5" : "#ffffff",
      color: state.isSelected ? "#ffffff" : "#000000",
      "&:hover": {
        backgroundColor: "#3f51b5",
        color: "#ffffff",
      },
    }),
  };

  return (
    <Select
      required
      isMulti
      options={options}
      placeholder=""
      styles={customStyles}
      onChange={handleChange}
    />
  );
};

export default MultiSelectAutocomplete;
