import "./DemoMain.scss";

import "../../index.scss";
import "../../globalFormStyle.scss";
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import KuruahLogo from "../../assets/icons/kuruah-logo.svg";
import RestauantImg from "../../assets/icons/DEFAULT IMG/restaurantImg.PNG";
import StoreImg from "../../assets/icons/DEFAULT IMG/storeImg.PNG";
import KuruahAppLogo from "../../assets/icons/ICONS-LOGO.jpeg";
import WorkerForm from "../Operator/WorkerForm/WorkerForm";
import { useContext, useEffect, useState } from "react";
import Context from "../../context/UserContext";
import {
  editDemoProgress,
  postDemoCompany,
} from "../../services/utilsCompany/utilsCompany";
import useUser from "../../services/usersManage/useUser";

export const DemoMain = () => {
  const { isLogged, logout } = useUser();
  
  const { userRole, demoProgress, setDemoProgress } = useContext(Context);

  const handleClickLogOut = (e) => {
    logout();
  };

  const { supervisorProfile } = useContext(Context);

  //const [demoProgress, setDemoProgress] = useState(1);

  const [demoName, setDemoName] = useState("");

  const [demoRestaurant, setDemoRestaurant] = useState(true);

  const [demoSupermarket, setDemoSupermarket] = useState(false);

  const [operatorDemo, setOperatorDemo] = useState({});

  let nexStepDemo = () => {
    let demo = demoProgress;
    let formDataEditBody = new FormData();
    let companyId = supervisorProfile.profile.companies[0].id;
    formDataEditBody.append("id", companyId);
    if (demo === "2") {
      //setDemoProgress(1);
      formDataEditBody.append("demo_progress", 3);
      editDemoProgress(formDataEditBody).then((data) => {});
      window.localStorage.setItem("demo_progress","3");
      setDemoProgress("3");
      window.location.pathname = "/home";
    } else {
      
      setDemoProgress("2");
      formDataEditBody.append("demo_progress", 2);
      editDemoProgress(formDataEditBody).then((data) => {});
    }

    
  };
  useEffect(() => {
    console.log("THIS IS USEEFFECT",demoProgress);

    if (
      supervisorProfile.profile.companies[0].demo_progress === 3 ||
      demoProgress === 3
    ) {
      window.location.pathname = "/home";
    }
  }, [demoProgress, supervisorProfile]);

  const handleInputChangeInner = (event) => {
    setDemoName(event.target.value);
  };

  const handleChangeCheckBox = (event) => {
    //setProduct({ ...product, [event.target.name]: event.target.checked });
    if (event.target.name === "stationery") {
      setDemoRestaurant(false);
      setDemoSupermarket(true);
    } else {
      setDemoRestaurant(true);
      setDemoSupermarket(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let supervisorId = localStorage.getItem("fk_supervisor");
    let companyId = supervisorProfile.profile.companies[0].id;
    let type = "";
    if (demoRestaurant) {
      type = "restaurant";
    } else if (demoSupermarket) {
      type = "stationery";
    }

    let name = demoName;

    let formDataBody = new FormData();
    formDataBody.append("name", name);
    formDataBody.append("type", type);
    formDataBody.append("fk_supervisor", supervisorId);
    formDataBody.append("fk_company", companyId);

    postDemoCompany(formDataBody).then((data) => {
      if (!data.error) {
        console.log(data.demo.operator);
        let operadotUser = data.demo.operator;
        setOperatorDemo(operadotUser);
        nexStepDemo();
      }
    });
  };
  return (
    <main>
      <nav class="navbar navbar-demo">
        <img src={KuruahLogo} alt="kuruah-logo" className="kuruah-Logo" />
      </nav>
      {demoProgress === "1" && (
        <article className="grid-center">
          <form
            action=""
            className="grid-center content-size"
            onSubmit={handleSubmit}
          >
            <section className="grid-center">
              <h2>
                <FormattedMessage id="welcomeLbl" />
                {supervisorProfile.profile.user.first_name}!
              </h2>
              <h4>
                <FormattedMessage id="kindBusinessLbl" />
              </h4>
            </section>
            <section className="row container-margin">
              <div className="col-6">
                <div className="grid-center">
                  <img
                    src={RestauantImg}
                    alt="restauant-img"
                    className="img-logo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={demoRestaurant}
                        name="restaurant"
                        onChange={handleChangeCheckBox}
                      />
                    }
                    label={<FormattedMessage id="gastronomicLbl" />}
                  />
                  <p>
                    <FormattedMessage id="gastronomicDescription" />
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="grid-center">
                  <img src={StoreImg} alt="store-img" className="img-logo" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={demoSupermarket}
                        name="stationery"
                        onChange={handleChangeCheckBox}
                      />
                    }
                    label={<FormattedMessage id="storeLbl" />}
                  />
                  <p>
                    <FormattedMessage id="storeDescription" />
                  </p>
                </div>
              </div>
            </section>
            <section className="grid-center">
              <h4>
                <FormattedMessage id="franchiseNameLbl" />
              </h4>
              <TextField
                className="form-control text-field-margin"
                name="name"
                required
                onChange={handleInputChangeInner}
              />

              <button className="btn btn-outline-principal m-2">
                <FormattedMessage id="startLbl" />
              </button>
            </section>
          </form>
        </article>
      )}

      {demoProgress === "2" && (
        <article className="grid-center">
          <section className="grid-center">
            <img
              src={KuruahAppLogo}
              alt="restauant-img"
              className="img-logo info-margin"
            />
            <h2 id="all-ready">
              <FormattedMessage id="allReadyLbl" />
            </h2>
          </section>
          <section className="row ready-to-start-container-size">
            <div className="col-6">
              <p className="text-center">
                <FormattedMessage id="operatorDescriptionDemo" />
              </p>
              <span></span>
              <h2 className="text-center font-color">
                {" "}
                <FormattedMessage id="usernameLbl" /> {operatorDemo.username}
              </h2>
              <h2 className="text-center font-color">
                {" "}
                <FormattedMessage id="passwordDemoLbl" />{" "}
                {operatorDemo.password}
              </h2>
            </div>
            <div className="col-6">
              <p className="text-center">
                <FormattedMessage id="supervisorDescriptionDemo" />
              </p>
              <h2 className="text-center font-color">
                {supervisorProfile.profile.user.username}
              </h2>
            </div>
          </section>

          <button
            className="btn btn-outline-principal m-2"
            onClick={nexStepDemo}
          >
            <FormattedMessage id="readyLbl" />
          </button>
        </article>
      )}

      <div className="grid-end">
        <Link onClick={handleClickLogOut} to={`/`}>
          <button className="btn btn-outline-primary m-2 log-outbutton-fix">
            <FormattedMessage id="logOutTitle" />
          </button>
        </Link>
      </div>
    </main>
  );
};

/**
 * This code is for operatorForm, and all ready to make an operator
 */

/**
 *       {demoProgress === 3 && (
        <>
          <WorkerForm />
        </>
      )}
      {demoProgress === 4 && (
        <article className="grid-center ">
          <section className="grid-center ready-size">
            <h2> ¡Ya casi estás listo!</h2>
            <img
              src={RestauantImg}
              alt="restauant-img"
              className="img-logo info-margin"
            />
            <p className="text-center">
              Hemos configurado un negocio de tipo "Gastronómico" para ti.
              Puedes agregar o cambiar el catálogo de productos cuando quieras.
            </p>
            <h4 className="text-center">
              A continuación, vas a crear un usuario de tipo "Trabajador", desde
              el que podrás reportar ventas con la aplicación "Kurúah" para
              dispositivos Android.
            </h4>

            <button
              className="btn btn-outline-principal m-2"
              onClick={nexStepDemo}
            >
              <FormattedMessage id="createWorkerLbl" />
            </button>
          </section>
        </article>
      )}
 */
