import { Link } from "react-router-dom";
import Select from "react-select";

import EditIcon from "../../assets/icons/baseline-edit-purple-24px.svg";
import DeleteIcon from "../../assets/icons/baseline-close-24px.svg";

import "./AddRelationSection.scss";
import "../../index.scss";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import  { LinearProgress} from "@material-ui/core";

const ItemRelation = ({
  item,
  products = undefined,
  id,
  handleSubmitDelete,
}) => {
  return (
    <div className="row item-padding relation-item-container">
      <div className="col item-title-padding">
        <div className="row ">
          <h6 className="item-title">{item.name}</h6>
          {products && (
            <p className="item-sub-info">{products ? products : ""}</p>
          )}
        </div>
      </div>
      <div className="col-3 margin-auto icons-parent">
        <Link
          to={"/ingredientes/" + "/edit"}
          style={{ textDecoration: "none" }}
        >
          <a
            href={"/ingredientes/" + "/edit"}
            className="item-icon"
            onClick={(event, i) => {}}
          >
            <img src={EditIcon} alt="" className="" />
          </a>
        </Link>
        {handleSubmitDelete ? (
          <a
            href="/"
            className="item-icon"
            onClick={(event, i) => {
              event.preventDefault();
              handleSubmitDelete(item.value);
            }}
          >
            <img src={DeleteIcon} alt="" className="" />
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const AddRelationForm = ({ options,selectedOption, setSelectedOptions, handleSubmit }) => {
  // const [selectedOptions, setSelectedOptions ] = useState();

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const hanldeSubmit2 = (event) => {
    event.preventDefault();
    handleSubmit();
    // setSelectedOptions("");
  };
  return (
    <form action="" className="add-relation-form" onSubmit={hanldeSubmit2}>
      <div className="full-weigh">
        <Select required  value={selectedOption} options={options} onChange={handleChange} />
      </div>
      <button className="btn btn-primary m-2" type="submit">
        <FormattedMessage id={"addIngredientLbl"} />
      </button>
    </form>
  );
};
const AddRelationSection = ({
  items,
  options,
  selectedOption,
  setSelectedOptions,
  handleSubmitPost,
  handleSubmitDelete,
  urlDeleteRelation,
  submitedRelation
}) => {
  return (
    <>
      {handleSubmitPost && (
        <AddRelationForm
          options={options}
          handleSubmit={() => handleSubmitPost()}
          setSelectedOptions={setSelectedOptions}
          selectedOption={selectedOption}
        />
      )}
      {submitedRelation && <LinearProgress/>}

      <div className="size-32px"></div>
      {items &&
        items.map((item) => {
          return (
            <>
              <ItemRelation
                item={item}
                products={item.products ? item.products : undefined}
                handleSubmitDelete={
                  handleSubmitDelete ? (id) => handleSubmitDelete(id) : undefined
                }
              />
              <div className="size-40px"></div>
            </>
          );
        })}
    </>
  );
};

export default AddRelationSection;
