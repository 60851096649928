import { useEffect, useState } from "react";
import EditPrimaryIcon from "../../../assets/icons/edit-icon-24px.svg";
import CustomTable, { convertToSelect } from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import { getOrganizations } from "../../../services/utilsOrganization";
import { FormattedMessage } from "react-intl";
import SearchBar from "../../../components/searchBar/searchBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const OrganizationList = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------
  const [searchQuery, setSearchQuery] = useState("");

  const [organizations, setOrganizations] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [pagination, setPagination] = useState({});

  const [loading, setLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(true);

  const organizationsTableHeaders = [
    "nameHeader",
    "cityHeader",
    "countryHeader",
    "actionsHeader",
  ];

  const createOrganizationData = (name, city, country, id) => {
    return {
      name,
      city,
      country,
      actions: (
        <Link to={`/organizaciones/${name}/edit`} onClick={() => goOrganization(id)}>
          <img src={EditPrimaryIcon} alt="edit" />
        </Link>
      ),
    };
  };
  const goOrganization = (id) => {
    localStorage.setItem("fk_organization", id);
  };

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------

  const fetchData = async (page, rowsPerPage, searchQuery = null) => {
    setTableLoading(true);
    getOrganizations(page, rowsPerPage, searchQuery).then((data) => {
      let organizationsData = data.organizations.map((organization) =>
        createOrganizationData(
          organization.name,
          organization.city,
          organization.country,
          organization.id
        )
      );

      setOrganizations(organizationsData);
      let paginationObject = data.pagination;
      paginationObject.rowsPerPage = rowsPerPage;
      paginationObject.page = page;
      setPagination(paginationObject);
      setLoading(false);
      setTableLoading(false);
      setPage(page);
      setRowsPerPage(rowsPerPage);
    });
  };

  useEffect(() => {
    fetchData(page, rowsPerPage, "");
  }, []);

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  const handleChangePage = async (newPage) => {
    await fetchData(newPage, rowsPerPage).then(() => {
      setPage(newPage);
    });
  };

  const handleChangeRowsPerPage = async (rowsPerPage) => {
    await fetchData(0, rowsPerPage).then(() => {
      setPage(0);
      setRowsPerPage(rowsPerPage);
    });
  };
  const handleTextSearch = async (input) => {
    setSearchQuery(input);
    fetchData(0, rowsPerPage, input);
    setPage(0);
  };

  return (
    <div>
      <main className="section-body">
        <h1 className="section-title">
          <FormattedMessage id="organizationTitle" />
        </h1>
        <>
          {loading && (
            <div className="d-flex justify-content-center spinner-container">
              <div className="spinner-border spinner-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {!loading && (
            <>
              <SearchBar
                input={searchQuery}
                onChange={handleTextSearch}
                nombre="Organizaciones"
              />
              {tableLoading && <LinearProgress />}
              <CustomTable
                headers={organizationsTableHeaders}
                rows={organizations}
                pagination={pagination}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </>
        <Link to={"organizaciones/create"}>
          <Fab size="small" className="fab" color="secondary" aria-label="edit">
            <AddIcon className="fab-icon" />
          </Fab>
        </Link>
      </main>
    </div>
  );
};

export default OrganizationList;
