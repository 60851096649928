import React, { useState, useEffect } from "react";
import "../../../index.scss";
import "./ProductForm.scss";
import ProductDefaultImg from "../../../assets/market-icons/producto.jpg";
import {
  postProduct,
  getProductById,
  putProduct,
} from "../../../services/utilsProduct/utilsProduct";
import { getCategoriesSimple } from "../../../services/utilsCategories/utilsCategories";
import { getFranchiseTypes } from "../../../services/utilsFranchiseType/utilsFranchiseType";

import { Link, useLocation } from "react-router-dom";
import EditIcon from "../../../assets/icons/white-create-24px.svg";
import { FormattedMessage } from "react-intl";
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import ProductIngredientPage from "../ProductIngredientPage/ProductIngredientPage";
import { Alert } from "@material-ui/lab";

//This is for mobile
import { styled, Box } from "@mui/system";
import Switch from "@mui/material/Switch";

import Modal from "@mui/material/Modal";
import { getAllSalePointType } from "../../../services/utilsSalePointType";
import CreatableSelect from "react-select/creatable";
import TagInput from "../../../components/TagsInput/TagInput";
import {
  associateTagWithProduct,
  getTagsOfOrganization,
  getTagsOfProduct,
  modifyTagWithProduct,
} from "../../../services/utilsTags";
import { set } from "date-fns";
import { useHistory } from "react-router-dom";

const filter = createFilterOptions();

const style = {
  position: "absolute",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "grid",
  placeItems: "center",
};

const ProductForm = () => {
  /**
   * Variables & Constans
   */
  const [product, setProduct] = useState({
    inventory_available: false,
    customer_visible: false,
    quantity_mode: false,
    iva_mode: false,
    ipo_mode: false,
    discount_active: false,
  });

  const [imgSource, setImgSource] = useState(ProductDefaultImg);

  const [loading, setLoading] = useState(true);

  const [formTitle, setFormTitle] = useState("createNewProduct");

  const [submitLabel, setSubmitLabel] = useState("createProductLbl");

  const [inEditSize, setInEditSize] = useState("section-body col");

  const [submitted, setSubmitted] = useState(false);

  const [submittedDelete, setSubmittedDelete] = useState(false);

  const [productCategory, setProductCategory] = useState("");

  const [productCategoriesList, setProductCategoriesList] = useState([]);

  const [salePointTypeList, setSalePointTypeList] = useState([]);

  const [sellPrice, setSellPrice] = React.useState();

  const [disabledSelect, setDisableSelect] = useState(true);

  const [ingredientsOfProduct, setIngredientsOfProduct] = useState([]);

  const hiddenFileInput = React.useRef(null);

  const urlLocation = useLocation();

  let newProfilePicture;

  const [open, setOpen] = React.useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [openErrorModal, setErrorModal] = useState(false);

  const [selectedSpt, setSelectedSpt] = useState([]);

  const [tags, setTags] = useState();

  const [selectedTags, setSelectedTags] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [loadingDelete, setLoadingDelete] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [deleteAvailable, setDeleteAvailable] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  // const handleDeleteClose = () => setOpenDeleteModal(false);

  /**
   * Async calls
   */

  const fetchData = async () => {
    //Product manage in edit
    if (urlLocation.pathname.includes("edit")) {
      let productId = localStorage.getItem("fk_product");
      // let fk_sale_point_types;
      let fk_product_category;
      setFormTitle("editProduct");
      setDeleteAvailable(true);
      setSubmitLabel("saveProductLbl");
      setInEditSize("section-body col-7");

      getProductById(productId).then((data) => {
        let productData = data.product;
        setSellPrice(productData.sell_price / 100);
        setIngredientsOfProduct(productData.ingredients);
        //Manejo de las categorias

        let salePointTypes = productData.product_category.sale_point_types.map(
          (e) => ({
            value: e.sale_point_type.id,
            label: e.sale_point_type.name,
          })
        );
        fk_product_category = productData.product_category.id;
        setProduct({
          ...product,
          name: productData.name,
          fk_sale_point_types: salePointTypes,
          description: productData.description,
          customer_visible: productData.customer_visible,
          inventory_available: productData.inventory_available,
          fk_product_category: productData.product_category.id,
          sell_price: productData.sell_price / 100,
          quantity_mode: productData.quantity_mode,
          barcode: productData.barcode,
          ipo: productData.ipo,
          iva: productData.iva,
          iva_mode: productData.iva_mode,
          ipo_mode: ipoHandler(productData.ipo_mode),
          internal_code: productData.internal_code,
          special_price:
            productData.special_price !== 0
              ? productData.special_price / 100
              : null,
          discount_price:
            productData.discount_price !== 0
              ? productData.discount_price / 100
              : null,
          discount_active: productData.discount_active,
          buy_price:
            productData.buy_price !== 0 ? productData.buy_price / 100 : null,
          sku: productData.sku,
        });
        productData.image
          ? setImgSource(productData.image)
          : setImgSource(imgSource);
        let sptOptions = productData.product_category.sale_point_types.map(
          (e) => ({
            value: e.sale_point_type.id,
            label: e.sale_point_type.name,
          })
        );
        setSalePointTypeList(sptOptions);
        setSelectedSpt(sptOptions);
        setLoading(false);
      });

      getCategoriesSimple().then((data) => {
        let categoriesList = data["product_categories"];
        // let finalCategorieList = categoriesList.filter(function (cat) {
        //   return cat.id === fk_product_category;
        // });
        setProductCategoriesList(categoriesList);
      });

      getTagsOfProduct(productId).then((data) => {
        let tagsTransformed = data.tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
          color: tag.color,
        }));
        setSelectedTags(tagsTransformed);
      });
      let fkOrganization = localStorage.getItem("fk_organization");
      getTagsOfOrganization(fkOrganization).then((data) => {
        let tagsTransformed = data.tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
          color: tag.color,
        }));
        setTags(tagsTransformed);
      });
    } else {
      getCategoriesSimple().then((data) => {
        setProductCategoriesList(data["product_categories"]);
        setLoading(false);
      });

      getAllSalePointType().then((data) => {
        let sptOptions = data.sale_points_type.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        setSalePointTypeList(sptOptions);
      });
      let fkOrganization = localStorage.getItem("fk_organization");
      getTagsOfOrganization(fkOrganization).then((data) => {
        let tagsTransformed = data.tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
          color: tag.color,
        }));
        setTags(tagsTransformed);
      });
    }
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  /**
   * Handlers
   */

  const handleEditImageClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleOnImageChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile != null) {
      setProduct({ ...product, [event.target.name]: event.target.files[0] });
      newProfilePicture = newFile;
      const url = URL.createObjectURL(newProfilePicture);
      setImgSource(url);
    }
  };

  const handleChangeCheckBox = (event) => {
    setProduct({ ...product, [event.target.name]: event.target.checked });
  };

  const handleReturn = (event) => {
    console.log("return");
    setOpenDeleteModal(false);
  };
  const handleConfirmDelete = (event) => {
    console.log("confirm");
    setSubmittedDelete(true);
    setLoadingDelete(true);
    sleep(1000).then((data) => {
      console.log(data);

      setSuccessSnackbar(true);
      setOpenDeleteModal(false);
      setSnackbarMessage(
        <Alert severity="success">Se ha eliminado el operario con éxito</Alert>
      );
      sleep(100).then((data) => {
        setOpenSnackbar(true);
        setSubmittedDelete(false);
      });
    });
    // setSubmittedDelete(false);
    // setOpenDeleteModal(false);
  };

  const handleChangeOfCategory = (value) => {
    //SI ya existia la categoria
    if (value) {
      if (value.sale_point_types) {
        let categoryId = value.id;
        let salePointTypes = value.sale_point_types.map((e) => ({
          value: e.sale_point_type.id,
          label: e.sale_point_type.name,
        }));
        setProduct({
          ...product,
          fk_product_category: categoryId,
          fk_sale_point_types: salePointTypes,
        });
        setSalePointTypeList(salePointTypes);
        setSelectedSpt(salePointTypes);
      } else {
        //setProduct({ ...product, product_category_name: value.inputValue });
        setDisableSelect(false);
      }
    } else {
      setProduct({ ...product, fk_sale_point_type: "" });
    }
    //SI ES UNA NUEVA CATEGORIA
  };

  const handleInputChangeInner = (event) => {
    // console.log({ [event.target.name]: event.target.value });
    setProduct({ ...product, [event.target.name]: event.target.value });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (successSnackbar) {
      history.push("/productos");
    }
  };

  const handleDeleteClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDeleteModal(false);
  };

  const setCloseTime = () => {
    setOpen(false);
  };

  let ipoHandler = (ipo) => {
    if (ipo === 2) return 1;
    else return ipo;
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleDelete = (event) => {
    setSubmittedDelete(true);
    setOpenDeleteModal(true);
    sleep(1000).then(() => {
      setSubmittedDelete(false);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    /**
     * Vamos a hacer el manejo de las categorias
     * 1. Cuando es una categoria antigua
     * 2. Cuando es una categoria nueva -> toca enviar el nombre de la categoria y el tipo de franquicia
     */

    for (const key in product) {
      if (typeof product[key] == File) {
        formDataBody.append(key, product[key], "productPicture.jpg");
      } else if (
        key === "inventory_available" ||
        key === "customer_visible" ||
        key === "iva_mode" ||
        key === "ipo_mode" ||
        key === "quantity_mode" ||
        key === "discount_active"
      ) {
        formDataBody.append(key, product[key] ? 1 : 0);
      } else if (
        key === "fk_sale_point_types" ||
        key === "product_category_name" ||
        key === "fk_product_category"
      ) {
        continue;
      } else if (key === "ipo") {
        if (
          product["ipo_mode"] === 1 &&
          (product["ipo"] === "" ||
            product["ipo"] === undefined ||
            product["ipo"] === 0)
        ) {
          formDataBody.append("ipo", 0.08);
        } else {
          formDataBody.append(key, product[key]);
        }
      } else if (
        key === "sell_price" ||
        key === "special_price" ||
        key === "discount_price" ||
        key === "buy_price"
      ) {
        let sellPrice = product[key];
        // console.log(sellPrice, typeof sellPrice, "This is sall price");
        let finalPrice =
          typeof sellPrice === "string"
            ? sellPrice.replaceAll(",", "")
            : sellPrice;
        if (sellPrice === null) {
          formDataBody.append(key, 0);
        } else {
          formDataBody.append(key, parseInt(finalPrice) * 100);
        }
      } else {
        formDataBody.append(key, product[key]);
      }
    }

    if (urlLocation.pathname.includes("edit")) {
      formDataBody.append(
        "fk_product_category",
        product["fk_product_category"]
      );
      formDataBody.append("fk_product", localStorage.getItem("fk_product"));
      putProduct(formDataBody).then((data) => {
        //TODO
        let idTags = selectedTags.map((tag) => tag.value);

          let jsonBody = {
            fk_product: localStorage.getItem("fk_product"),
            tag_ids: idTags,
          };
          console.log(jsonBody);
          modifyTagWithProduct(JSON.stringify(jsonBody)).then((data) => {
            setOpen(true);
            setSubmitted(false);
          });
        // if (selectedTags.length > 0) {
        //   let idTags = selectedTags.map((tag) => tag.value);

        //   let jsonBody = {
        //     fk_product: localStorage.getItem("fk_product"),
        //     tag_ids: idTags,
        //   };
        //   console.log(jsonBody);
        //   modifyTagWithProduct(JSON.stringify(jsonBody)).then((data) => {
        //     setOpen(true);
        //     setSubmitted(false);
        //   });
        // } else {
        //   setOpen(true);
        //   setSubmitted(false);
        // }
      });
    } else {
      if (!productCategory.sale_point_types) {
        //Check the salePoint type
        let spt_array = selectedSpt.map((spt) => {
          if (spt.value !== 0) {
            return spt.value;
          } else {
            return spt.label;
          }
        });
        formDataBody.append("fk_sale_point_types", spt_array.join(","));
        formDataBody.append("product_category_name", productCategory.title);
      } else {
        //Check the salePoint type
        let spt_array = product["fk_sale_point_types"].map((spt) => {
          if (spt.value !== 0) {
            return spt.value;
          } else {
            return spt.label;
          }
        });
        formDataBody.append("fk_sale_point_types", spt_array.join(","));
        formDataBody.append("fk_product_category", productCategory.id);
      }

      const organizationId = localStorage.getItem("fk_organization");
      formDataBody.append("fk_organization", organizationId);

      postProduct(formDataBody)
      .then((data) => {
        if (!data.error) {
          localStorage.setItem("fk_product", data.product.id);
          //Vamos a asociar el producto y el tag
          if (selectedTags.length === 0) {
            setSubmitted(false);
            setOpenModal(true);
          } else {
            let idTags = selectedTags.map((tag) => tag.value);

            let jsonBody = {
              fk_product: localStorage.getItem("fk_product"),
              tag_ids: idTags,
            };
            modifyTagWithProduct(JSON.stringify(jsonBody))
              .then(() => {
                setSubmitted(false);
                setOpenModal(true);
              })
              .catch((error) => {
                setSubmitted(false);
                setErrorMessage(error.message || "Error al modificar tags");
                setErrorModal(true);
              });
          }
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        setSubmitted(false);
        setErrorMessage(error.message || "Error al crear el producto");
        setErrorModal(true);
      });
    }
  };

  /**
   * Render elements
   */
  return (
    <div className="row" id="container-product-form">
      <main className={inEditSize} id="product-info">
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </Snackbar>
        <h1 className="section-title">
          <FormattedMessage id={formTitle} />
        </h1>
        <article>
          {!loading && (
            <form onSubmit={handleSubmit} className="row g-3">
              <div className="d-flex justify-content-center mb-4">
                <img className="profile-picture" src={imgSource} alt="Avatar" />
                <div className="icon-container d-flex justify-content-center align-items-center">
                  <img
                    className="icon"
                    src={EditIcon}
                    alt="edit_icon"
                    onClick={handleEditImageClick}
                  />
                </div>
              </div>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleOnImageChange}
                name="file"
                hidden="hidden"
                accept=".jpg, .jpeg, .png, .gif"
              />
              <div className="row">
                <div className="col-6 mb-3">
                  <TextField
                    required
                    className="form-control"
                    label={<FormattedMessage id="nameLbl" />}
                    value={product.name ? product.name : ""}
                    name="name"
                    onChange={handleInputChangeInner}
                  />
                </div>
                <div className="col-6 mb-3">
                  <TextField
                    className="form-control"
                    label={<FormattedMessage id="skuLbl" />}
                    value={product.sku ? product.sku : ""}
                    name="sku"
                    onChange={handleInputChangeInner}
                  />
                </div>
              </div>

              <div className="mb-3">
                <TextField
                  className="form-control"
                  label={<FormattedMessage id="descriptionLbl" />}
                  rows={4}
                  multiline
                  value={product.description ? product.description : ""}
                  name="description"
                  onChange={handleInputChangeInner}
                  required
                />
              </div>
              <div className="row">
                {!urlLocation.pathname.includes("edit") && (
                  <div className="col-6 mb-3 adjust-margin-cat">
                    <Autocomplete
                      value={productCategory}
                      onChange={(event, newValue) => {
                        handleChangeOfCategory(newValue);
                        if (typeof newValue == "string") {
                          setProductCategory({
                            title: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          setProductCategory({
                            title: newValue.inputValue,
                          });
                        } else {
                          setProductCategory(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="category-input"
                      options={productCategoriesList}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }

                        if (option.title) {
                          return option.title;
                        }

                        // Regular option
                        return option.name;
                      }}
                      renderOption={(option) => (
                        <React.Fragment>
                          <div>
                            {option.name}
                            <br />
                            <p className="option_subtitle">
                              {" "}
                              {option["sale_point_type"]
                                ? option["sale_point_type"].name
                                : ""}
                            </p>
                          </div>
                        </React.Fragment>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={<FormattedMessage id="categoryLbl" />}
                        />
                      )}
                    />
                  </div>
                )}

                {urlLocation.pathname.includes("edit") && (
                  <div className="col-6 mb-3">
                    <label className="form-label" htmlFor="franchiseInput">
                      <FormattedMessage id="categoryLbl" />
                    </label>
                    <Select
                      required
                      value={
                        product.fk_product_category
                          ? product.fk_product_category
                          : ""
                      }
                      onChange={handleInputChangeInner}
                      name="fk_product_category"
                      className="worker-form-select"
                      id="categoryInput"
                    >
                      {productCategoriesList &&
                        productCategoriesList.map((category, i) => (
                          <MenuItem key={i} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                )}

                <div className="col-6 mb-3">
                  <label className="form-label" htmlFor="franchiseInput">
                    <FormattedMessage id="franchiseTypeLbl" />
                  </label>

                  {salePointTypeList && (
                    <Chips
                      options={salePointTypeList}
                      selectedOptions={selectedSpt}
                      setSelected={setSelectedSpt}
                      isDisabled={
                        product.fk_sale_point_types &&
                        product.fk_sale_point_types.length
                      }
                    />
                  )}
                </div>
              </div>
              {/* Precios title */}
              <p className="section-title">Precios y costos</p>

              <div className="row">
                <div className="col-6 mb-3">
                  <CurrencyTextField
                    label={<FormattedMessage id="sellPriceLbl" />}
                    variant="standard"
                    className="form-control"
                    value={product.sell_price}
                    name="sell_price"
                    currencySymbol="$"
                    outputFormat="number"
                    decimalPlaces={0}
                    onChange={handleInputChangeInner}
                    required
                  />
                  <br />
                  <br />

                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.customer_visible
                            ? Boolean(product.customer_visible)
                            : false
                        }
                        onChange={handleChangeCheckBox}
                        name="customer_visible"
                      />
                    }
                    label={<FormattedMessage id="customerVisibleLbl" />}
                  /> */}
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.quantity_mode
                            ? Boolean(product.quantity_mode)
                            : false
                        }
                        onChange={handleChangeCheckBox}
                        name="quantity_mode"
                      />
                    }
                    label={<FormattedMessage id="quantityModeLbl" />}
                  /> */}
                </div>
                <div className="col-6 mb-3">
                  <TextField
                    onChange={handleInputChangeInner}
                    className="form-control"
                    value={product.barcode ? product.barcode : ""}
                    name="barcode"
                    label={<FormattedMessage id="barCodeLbl" />}
                  />
                  <br />
                  <br />
                  {/* 
                  <TextField
                    className="form-control"
                    label={<FormattedMessage id="internalCodeLbl" />}
                    value={product.internal_code ? product.internal_code : ""}
                    name="internal_code"
                    onChange={handleInputChangeInner}
                  /> */}
                </div>
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <CurrencyTextField
                    label={<FormattedMessage id="specialPricelbl" />}
                    variant="standard"
                    className="form-control"
                    value={product.special_price ? product.special_price : ""}
                    name="special_price"
                    currencySymbol="$"
                    outputFormat="number"
                    decimalPlaces={0}
                    onChange={handleInputChangeInner}
                  />
                </div>
                <div className="col-6 mb-3">
                  <CurrencyTextField
                    label={<FormattedMessage id="buyPricelbl" />}
                    variant="standard"
                    className="form-control"
                    value={product.buy_price ? product.buy_price : ""}
                    name="buy_price"
                    currencySymbol="$"
                    outputFormat="number"
                    decimalPlaces={0}
                    onChange={handleInputChangeInner}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <CurrencyTextField
                    label={<FormattedMessage id="discountPricelbl" />}
                    variant="standard"
                    className="form-control"
                    value={product.discount_price}
                    name="discount_price"
                    currencySymbol="$"
                    outputFormat="number"
                    decimalPlaces={0}
                    onChange={handleInputChangeInner}
                  />
                </div>
                <div className="col-6 mb-3">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={product.discount_active}
                        name="discount_active"
                        onChange={handleChangeCheckBox}
                      />
                    }
                    label={<FormattedMessage id="discountActivelbl" />}
                  />
                </div>
              </div>
              <p className="section-title">Clasificación</p>
              <div className="row">
                <div className="col-6 mb-3"></div>
                <div className="col-6 mb-3">
                  <TagInput
                    tags={tags}
                    setTags={setTags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                  />
                </div>
              </div>

              <p className="section-title">Información tributaria</p>
              <div className="row">
                <div className="col-6 mb-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.iva_mode ? Boolean(product.iva_mode) : false
                        }
                        onChange={handleChangeCheckBox}
                        name="iva_mode"
                      />
                    }
                    label={<FormattedMessage id="ivaModeLbl" />}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.ipo_mode ? Boolean(product.ipo_mode) : false
                        }
                        onChange={handleChangeCheckBox}
                        name="ipo_mode"
                      />
                    }
                    label={<FormattedMessage id="ipoModeLbl" />}
                  />
                  <br />

                  <label className="form-label" htmlFor="franchiseInput">
                    <FormattedMessage id="ivaPercentageLbl" />
                  </label>
                  <Select
                    value={product.iva ? product.iva : ""}
                    onChange={handleInputChangeInner}
                    name="iva"
                    className="worker-form-select"
                    id="categoryInput"
                  >
                    <MenuItem value={0}>0%</MenuItem>

                    <MenuItem value={0.05}>5%</MenuItem>
                    <MenuItem value={0.08}>8%</MenuItem>
                    <MenuItem value={0.19}>19%</MenuItem>
                  </Select>
                </div>
                <div className="col-6 mb-3">
                  <TextField
                    className="form-control"
                    label={<FormattedMessage id="fixIpoLbl" />}
                    value={product.ipo ? product.ipo : ""}
                    name="ipo"
                    onChange={handleInputChangeInner}
                  />
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-6">
                  {/* {deleteAvailable && (
                    <button
                      className="btn btn-outline-danger m-2"
                      type="button"
                      disabled={submittedDelete}
                      onClick={handleDelete}
                    >
                      {!submittedDelete ? (
                        <FormattedMessage id="deleteProductLbl" />
                      ) : (
                        <div className="d-flex justify-content-center spinner-container">
                          <div
                            className="spinner-border spinner-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </button>
                  )} */}
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Link className="" to={`/productos`}>
                    <button className="btn btn-outline-primary m-2">
                      <FormattedMessage id="cancelLbl" />
                    </button>
                  </Link>
                  <button
                    className="btn btn-primary m-2"
                    type="submit"
                    disabled={submitted}
                  >
                    {!submitted && <FormattedMessage id={submitLabel} />}
                    {submitted && (
                      <div className="d-flex justify-content-center spinner-container">
                        <div
                          className="spinner-border spinner-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </button>
                  {/* </Link> */}
                </div>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert onClose={handleClose} severity="success">
                    <FormattedMessage id="uptadedProductAlertLbl" />
                  </Alert>
                </Snackbar>
                {/**Let's create the modal */}
                
                {/* Modal to handle error in postproduct */}
                <Modal
                  open={openErrorModal}
                  onClose={handleClose}
                  errorMessage={errorMessage}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="modal-box-error">
                      <h2 className="modal-title">
                        Ha ocurrido un error
                      </h2>
                      <p className="text-center modal-question">
                        {errorMessage}
                      </p>
                      <div className="text-center modal-options">
                      <Link
                          className="modal-option"
                          to={`/productos/create`}
                          onClick={() => window.location.reload(false)}
                        >
                          <FormattedMessage id="closelbl" />
                        </Link>


                        {/* <button
                          className="modal-option"
                          onClick={() => {
                            window.location.reload(false);
                            handleClose();
                          }}
                        >
                          Cerrar
                        </button> */}
                        
                      </div>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="modal-box">
                      <h2 className="modal-title">
                        <FormattedMessage id="createdProductLbl" />
                      </h2>
                      <p className="modal-question">
                        <FormattedMessage id="whatNowLbl" />
                      </p>
                      <div className="text-center modal-options">
                        <Link
                          className="modal-option"
                          to={`/productos/create`}
                          onClick={() => window.location.reload(false)}
                        >
                          <FormattedMessage id="createOtherProductLbl" />
                        </Link>

                        <Link
                          className="modal-option"
                          to={`/productos/newProduct/edit`}
                        >
                          <FormattedMessage id="setUpThisProduct" />
                        </Link>
                      </div>
                    </div>
                  </Box>
                </Modal>
                <Modal
                  open={openDeleteModal}
                  onClose={handleDeleteClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="modal-box">
                      {!loadingDelete ? (
                        <>
                          <h2 className="modal-title">
                            <FormattedMessage id="deletedWorkertLbl" />
                          </h2>
                          <div className="text-center modal-options">
                            <button
                              className="btn btn-custom m-2"
                              onClick={handleReturn}
                            >
                              <FormattedMessage id="returnLbl" />
                            </button>
                            <button
                              className="btn btn-outline-danger m-2"
                              onClick={handleConfirmDelete}
                            >
                              <FormattedMessage id="checkLbl" />
                            </button>
                          </div>
                        </>
                      ) : (
                        <div className="d-flex justify-content-center spinner-container">
                          <div
                            className="spinner-border spinner-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Box>
                </Modal>
              </div>
            </form>
          )}
          {loading && (
            <div className="d-flex justify-content-center spinner-container">
              <div className="spinner-border spinner-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </article>
      </main>
      {/* {urlLocation.pathname.includes("edit") && (
        <aside className="section-body col" id="ingredient-info">
          <h2 className="section-title">
            <FormattedMessage id="compositionTitle" />
          </h2>
          <article>
            <ProductIngredientPage
              ingredients={ingredientsOfProduct}
            ></ProductIngredientPage>
          </article>
        </aside>
      )} */}
    </div>
  );
};

const Chips = ({ options, selectedOptions, setSelected, isDisabled }) => {
  const handleChange = (newValue, { action }) => {
    setSelected(newValue);
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: 0, label: inputValue };
    setSelected([...selectedOptions, newOption]);
  };

  return (
    <CreatableSelect
      isDisabled={isDisabled}
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      isMulti
      isClearable
      isSearchable
      placeholder="Selecciona un pasillo"
    />
  );
};

export default ProductForm;
