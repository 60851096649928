import {
  URL_DOMAIN,
  URL_HELP,
  URL_DOMAIN_MARKET,
  getJSON,
  URL_P2,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut
} from "../globals";

const URL_FINAL = URL_DOMAIN + URL_P2[2];

const URL_PRODUCT = URL_DOMAIN + URL_P2[2];


const supervisorId = localStorage.getItem("fk_supervisor");

const organizationId = localStorage.getItem('fk_organization');
/**
 * Franchises
 * @returns
 */

const getProductsOfOrganization = async () => {
  try {
    const response = await fetch(
      URL_DOMAIN +
      "product/get-all-products-of-organization?fk_organization=" +
      organizationId,
      requestOptionsGet
    );

    if (!response.ok) {
      throw new Error("Error al obtener los datos de ventas");
    }

    return getJSON(response);
  } catch {
    throw new Error("Error al obtener los datos de ventas: ");
  }
};

const getProductById = async (id) => {
  return fetch(
    URL_DOMAIN + "product/get-product-detail?fk_product=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const getExcelTemplate = async (id) => {
  return fetch(
    URL_HELP + "inventory/web/get-inventory-excel-template?fk_organization="+id,
    requestOptionsGet
  );
};

const postProduct = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(URL_DOMAIN + "product/create-product", requestOptionsPost).then(
    getJSON
  );
};

const putProduct = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_DOMAIN + "product/edit-product", requestOptionsPut).then(
    getJSON
  );
};
const uploadProducts = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(
    URL_DOMAIN + "product/upload-initial-products", requestOptionsPost
  ).then(getJSON);
}

const uploadProductsExcel = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(
    URL_HELP + "inventory/web/load-products-from-excel", requestOptionsPost
  ).then(getJSON);
}

const activateSpecialPrices = async (jsonBody) => {
  requestOptionsPut.body = jsonBody;
  return fetch(
    URL_DOMAIN + "product/activate-special-prices",
    requestOptionsPut
  ).then(getJSON);
};

const disactivateSpecialPrices = async (jsonBody) => {
  requestOptionsPut.body = jsonBody;
  return fetch(
    URL_DOMAIN + "product/disactivate-special-prices",
    requestOptionsPut
  ).then(getJSON);
};

export { getProductsOfOrganization, getProductById, postProduct, putProduct, uploadProducts, getExcelTemplate, uploadProductsExcel,activateSpecialPrices,disactivateSpecialPrices };
