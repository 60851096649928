import {
    URL_DOMAIN,
    URL_DOMAIN_MARKET,
    getJSON,
    URL_P2,
    requestOptionsPost,
    requestOptionsPut,
    requestOptionsGet
  } from "./globals";


  const getAllSalePoint = async (fkOrganization) =>{
    return fetch(
        URL_DOMAIN +
          "sale-point/get-sale-points?fk_organization=" +
          fkOrganization,
        requestOptionsGet
      ).then(getJSON);
  }

  const getSalePointById = async (fkSalePoint) =>{
      return fetch(
          URL_DOMAIN +
          "sale-point/get-sale-point-detail?fk_sale_point="+
          fkSalePoint, requestOptionsGet
      ).then(getJSON)
  }

  const getCoursesBySalePoint = async (fkSalePoint) =>{
    return fetch(
        URL_DOMAIN +
        "courses/get-courses-by-sale-point?fk_sale_point="+
        fkSalePoint, requestOptionsGet
    ).then(getJSON)
}
  export {getAllSalePoint, getSalePointById, getCoursesBySalePoint};