import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/Table/Table";
import EditPrimaryIcon from "../../../assets/icons/edit-24px.svg";
import SearchBar from "../../../components/searchBar/searchBar";
import { getIngredients } from "../../../services/utilsIngredient/utilsIndredient";
import { getPaginatedProductCategories } from "../../../services/utilsProductCategory/utilsProductCategory";
import { LinearProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import InternetErrorComponent from "../../../components/Errors/InternetErrorComponent";

const ProductCategoryListPage = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ---------------- 
  // ----------------------------------------------------
  const [searchQuery, setSearchQuery] = useState("");

  const [productCategories, setProductCategories] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [pagination, setPagination] = useState({});

  const [loading, setLoading] = useState(true);

  const [loadingError, setLoadingError] = useState(false);

  const [tableLoading, setTableLoading] = useState(true);

  const productCategoryTableHeaders = [
    "nameHeader",
    "typeHeader",
    "franchiseTypeHeader",
    "actionsHeader",
  ];

  const createProductCategoryData = (name, type, franchiseType, id) => {
    return {
      name,
      type,
      franchiseType,
      actions: (
        <Link to={`/categorias-de-producto/${name}/edit`} onClick={() => goProductCategory(id)}><img src={EditPrimaryIcon} alt="edit" onClick={() => console.log("id")} /></Link>
      ),
    };
  };

  const goProductCategory = (id) => {
    localStorage.setItem("fk_product_category", id);
  };

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------
  const fetchData = async (page, rowsPerPage, searchQuery = null) => {
    setTableLoading(true)
    getPaginatedProductCategories(page, rowsPerPage, searchQuery).then((data) => {
      let productCategoryData = data.product_categories.map((productCategory) =>
        createProductCategoryData(
          productCategory.name,
          productCategory.type,
          productCategory.sale_point_types.length === 1 ? productCategory.sale_point_types[0].sale_point_type.name : "Multiple",
          productCategory.id
        )
      );
      setProductCategories(productCategoryData);

      let paginationObject = data.pagination;
      paginationObject.rowsPerPage = rowsPerPage;
      paginationObject.page = page;
      setPagination(paginationObject);
      setLoading(false)
      setTableLoading(false)
      console.log("fetch done")
    }).catch((error) => {
      setLoading(false);
      setLoadingError(true);
      setTableLoading(false);
    });
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------
  const handleChangePage = async (newPage) => {
    await fetchData(newPage, rowsPerPage).then(() => {
      setPage(newPage);
    });
  };

  const handleChangeRowsPerPage = async (rowsPerPage) => {
    await fetchData(0, rowsPerPage).then(() => {
      setPage(0);
      setRowsPerPage(rowsPerPage);
    });
  };

  const handleTextSearch = async (input) => {
    setSearchQuery(input)
    fetchData(0, rowsPerPage, input)
    setPage(0);
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <>
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loading &&
        <>
          <SearchBar
            input={searchQuery}
            onChange={handleTextSearch}
            nombre="Categorías"
          />
          {tableLoading && <LinearProgress />}
          <CustomTable
            headers={productCategoryTableHeaders}
            rows={productCategories}
            pagination={pagination}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {loadingError && (<InternetErrorComponent></InternetErrorComponent>)}
        </>
      }


    </>
  );
};

export default ProductCategoryListPage;
