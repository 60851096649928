import WalletIcon from "@mui/icons-material/Wallet";
import PendingIcon from "@mui/icons-material/Pending";
import UndoIcon from "@mui/icons-material/Undo";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import UpdateIcon from "@mui/icons-material/Update";
import {
  convertUTCtoLocalTimeString,
  convertUTCtoLocalTimeStringMovements,
} from "../../timezonefuncions";
import { useState } from "react";
import { getStatusPayIn } from "../../../services/utilsWallet";

const WalletPayIn = ({ movement }) => {
  const [movementObj, setMovementObj] = useState(movement);
  const [openDetail, setOpenDetail] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const formatMoney = (number) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const statusToString = (status) => {
    switch (status) {
      case "SUCCESSFUL":
        return "Exitosa";
      case "FAILED":
        return "Fallida";
      case "IN_PROGRESS":
        return "En progreso";
      default:
        return "";
    }
  };
  
  const payInTypeToString = (type, transferTo = null) => {
    switch (type) {
      case "TRANSFER":
        return `Transferencia${transferTo ? ` a ${transferTo}` : ""}`; 
      case "CREDIT_CARD":
        return "Tarjeta de crédito";
      case "CASHIER":
        return "Ventanilla";
      case "PAYROLL_DEDUCTION":
        return "Descuento por nómina";
      default:
        return "PSE";
    }
  };
  
  const handleOpenDetailClick = () => {
    setOpenDetail((prevState) => !prevState);
  };

  const getStatusTransaction = async () => {
    setLoadingUpdate(true);
    console.log("getStatusTransaction", movementObj.idempotence_key);
    getStatusPayIn(movementObj.idempotence_key).then((res) => {
      setLoadingUpdate(false);
      setMovementObj(res.response);
      console.log("res", res.response);
    });
  };

  return (
    <>
      <div className="row-wallet-information" onClick={handleOpenDetailClick}>
        <div className="row-wallet-information-rechage">
          <li className="time-text">
            {convertUTCtoLocalTimeStringMovements(movementObj.datetime)}
          </li>
          <div className="row-info-transaction">
            <div>
              {movementObj.status === "SUCCESSFUL" && (
                <div className="bachground-payin success">
                  <WalletIcon className="icon-recharge"></WalletIcon>
                </div>
              )}
              {movementObj.status === "REFUND" && (
                <div className="bachground-payin refund">
                  <UndoIcon className="icon-recharge"></UndoIcon>
                </div>
              )}
              {movementObj.status === "IN_PROGRESS" && (
                <div className="bachground-payin in_progress">
                  <PendingIcon className="icon-recharge"></PendingIcon>
                </div>
              )}
              {movementObj.status === "FAILED" && (
                <div className="bachground-payin failed">
                  <ClearIcon className="icon-recharge"></ClearIcon>
                </div>
              )}
            </div>
            <div className="row-wallet-information-text">
              <li className="movement-title">Recarga</li>
              <li className="movement-sub-title">
                {payInTypeToString(movementObj.payment_method)}:{" "}
                {statusToString(movementObj.status)}
              </li>
            </div>
          </div>
        </div>
        <div className={`row-wallet-information-amount ${movementObj.status}`}>
          <li>{formatMoney(movementObj.amount / 100)}</li>
        </div>
      </div>
      {openDetail && (
        <div className={`info-container ${movementObj.status}`}>
          <div className="row title-info-detail">
            {movementObj.status === "SUCCESSFUL" && (
              <div className="title-info-detail">
                <div className="bachground-payin success">
                  <CheckIcon className="icon-recharge"></CheckIcon>
                </div>
                <h2>Recarga exitosa</h2>
              </div>
            )}
            {movementObj.status === "REFUND" && (
              <div className="title-info-detail">
                <div className="bachground-payin failed">
                  <UndoIcon className="icon-recharge"></UndoIcon>
                </div>
                <h2>T</h2>
              </div>
            )}
            {movementObj.status === "IN_PROGRESS" && (
              <div className="title-info-detail">
                <div className="bachground-payin in_progress">
                  <PendingIcon className="icon-recharge"></PendingIcon>
                </div>
                <h2>Recarga en progreso</h2>
              </div>
            )}
            {movementObj.status === "FAILED" && (
              <div className="title-info-detail">
                <div className="bachground-payin failed">
                  <ClearIcon className="icon-recharge"></ClearIcon>
                </div>
                <h2>Recarga fallida</h2>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                Id de la transacción
              </h6>
              <p>{movementObj.idempotence_key}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                Valor
              </h6>
              <p>{formatMoney(movementObj.amount / 100)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                Medio de pago
              </h6>
              <p>{payInTypeToString(movementObj.payment_method, movementObj.payin_info?.transfer_to)}</p>
            </div>
          </div>
          {movementObj.payment_method === "CREDIT_CARD" && (
            <div className="row">
              <div className="col">
                <h6 className={`info-detail-subtitle ${movementObj.status}`}>
                  Banco
                </h6>
                <p>{movementObj.payin_info.data.transaction.data.banco}</p>
              </div>
            </div>
          )}
          {movementObj.status === "IN_PROGRESS" && (
            <>
              <div className="button-container">
                <button
                  className="btn btn-primary button-cancel m-2 in_progress"
                  onClick={getStatusTransaction}
                >
                  {!loadingUpdate && 'Actualizar'}
                  {loadingUpdate && (
                    <div className="d-flex justify-content-center spinner-container">
                      <div
                        className="spinner-border spinner-secondary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </>
          )}
          {/* {movement.status === 'IN_PROGRESS' && (
            <div className="row">
            <div className="col">
              <button>
                Actualizar
              </button>
             
            </div>
          </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default WalletPayIn;
