import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { FormattedMessage } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import "./CustomTab.scss";
import "../../index.scss";
import { Link } from "react-router-dom";

import AcUnitIcon from '@mui/icons-material/AcUnit';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
    >
      {value === index && (
        <Box style={{ padding: 0 }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const pages = [
  { navTitle: "franchiseTitle", component: "<SalePointList />" },
  { navTitle: "operatorTitle", component: "<WorkerPage />" },
  { navTitle: "productTitle", component: "<ProductListPage />" },
];
const useStyles = makeStyles((theme) => ({
    fontFamiliy: {
      fontFamily: "Catamaran-Regular",
      margin:"0px"
    },
  }));

export default function CustomTab(props) {
  console.log(props);
  const pages = props.props;
  
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="root">
      <Paper position="static" color="white">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          elevation={0}
        >
          {pages &&
            pages.map((page, i) => (
              <Tab
                label={<FormattedMessage id={page.navTitle} />}
                {...a11yProps(i)}
                
                className={classes.fontFamiliy}
              />
            ))}
        </Tabs>
      </Paper>
      {pages &&
        pages.map((page, i) => (
          <TabPanel value={value} index={i}>
            {page.component}

            {page.buttonComponenet ? page.buttonComponenet :page.url ? (<Link to={page.url + "/create"}>
              <Fab size="small" className="fab" color="secondary" aria-label="edit">
                <AddIcon className="fab-icon" />
              </Fab>
            </Link>) : ""}

          </TabPanel>
        ))}
    </div>
  );
}
