// import "./ResumeProductInventoryDetail.scss";
import React, { useEffect } from "react";
import "./WalletCustomerInfo.scss";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import { getAllWalletInfo } from "../../../services/utilsWallet";
import DefaultImg from "../../../assets/market-icons/avatar-supervisor.jpg";
import WalletIcon from "@mui/icons-material/Wallet";
import PendingIcon from "@mui/icons-material/Pending";
import UndoIcon from "@mui/icons-material/Undo";
import ClearIcon from "@mui/icons-material/Clear";
import WalletTransaction from "./WalletTransaction";
import WalletPayIn from "./WalletPayIn";

const WalletCustomerInfo = (props) => {
  const { walletInfo, setWalletInfo, selectedWallet } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const [movements, setMovements] = useState([]);

  const formatMoney = (number) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const handlePageChange = (event, newPage) => {
    let fkOrganization = localStorage.getItem("fk_organization");
    setCurrentPage(newPage);
    getAllWalletInfo(
      fkOrganization,
      newPage,
      6,
      selectedWallet.wallet_type,
      selectedWallet.id
    ).then((data) => {
      setMovements(data.wallet_movements);
      // console.log(data);
    });
  };

  useEffect(() => {
    setMovements(walletInfo.wallet_movements);
  }, [walletInfo]);

  useEffect(() => {
    console.log(movements);
  }, [movements]);
  return (
    <div className="wallet-customer-info">
      <div className="wallet-movements-list">
        <li className="wallet-mov-text">Movimientos de Billetera</li>
        {movements.map((movement) => (
          <div key={movement.id}>
            {movement.type === "transaction" ? (
              <WalletTransaction movement={movement} />
            ) : (
              <WalletPayIn movement={movement} />
            )}
          </div>
        ))}
      </div>
      <Pagination
        count={walletInfo.pagination.num_pages}
        color="primary"
        page={currentPage}
        onChange={handlePageChange}
        className="pagination"
      />
    </div>
  );
};

export default WalletCustomerInfo;
