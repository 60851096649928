import {
    URL_DOMAIN,
    URL_DOMAIN_MARKET,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut,
  } from "../globals";
  
  const URL_FRANCHISES = URL_DOMAIN + URL_P2[2];
  
  const supervisorId = localStorage.getItem("fk_supervisor");
  
  const organizationId = localStorage.getItem('fk_organization');

  /**
   * Simple categories
   * @returns
   */
  
  const getCategoriesSimple = async () => {
    return fetch(
      URL_DOMAIN +
        "product-category/get-all-product-category?fk_organization=" +
        organizationId,
      requestOptionsGet
    ).then(getJSON);
  };
  
  export { getCategoriesSimple };
  