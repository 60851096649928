import {
  URL_DOMAIN,
  URL_HELP,
  URL_DOMAIN_MARKET,
  getJSON,
  URL_P2,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsGet,
} from "./globals";
const getWalletsNameContains = async (fkOrganization, name) => {
  return fetch(
    URL_DOMAIN +
      "wallet/get_wallet_name_contains?keyword=" +
      name +
      "&fk_organization=" +
      fkOrganization,
    requestOptionsGet
  ).then(getJSON);
};

const getAllWalletInfo = async (
  fkOrganization,
  page_number,
  results_per_page,
  wallet_type,
  walled_id
) => {
  return fetch(
    URL_DOMAIN +
      "wallet/get-all-wallet-info?fk_organization=" +
      fkOrganization +
      "&page_number=" +
      page_number +
      "&results_per_page=" +
      results_per_page +
      "&wallet_type=" +
      wallet_type +
      "&wallet_id=" +
      walled_id,
    requestOptionsGet
  ).then(getJSON);
};

const rechargeWallet = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(URL_DOMAIN + "payin/payin-by-type-web", requestOptionsPost).then(
    getJSON
  );
};

const getStatusPayIn = async (id_payin) => {
  // requestOptionsPost.body ;
  return fetch(URL_DOMAIN + "payin/get-status?idempotency_key="+ id_payin, requestOptionsGet).then(
    getJSON
  );
};

export {
  getWalletsNameContains,
  getAllWalletInfo,
  rechargeWallet,
  getStatusPayIn,
};
