import { FormattedMessage } from "react-intl";
import "../Dashboard.scss";
import EarningsLogo from "../../../assets/market-icons/dashboard-icons/earnings.png";
import PreSalesLogo from "../../../assets/market-icons/dashboard-icons/presales.png";
import SalesLogo from "../../../assets/market-icons/dashboard-icons/sales.png";
import BestProductLogo from "../../../assets/market-icons/dashboard-icons/best.png";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getBestSellingProducts,
  getTotalPreSales,
  getTotalSales,
} from "../../../services/utilsDashboard";
import { ResponsiveBar } from "@nivo/bar";

function formatPresalesValue(value) {
  return (value / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
}

// Your React component that renders the bar chart
// Sample data for the bar chart
const data = [
  {
    date: "12/11",
    presale: 84,
    sale: 162,
  },
  {
    date: "13/11",
    presale: 84,
    sale: 162,
  },
  {
    date: "14/11",
    presale: 84,
    sale: 162,
  },
  {
    date: "15/11",
    presale: 84,
    sale: 162,
  },
  {
    date: "16/11",
    presale: 84,
    sale: 162,
  },
  {
    date: "17/11",
    presale: 84,
    sale: 162,
  },
  {
    date: "18/11",
    presale: 84,
    sale: 162,
  },
  // ...add more data points here
];

// Your React component that renders the bar chart
const MyBarChart = ({ data }) => {
  console.log(data);
  return (
    <div className="aspect-ratio-keeper">
      <div className="chart-content">
        <ResponsiveBar
          data={data}
          keys={["sale", "presale"]}
          indexBy="date"
          enableLabel={false}
          valueFormat=" >-$"
          groupMode="grouped"
          margin={{ top: 8, right: 8, bottom: 60, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 42,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "right-to-left",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          // ...add more props and configurations
        />
      </div>
    </div>
  );
};

const DashboardSales = () => {
  const [loadingInfo, setLoadinginfo] = useState({
    earnins: false,
    presales: true,
    sales: true,
    transactions: true,
    bestProduct: true,
    bestSealling: true,
    graphSales: true,
    grapPresales: true,
  });

  const [dashboardInfo, setDashboardInfo] = useState({
    earnins: "",
    presales: "",
    sales: "",
    bestProduct: "",
  });

  const [bestSellingProducts, setBestSellingProducts] = useState();

  const [transactions, setTransactions] = useState();

  const fetchInitialInfo = async () => {
    const fkOrganzation = localStorage.getItem("fk_organization");

    const preSalesData = await getTotalPreSales(
      fkOrganzation,
      null,
      "2023-09-11T14:56:15",
      "2023-11-10T14:32:52"
    ).then((data) => {
      const formattedPresalesValue = formatPresalesValue(data.total_value);

      setDashboardInfo((prevdashboardInfo) => ({
        ...prevdashboardInfo,
        presales: formattedPresalesValue,
      }));

      let summaryByDate = data.presales.reduce((summary, transaction) => {
        let dateString = toDateString(transaction.datetime);
        if (!summary[dateString]) {
          summary[dateString] = 0;
        }
        summary[dateString] += transaction.transaction_value / 100;
        return summary;
      }, {});

      let summaryArrayPresales = Object.entries(summaryByDate).map(
        ([date, presale]) => ({
          date,
          presale,
          sale: 0,
        })
      );

      if (transactions) {
        let mergedTransactions = mergeSummariesByDate(
          transactions,
          summaryArrayPresales
        );
        setTransactions(mergedTransactions);
        setLoadinginfo((prevLoadingInfo) => ({
          ...prevLoadingInfo,
          grapPresales: false,
        }));
      } else {
        setTransactions(summaryArrayPresales);
        setLoadinginfo((prevLoadingInfo) => ({
          ...prevLoadingInfo,
          grapPresales: false,
        }));
      }

      setLoadinginfo((prevLoadingInfo) => ({
        ...prevLoadingInfo,
        presales: false,
      }));
    });

    const salesData = await getTotalSales(
      fkOrganzation,
      null,
      "2023-09-11T14:56:15",
      "2023-11-10T14:32:52"
    ).then((data) => {
      const formattedPresalesValue = formatPresalesValue(data.total_value);
      setDashboardInfo((prevdashboardInfo) => ({
        ...prevdashboardInfo,
        sales: formattedPresalesValue,
      }));

      let summaryByDate = data.sales.reduce((summary, transaction) => {
        let dateString = toDateString(transaction.datetime);
        if (!summary[dateString]) {
          summary[dateString] = 0;
        }
        summary[dateString] += transaction.transaction_value / 100;
        return summary;
      }, {});

      let summaryArraySales = Object.entries(summaryByDate).map(
        ([date, sale]) => ({
          date,
          sale,
          presale: 0,
        })
      );

      if (transactions) {
        let mergedTransactions = mergeSummariesByDate(
          transactions,
          summaryArraySales
        );
        console.log("SALES TRASNFORMED", mergedTransactions);
        setTransactions(mergedTransactions);
        setLoadinginfo((prevLoadingInfo) => ({
          ...prevLoadingInfo,
          graphSales: false,
        }));
      } else {
        setTransactions(summaryArraySales);
        setLoadinginfo((prevLoadingInfo) => ({
          ...prevLoadingInfo,
          graphSales: false,
        }));
      }

      setLoadinginfo((prevLoadingInfo) => ({
        ...prevLoadingInfo,
        sales: false,
      }));
    });

    // const [preSalesData, salesData] = await Promise.all([
    //   getTotalPreSales(
    //     fkOrganzation,
    //     null,
    //     "2023-09-11T14:56:15",
    //     "2023-11-10T14:32:52"
    //   ).then((data) => {
    //     const formattedPresalesValue = formatPresalesValue(data.total_value);

    //     setDashboardInfo((prevdashboardInfo) => ({
    //       ...prevdashboardInfo,
    //       presales: formattedPresalesValue,
    //     }));

    //     setLoadinginfo((prevLoadingInfo) => ({
    //       ...prevLoadingInfo,
    //       presales: false,
    //     }));
    //   }),
    //   getTotalSales(
    //     fkOrganzation,
    //     null,
    //     "2023-09-11T14:56:15",
    //     "2023-11-10T14:32:52"
    //   ),
    // ]);

    // console.log("PROMISE", preSalesData, salesData);

    // // Process pre-sales data

    // let summaryByDatePresales = preSalesData.presales.reduce(
    //   (summary, transaction) => {
    //     let dateString = toDateString(transaction.datetime);
    //     if (!summary[dateString]) {
    //       summary[dateString] = 0;
    //     }
    //     summary[dateString] += transaction.transaction_value / 100;
    //     return summary;
    //   },
    //   {}
    // );

    // let summaryArrayPresales = Object.entries(summaryByDatePresales).map(
    //   ([date, presale]) => ({
    //     date,
    //     presale,
    //     sale: 0,
    //   })
    // );

    // // Process sales data
    // const formattedSalesValue = formatPresalesValue(salesData.total_value);
    // setDashboardInfo((prevdashboardInfo) => ({
    //   ...prevdashboardInfo,
    //   sales: formattedSalesValue,
    // }));

    // let summaryByDateSales = salesData.sales.reduce((summary, transaction) => {
    //   let dateString = toDateString(transaction.datetime);
    //   if (!summary[dateString]) {
    //     summary[dateString] = 0;
    //   }
    //   summary[dateString] += transaction.transaction_value / 100;
    //   return summary;
    // }, {});

    // let summaryArraySales = Object.entries(summaryByDateSales).map(
    //   ([date, sale]) => ({
    //     date,
    //     sale,
    //     presale: 0,
    //   })
    // );

    // // Merge summaries
    // let mergedTransactions = mergeSummariesByDate(
    //   transactions,
    //   summaryArrayPresales.concat(summaryArraySales)
    // );

    // // Set transactions and update loading state
    // setTransactions(mergedTransactions);
    // setLoadinginfo((prevLoadingInfo) => ({
    //   ...prevLoadingInfo,
    //   grapPresales: false,
    //   graphSales: false,
    // }));

    const bestSellingProductsData = await getBestSellingProducts(
      fkOrganzation,
      null,
      "2023-09-11T14:56:15",
      "2023-11-10T14:32:52"
    ).then((data) => {
      let products = data.products;
      let bestSellingProducts = products
        .map((product) => ({
          name: product.name,
          image: product.image,
          sell_price: product.sell_price / 100,
          quantity_sold: product.quantity_sold,
        }))
        .slice(0, 10);
      setBestSellingProducts(bestSellingProducts);
      setDashboardInfo((prevdashboardInfo) => ({
        ...prevdashboardInfo,
        bestProduct: products[0].name,
      }));

      setLoadinginfo((prevLoadingInfo) => ({
        ...prevLoadingInfo,
        bestProduct: false,
      }));
    });
  };

  useEffect(() => {
    fetchInitialInfo();
  }, []);

  const mergeSummariesByDate = (currentTransactions, newSummaryArray) => {
    // Check if currentTransactions is defined
    if (!currentTransactions) {
      // If it's undefined, return newSummaryArray directly
      return newSummaryArray;
    }

    // Create a new map from existing transactions for fast lookup
    let transactionsMap = new Map(
      currentTransactions.map((tx) => [tx.date, tx])
    );

    // Iterate over new summary array to add or update transaction values
    newSummaryArray.forEach((newTx) => {
      if (transactionsMap.has(newTx.date)) {
        // If the date exists, update the existing transaction value
        let existingTx = transactionsMap.get(newTx.date);
        if (newTx.hasOwnProperty("sale") && existingTx.hasOwnProperty("sale")) {
          existingTx.sale += newTx.sale;
        } else if (
          newTx.hasOwnProperty("presale") &&
          existingTx.hasOwnProperty("presale")
        ) {
          existingTx.presale += newTx.presale;
        }
      } else {
        // If the date doesn't exist, add the new transaction
        transactionsMap.set(newTx.date, newTx);
      }
    });

    // Convert the map back to an array
    return Array.from(transactionsMap.values());
  };

  const toDateString = (datetime) => {
    let dateObj = new Date(datetime);
    let day = String(dateObj.getDate()).padStart(2, "0");
    let month = String(dateObj.getMonth() + 1).padStart(2, "0"); // January is 0!
    let year = dateObj.getFullYear();
    return day + "/" + month + "/" + year;
  };

  return (
    <div className="dashboard-cotainer" style={{ height: "100%" }}>
      {/* Title */}
      <div className="row no-gutter-x" style={{ height: "10%" }}>
        <div className="dashboard-style-container">
          <h2 className="dashboard-title">
            <FormattedMessage id="dashboardSalesTitle" />
          </h2>
        </div>
      </div>
      {/* Sales indications */}
      <div className="row no-gutter-x" style={{ height: "auto" }}>
        <div className="col dashboard-style-container space-between-columns totals-container background-color earnings">
          {!loadingInfo.earnins && (
            <div className="row">
              <div className="col-2">
                <div className="logo-background-dashboard">
                  <img className="logo-size" src={EarningsLogo} alt="" />
                </div>
              </div>
              <div className="col margin-card-info">
                <p className="subtitle-info">Ganancias</p>
                <h6 className="text-info">$10’000.000</h6>
              </div>
            </div>
          )}
          {loadingInfo.earnins && (
            <div className="d-flex align-items-center justify-content-center loading-info-container">
              {" "}
              {/* Center items vertically and horizontally */}
              <CircularProgress style={{ color: "white" }} />
            </div>
          )}
        </div>

        <div className="col dashboard-style-container space-between-columns totals-container background-color presales">
          {!loadingInfo.presales && (
            <div className="row">
              <div className="col-2">
                <div className="logo-background-dashboard">
                  <img className="logo-size" src={PreSalesLogo} alt="" />
                </div>
              </div>
              <div className="col margin-card-info">
                <p className="subtitle-info">Pre-compras</p>
                <h6 className="text-info">{dashboardInfo.presales}</h6>
              </div>
            </div>
          )}
          {loadingInfo.presales && (
            <div className="d-flex align-items-center justify-content-center loading-info-container">
              {" "}
              {/* Center items vertically and horizontally */}
              <CircularProgress style={{ color: "white" }} />
            </div>
          )}
        </div>

        <div className="col dashboard-style-container space-between-columns totals-container background-color purchases">
          {!loadingInfo.sales && (
            <div className="row">
              <div className="col-2">
                <div className="logo-background-dashboard">
                  <img className="logo-size" src={SalesLogo} alt="" />
                </div>
              </div>
              <div className="col margin-card-info">
                <p className="subtitle-info">Compras</p>
                <h6 className="text-info">{dashboardInfo.sales}</h6>
              </div>
            </div>
          )}

          {loadingInfo.sales && (
            <div className="d-flex align-items-center justify-content-center loading-info-container">
              {" "}
              {/* Center items vertically and horizontally */}
              <CircularProgress style={{ color: "white" }} />
            </div>
          )}
        </div>

        <div className="col dashboard-style-container space-between-columns totals-container background-color bestproduct">
          {!loadingInfo.bestProduct && (
            <div className="row">
              <div className="col-2">
                <div className="logo-background-dashboard">
                  <img className="logo-size" src={BestProductLogo} alt="" />
                </div>
              </div>
              <div className="col margin-card-info">
                <p className="subtitle-info">Mejor Producto</p>
                <h6 className="text-info product">
                  {dashboardInfo.bestProduct}
                </h6>
              </div>
            </div>
          )}
          {loadingInfo.bestProduct && (
            <div className="d-flex align-items-center justify-content-center loading-info-container">
              {" "}
              {/* Center items vertically and horizontally */}
              <CircularProgress style={{ color: "white" }} />
            </div>
          )}
        </div>
      </div>
      {/* Graph */}
      <div className="row no-gutter-x" style={{ height: "65%" }}>
        <div className="col-7 dashboard-style-container space-between-columns ">
          <h2 className="dashboard-title section-2">Ventas de la semana</h2>
          <div className="graph-size">
            {!loadingInfo.grapPresales && !loadingInfo.graphSales && (
              <MyBarChart data={transactions} />
            )}
          </div>
        </div>
        <div className="col dashboard-style-container">
          <h2 className="dashboard-title section-2">
            Productos con mayor venta
          </h2>
          <div className="best-sealling-products-container">
            <div className="row">
              <div className="col">Name</div>
              <div className="col-2">Vendido</div>
              <div className="col-3">Costo</div>
            </div>
            {bestSellingProducts &&
              bestSellingProducts.map((product) => (
                <div className="row">
                  <div className="col">{product.name}</div>
                  <div className="col-2">{product.quantity_sold}</div>
                  <div className="col-3">{product.sell_price}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSales;
