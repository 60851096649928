import React from "react";

import SignalWifiConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiConnectedNoInternet4";

const InternetErrorComponent = () => {
  return (
    <div className="center-container-wifi">
      <div className="wifi">
        <SignalWifiConnectedNoInternet4Icon />
      </div>
      <p>
        Lo sentimos, parece que no tienes conexión a Internet en este momento.
      </p>
    </div>
  );
};

export default InternetErrorComponent;
