import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import {TextField, Select, MenuItem, Snackbar} from "@material-ui/core";
import {styled, alpha} from "@mui/material/styles";
import {
    Dialog,
    Menu,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";

import LinearProgress from "@material-ui/core/LinearProgress";
import CustomTable from "../../components/Table/Table";
import EditInventoryDialog from "../../components/supervisor/EditInventoryDialog/EditInventoryDialog";
import DropzoneFile from "../../components/DropzoneFile/DropzoneFile";
import DownloadLinkCustom from "../../components/DownloadLinkCustom/DownloadLinkCustom";

import {getSalePointsOfOrganization} from "../../services/utilsFranchise/utilsFranchise";
import {
    getInventoryInfoByFranchise,
    getInventoryIngredientsInfoByFranchise
} from "../../services/utilsInventory/utilsInventory";

import "../../index.scss";
import "../../globalFormStyle.scss";
import "./InventoryPage.scss";
import {URL_DOMAIN, URL_P2, URL_REPORT} from "../../services/globals";
import ImagenDefult from '../../assets/market-icons/producto.jpg';
import IconButton from '@mui/material/IconButton';
import EditPrimaryIcon from '../../assets/icons/edit-24px.svg';
import InfoPrimaryIcon from '../../assets/icons/baseline-info-24px.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DescriptionIcon from '@mui/icons-material/Description';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CustomTab from "../../components/CustomTab/CustomTab";
import SalePointListPage from "../Franchise/SalePointList/SalePointListPage";
import SearchBar from "../../components/searchBar/searchBar";

const InventoryPage = () => {

    const [inputProduct, setInputProduct] = useState("");

    const [productListDefault, setProductListDefault] = useState([]);

    const [productsInventory, setProductsInventory] = useState([]);

    const [inputIngredient, setInputIngredient] = useState("");

    const [ingredientsInventory, setIngredientsInventory] = useState([]);

    const [ingredientsListDefault, setIngredientsListDefault] = useState([]);

    const [franchiseList, setFranchiseList] = useState([]);

    const [franchiseSelected, setFranchiseSelected] = useState(0);
    

    const [tableLoading, setTableLoading] = useState(true);





    const [fetchDataInventory, setFetchDataInventory] = useState(false);

    const [messageInitialInventory, setMessageInitialInventory] = useState('');
    const inventoryTableHeaders = [
        ' ',
        'nameHeader',
        'categoryHeader',
        'currentQuantityHeader',
        'unitHeader',
        'barcodeHeader',
        'lastMovementHeader',

        'actionsHeader'
    ];

    function productName(name) {

        if (name.length > 30) {
            name = name.substr(0, 30) + "...";
        }
        if ((name).indexOf("%") > -1) {
            return (name).replace("%", "");
        } else {
            return name;
        }
    }

    const createInventoryTableData = (
        img,
        name,
        category,
        quantity,
        unit,
        lastMovement,
        product,barcode,
        id
    ) => {
        return {
            img,
            name,
            category,
            quantity,
            unit,
            barcode,
            lastMovement,
            actions: (
                <>
                    <div className="flex">
                        <Link
                            to={`/inventarios/${productName(name)}`}
                            onClick={() => goProduct(id)} 
                        >
                            <img
                                src={InfoPrimaryIcon}
                                alt="info"
                                onClick={() => ''}
                                className="table-icon"
                            />
                        </Link>
                        <EditInventoryDialog
                            image={EditPrimaryIcon}
                            product={product}
                            franchiseId={franchiseSelected}
                            fetchData={fetchData}
                        />
                    </div>
                </>
            )
        };
    };

    function productName(name) {
        if (name.indexOf('%') > -1) {
            return name.replace('%', '');
        } else if (name.indexOf('/') > -1) {
            return name.replace('/', '');
        } else {
            return name;
        }
    }

    const fetchData = async (franchise) => {
        getSalePointsOfOrganization().then((data) => {
            setFranchiseList(data['franchises']);
        });
        if (franchise !== 0) {
            getInventoryInfoByFranchise(franchise).then((data) => {
                let arrayProducts = [];
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    console.log(element.products)
                    let categoryProductData = element.products.map((productInventory) =>
                        createInventoryTableData(
                            productInventory.image_url
                                ? productInventory.image_url
                                : ImagenDefult,
                            productInventory.name,
                            element.name,
                            productInventory.quantity ? productInventory.quantity : '-',
                            productInventory.unit,
                            productInventory.last_movement,
                            productInventory,
                            productInventory.barcode? productInventory.barcode : "-",
                            productInventory.id,

                        )
                    );
                    arrayProducts = arrayProducts.concat(categoryProductData);
                }
                setProductListDefault(arrayProducts);
                setProductsInventory(arrayProducts);
                setTableLoading(false);
            });

            getInventoryIngredientsInfoByFranchise(franchise).then((data) => {
                let arrayProducts = [];
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];

                    let categoryProductData = element.products.map((productInventory) =>
                        createInventoryTableData(
                            productInventory.image_url = ImagenDefult,
                            productInventory.name,
                            element.name,
                            productInventory.quantity ? productInventory.quantity : '-',
                            productInventory.unit,
                            productInventory.last_movement,
                            productInventory,
                            productInventory.barcode? productInventory.barcode : "-",
                            productInventory.id
                        )
                    );
                    arrayProducts = arrayProducts.concat(categoryProductData);
                }
                setIngredientsInventory(arrayProducts);
                setIngredientsListDefault(arrayProducts);
                //setTableLoading(false);
            });

        }
    };

    let goProduct = (id) => {
        localStorage.setItem('fk_product', id);
        localStorage.setItem('fk_franchise', franchiseSelected);
    };
    useEffect(() => {
        fetchData(franchiseSelected).then(() => {
        });
    }, [franchiseSelected]);

    const handleChangeFranchise = (event) => {
        setTableLoading(true);
        setFranchiseSelected(event.target.value);
        let franchiseName = franchiseList.filter(franchise => franchise.id === event.target.value)[0];

        localStorage.setItem("franchiseName", franchiseName.name);
    };
    const updateInputProducts = async (input) => {
        const filtered = productListDefault.filter((product) => {
            console.log(product)
            if (product.barcode) {
                return product.name.toLowerCase().includes(input.toLowerCase()) || product.barcode.toLowerCase().includes(input.toLowerCase());
            } else {
                return product.name.toLowerCase().includes(input.toLowerCase());
            }

        });

        setInputProduct(input);
        setProductsInventory(filtered);
    };

    const updateInputIngredient = async (input) =>{
        const filtered = ingredientsListDefault.filter((ingredient) =>{
            if (ingredient.barcode) {
                return ingredient.name.toLowerCase().includes(input.toLowerCase()) || ingredient.barcode.toLowerCase().includes(input.toLowerCase());
            } else {
                return ingredient.name.toLowerCase().includes(input.toLowerCase());
            }
        });

        setInputIngredient(input);
        setIngredientsInventory(filtered);
    }
    /**
     * Components for the tabs
     */
    let componentsTabs = [{
        navTitle: "productsNav",
        component: (
            <>
                <SearchBar
                    input={inputProduct}
                    onChange={updateInputProducts}
                    nombre="Producto"
                />
                <CustomTable
                    headers={inventoryTableHeaders}
                    rows={productsInventory}
                    rowTypes={[[0, 'image']]}
                    pagination={''}
                />
            </>)
    }, {
        navTitle: "insumosNav",
        component:(
            <>
                <SearchBar
                    input={inputIngredient}
                    onChange={updateInputIngredient}
                    nombre="Ingrediente"
                />
            <CustomTable
            headers={inventoryTableHeaders}
            rows={ingredientsInventory}
            rowTypes={[[0, 'image']]}
            pagination={''}
            />

            </>)
    }]

    return (
        <main className="section-body">
            <div className="flex space">
                <h1 className="section-title">
                    <FormattedMessage id="inventoryTitle"/>
                </h1>

                {franchiseSelected !== 0 && (
                    <DropdownMenu
                        props={franchiseSelected}
                        fetchData={fetchData}
                        messageInitial={setMessageInitialInventory}
                    />
                )}
            </div>
            <div className="row">
                <div className="col-6 mb-4">
                    <label className="form-label" htmlFor="franchiseInput">
                        <FormattedMessage id="franchiseLbl"/>
                    </label>
                    <br/>

                    <Select
                        required
                        value={franchiseSelected}
                        name="fk_franchise"
                        onChange={handleChangeFranchise}
                        className="worker-form-select"
                        id="franchiseInput"
                    >
                        {franchiseList &&
                            franchiseList.map((franchise, i) => (
                                <MenuItem key={i} value={franchise.id}>
                                    {franchise.name}
                                </MenuItem>
                            ))}
                    </Select>
                </div>
            </div>

            {franchiseSelected === 0 && (
                <>
                    <h5>Seleccionar punto de venta</h5>
                </>
            )}

            {tableLoading && franchiseSelected !== 0 && <LinearProgress/>}


            {franchiseSelected !== 0 && (
                <CustomTab props={componentsTabs}/>
            )}

        </main>
    );
};

export default InventoryPage;

/**
 * Dropdown Menu
 *
 */
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        backgroundColor: 'white',
        color:
            theme.palette.mode === 'light'
                ? 'rgb(55, 65, 81)'
                : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                )
            }
        }
    }
}));

function DropdownMenu(props) {
    let franchiseId = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [importOpen, setImportOpen] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (id) => {
        setAnchorEl(null);
    };

    const handleOption = (event) => {
        event.preventDefault();

        setAnchorEl(null);
    };
    const importFile = () => {
        setImportOpen(true);
    };

    const cancelImportFile = () => {
        setImportOpen(false);
    };

    const createUrlDownload = (id) => {
        if (id === 1) {
            return (
                URL_DOMAIN +
                URL_P2[2] +
                `/download-product-initial-inventory-excel?fk_franchise=${franchiseId.props}`
            );
        } else {
            return (
                URL_REPORT +
                `/get-current-inventory-for-franchise?fk_franchise=${franchiseId.props}`
            );
        }
    };

    const disabledItem = () => {
        if (franchiseId === 0) return true;
        else return false;
    };
    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color="primary"
            >
                <MoreVertIcon color="primary"/>
            </IconButton>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleOption}>
                    <DownloadLinkCustom
                        label={
                            <div className="no-linkunderline">
                                <SystemUpdateAltIcon color="primary"/> Descargar formato
                            </div>
                        }
                        filename="formato_importacion"
                        url={createUrlDownload(1)}
                    />
                </MenuItem>
                <MenuItem onClick={() => importFile()} className="center-items">
                    <CloudUploadIcon color="primary"/>
                    <p> Importar formato</p>
                </MenuItem>
                <MenuItem onClick={() => handleOption}>
                    <DownloadLinkCustom
                        label={
                            <div className="no-linkunderline">
                                <DescriptionIcon color="primary"/> Descargar inventario actual
                            </div>
                        }
                        filename="formato_importacion"
                        url={createUrlDownload(2)}
                    />
                </MenuItem>
            </StyledMenu>
            {/**
             * This is gonna be the place for the dialog of import file
             */}
            <DropzoneFile
                open={importOpen}
                functionClose={setImportOpen}
                franchiseId={franchiseId}
                fetchData={props.fetchData}
                setMessageInventory={props.messageInitial}
            />
        </div>
    );
}
