import {
  URL_DOMAIN,
  URL_HELP,
  URL_DOMAIN_MARKET,
  getJSON,
  URL_P2,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsGet,
} from "./globals";

const getWorkShiftForSalePointInDateRange = async (startDate, endDate, fkSalePoint) => {
  return fetch(
    URL_DOMAIN +
      `work-shift/get-work-shift-for-sale-point-in-date-range?start_date=${startDate}&end_date=${endDate}&fk_sale_point=${fkSalePoint}`,
    requestOptionsGet
  ).then(getJSON);
};


export { getWorkShiftForSalePointInDateRange }