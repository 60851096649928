import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import "../../../index.scss";
import "./SalePointTypeEdit.scss";
import "../SalePointTypeForm/SalePointTypeForm.scss";

import AddRelationSection from "../../../components/AddRelationSection/AddRelationSection";
import { useContext, useState } from "react";

import SalePointTypeContext from "../../../context/StoreIdContext";
import {
  addRelationSalePoint,
  deleteRelationSalePoint,
  getRelationsSalePoint,
  getSalePointTypeById,
  putSalePointType,
} from "../../../services/utilsSalePointType";
import { useEffect } from "react";
import { getAllSalePoint } from "../../../services/utilsSalePoint";

import CircularProgress from "@mui/material/CircularProgress";
import { LinearProgress } from "@material-ui/core";

import FeedbackAlert from "../../../components/FeedbackAlert/FeedbackAlert";
import { ReactSVG } from "react-svg";

const imagesIcons = {};

const importAll = (r) => {
  r.keys().forEach((key) => (imagesIcons[key] = r(key)));
};

importAll(
  require.context("../../../assets/market-icons/sptIconsSvg", true, /^\.\/ico-/)
);

const SalePointTypeEdit = () => {
  const { currentSalePointTypeId } = useContext(SalePointTypeContext);

  const [salePointTypeObj, setSalePointTypeObj] = useState();

  const [salePointsOptions, setSalePointsOptions] = useState([]);

  const [productCateoriesRelationList, setProductCateoriesRelationList] =
    useState([]);
  const [salePointRelationList, setSalePointRelationList] = useState([]);

  const [selectedOptionsSalePoint, setSelectedOptionsSalePoint] = useState("");

  const [submited, setSubmited] = useState(false);

  const [salePointTypeUpdatedSuccess, setSalePointTypeUpdatedSuccess] =
    useState(false);

  const [submitedRelation, setSubmitedRelation] = useState(false);

  const [sptSpAddRelationSuccess, setSptSpAddRelationSuccess] = useState();

  const [sptSpDeleteRelationSuccess, setSptSpDeleteRelationSuccess] =
    useState();

  //sale point icons
  const [selectedColor, setSelectedColor] = useState(null);

  const [selectedImageIcon, setSelectedImageIcon] = useState(null);

  const [svgDataSelected, setDataSvgSelected] = useState();

  const fetchData = () => {
    getSalePointTypeById(currentSalePointTypeId).then((data) => {
      // console.log("DATA BY ID", data.sale_point_type);
      let salePointType = data.sale_point_type;

      let productsInSpt = salePointType.product_category.map((pc) => {
        return { value: pc.id, name: pc.name };
      });

      let salePointInSpt = salePointType.sale_point.map((sp) => {
        return { value: sp.id, name: sp.name };
      });
      setProductCateoriesRelationList(productsInSpt);
      setSalePointRelationList(salePointInSpt);
      setSalePointTypeObj(salePointType);
    });

    fetchSalePoints();
  };

  const fetchSalePoints = () => {
    let fkOrganization = localStorage.getItem("fk_organization")
    getAllSalePoint(fkOrganization).then((data) => {
      let transformedAllSalePoints = data.sale_points.map((sp) => {
        return { value: sp.id, label: sp.name };
      });

      getRelationsSalePoint(currentSalePointTypeId).then((data2) => {
        let salePointsInRelation = data2.sale_points.map((sp) => {
          return { value: sp.id, label: sp.name };
        });
        let notInRealtion = transformedAllSalePoints.filter(
          (item) =>
            !salePointsInRelation.some((item2) => item2.value === item.value)
        );

        //Estas son las opciones

        setSalePointsOptions(notInRealtion);

        /**
         * 1. Opciones para los puntos de venta
         * 2. Lista de puntos de venta asociados
         * 3. Vaciar punto de venta - select
         */
      });
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmited(true);
    let formDataBody = new FormData();
    for (const key in salePointTypeObj) {
      formDataBody.append(key, salePointTypeObj[key]);
    }
    // Get the entries (key-value pairs) of the FormData object
    if((svgDataSelected)){
      console.log('typoof');
      formDataBody.append("principal_image", svgDataSelected, "image.png");
    }
    
    putSalePointType(formDataBody).then((data) => {
      setSalePointTypeObj(data.sale_point_type);
      setSubmited(false);
      setSalePointTypeUpdatedSuccess(true);
      setTimeout(() => {
        setSalePointTypeUpdatedSuccess(false);
      }, 5000);
    });
  };

  const handleInputChange = (event) => {
    setSalePointTypeObj({
      ...salePointTypeObj,
      [event.target.name]: event.target.value,
    });
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setSelectedImageIcon(null);
  };

  const handleImageSelect = (image) => {
    setSelectedImageIcon(image);
  };

  const handleSubmitAddRelation = () => {
    let formData = new FormData();
    formData.append("id", currentSalePointTypeId);
    formData.append("ids_sale_point", selectedOptionsSalePoint.value);

    setSalePointsOptions(null);
    setSubmitedRelation(true);
    addRelationSalePoint(formData).then((data) => {
      setSptSpAddRelationSuccess(true);
      let salePointsInRelation = data.sale_point_type.sale_point.map((sp) => {
        return { value: sp.id, name: sp.name };
      });
      setSalePointRelationList(salePointsInRelation);
      setSelectedOptionsSalePoint("");
      setSubmitedRelation(false);
    });

    fetchSalePoints();
  };

  const handleSubmitRemoveRelation = (id) => {
    setSalePointsOptions("");
    setSubmitedRelation(true);

    deleteRelationSalePoint(currentSalePointTypeId, id).then((data) => {
      setSptSpDeleteRelationSuccess(true);
      let salePointsInRelation = data.sale_point_type.sale_point.map((sp) => {
        return { value: sp.id, name: sp.name };
      });
      setSalePointRelationList(salePointsInRelation);
      fetchSalePoints();
      setSubmitedRelation(false);
    });
  };

  const addSectionRelation = () => {
    return (
      <AddRelationSection
        key={1}
        items={salePointRelationList}
        options={salePointsOptions}
        selectedOption={selectedOptionsSalePoint}
        setSelectedOptions={setSelectedOptionsSalePoint}
        handleSubmitPost={() => handleSubmitAddRelation()}
        handleSubmitDelete={(id) => handleSubmitRemoveRelation(id)}
        submitedRelation={submitedRelation}
      />
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {!salePointTypeObj && (
        <div className="center-container">
          <CircularProgress />
        </div>
      )}
      {salePointTypeObj && (
        <div className="row">
          <main className="section-body col-7 full-page">
            <h1 className="section-title">
              <FormattedMessage id="editSalePointTypelbl" />
            </h1>
            <form action="" className="row g-3" onSubmit={handleSubmit}>
              <div className="col-6 mb-3">
                <TextField
                  required
                  className="form-control"
                  label={<FormattedMessage id="nameLbl" />}
                  value={salePointTypeObj.name ? salePointTypeObj.name : ""}
                  name="name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <TextField
                  required
                  className="form-control"
                  label={<FormattedMessage id="descriptionLbl" />}
                  rows={4}
                  multiline
                  value={
                    salePointTypeObj.description
                      ? salePointTypeObj.description
                      : ""
                  }
                  name="description"
                  onChange={handleInputChange}
                />
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  
                  <label className="form-label" htmlFor="franchiseInput">
                    Ícono
                  </label>

                 
                  <ColorSelector onSelect={handleColorSelect} />
                  {selectedColor && (
                    <ImageSelector
                      color={selectedColor}
                      onSelect={handleImageSelect}
                      setDataSvgSelected={setDataSvgSelected}
                    />
                  )}
                   {!selectedColor && (
                    <div>
                      <div className="icons-container">
                        <img
                          alt=""
                          id="img1"
                          className="icon-sale-point-type edit"
                          src={
                            salePointTypeObj.principal_image
                              ? salePointTypeObj.principal_image
                              : ""
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row justify-content-end end-section">
                <div className="col-12 d-flex justify-content-end">
                  <Link className="" to={`/pasillos`}>
                    <button className="btn btn-outline-primary m-2">
                      <FormattedMessage id="cancelLbl" />
                    </button>
                  </Link>
                  <button className="btn btn-primary m-2" type="submit">
                    {!submited && (
                      <FormattedMessage id={"saveSalePointTypelbl"} />
                    )}
                    {submited && <CircularProgress color="inherit" />}
                  </button>
                  {/* </Link> */}
                </div>
              </div>
              <FeedbackAlert
                idmessage={"sptUpdatedSuccess"}
                open={salePointTypeUpdatedSuccess}
                setOpen={setSalePointTypeUpdatedSuccess}
              />
            </form>
          </main>
          <aside className="col">
            <article className="section-body row aside">
              <h2 className="section-title">
                <FormattedMessage id="franchisesLbl" />
              </h2>
              {addSectionRelation()}
            </article>
            <article className="section-body row aside">
              <h2 className="section-title">
                <FormattedMessage id="productCategorylbl" />
              </h2>
              <AddRelationSection
                key={2}
                items={productCateoriesRelationList}
              />
            </article>
            <FeedbackAlert
              type={"info"}
              vertical={"bottom"}
              horizontal={"right"}
              idmessage={"addRelationSptSp"}
              open={sptSpAddRelationSuccess}
              setOpen={setSptSpAddRelationSuccess}
            />
            <FeedbackAlert
              type={"warning"}
              vertical={"bottom"}
              horizontal={"right"}
              idmessage={"remodeRelationSptSp"}
              open={sptSpDeleteRelationSuccess}
              setOpen={setSptSpDeleteRelationSuccess}
            />
          </aside>
        </div>
      )}
    </>
  );
};

export default SalePointTypeEdit;

//Auxiliar components
const ColorSelector = ({ onSelect }) => {
  /**
   * Handlers
   */

  const inputColorSelect = (event) => {
    const color = event.target.value;
    onSelect(color);
  };
  /**
   * Render
   */
  return (
    <div>
      <p>Para cambiar el ícono, seleciona un color:</p>
      <input type="color" onChange={inputColorSelect} />
    </div>
  );
};

const ImageSelector = ({ color, onSelect, setDataSvgSelected }) => {
  const [filteredImages, setFilteredImages] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);

  function filterImagesByColor() {
    const arr = Object.values(imagesIcons);
    return arr;
  }

  useEffect(() => {
    let images = filterImagesByColor();
    setFilteredImages(images);
  }, [color]);

  const handleImageSelect = async (image) => {
    setSelectedImage(image);
    onSelect(image);

    const svgPath = image.default; // Replace with the actual path to your SVG file
    convertSvgToJpg(svgPath, color)
      .then((imgDataUrl) => {
        let blobFile = dataURLToBlob(imgDataUrl);
        setDataSvgSelected(blobFile);
        // Further processing or saving of the image data URL
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="grip-images">
      {filteredImages &&
        filteredImages.map((image, i) => {
          return (
            <div onClick={() => handleImageSelect(image)}>
              <ReactSVG
                src={image.default}
                beforeInjection={(svg) => {
                  // Set the color of the SVG
                  svg.setAttribute("fill", color);
                  svg.setAttribute("width", "70");
                  svg.setAttribute("height", "70");
                }}
                className={
                  selectedImage === image ? "icon-spt active" : "icon-spt"
                }
              />
            </div>
          );
        })}
    </div>
  );
};

const convertSvgToJpg = async (svgPath, color) => {
  const response = await fetch(svgPath);
  const svgText = await response.text();

  const div = document.createElement("div");
  div.innerHTML = svgText;

  const svgElement = div.querySelector("svg");
  svgElement.setAttribute("fill", color);
  svgElement.setAttribute("width", "100");
  svgElement.setAttribute("height", "100");

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Increase resolution for better quality
  const resolutionMultiplier = 2;
  const originalWidth = parseFloat(svgElement.getAttribute("width"));
  const originalHeight = parseFloat(svgElement.getAttribute("height"));

  const width = originalWidth * resolutionMultiplier;
  const height = originalHeight * resolutionMultiplier;

  const scaleRatio = Math.min(width / originalWidth, height / originalHeight);
  const scaledWidth = originalWidth * scaleRatio;
  const scaledHeight = originalHeight * scaleRatio;

  canvas.width = width;
  canvas.height = height;

  const offsetX = (width - scaledWidth) / 2;
  const offsetY = (height - scaledHeight) / 2;

  const svgXML = new XMLSerializer().serializeToString(svgElement);
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = () => {
      context.fillStyle = "white"; // Set white background color
      context.fillRect(0, 0, width, height);
      context.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }, "image/jpeg");
    };

    img.onerror = reject;

    img.src = `data:image/svg+xml;base64,${btoa(svgXML)}`;
  });
};

function dataURLToBlob(dataURL) {
  const base64Data = dataURL.split(",")[1];
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: "image/jpeg" });
}
