import React from "react";
import { useState } from "react";
import CreatableSelect  from "react-select/creatable";
import { createTag } from "../../services/utilsTags";
import "./TagInput.scss";
// Define the custom styles for the multi-value (tags) background and text color
const customStyles = {
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.color,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

// Here is your CustomOption component unchanged:
const CustomOption = (props) => {
  const colorsAvailable = [
    { name: "Light gray", hex: "#D3D3D3" },
    { name: "Gray", hex: "#808080" },
    { name: "Brown", hex: "#A52A2A" },
    { name: "Orange", hex: "#FFA500" },
    { name: "Yellow", hex: "#FFFF00" },
    { name: "Green", hex: "#DFECDA" },
    { name: "Blue", hex: "#0000FF" },
    { name: "Purple", hex: "#800080" },
    { name: "Pink", hex: "#FFC0CB" },
    { name: "Red", hex: "#FF0000" }
  ];
  const { color, label } = props.data;

  const [openEditTag, setOpenEditTag] = useState(false);

  const handleMoreOptionsClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation(); // Add this line
    console.log('Clicked for edit options');
    setOpenEditTag(true);
  };

  if (props.isDisabled) {
    return null;
  }

  return (

    <div {...props.innerProps} className="custom-option">
      <div className="color-box" style={{ backgroundColor: color }}>
        <p className="option-label">{label}</p>
      </div>
      {/* <button onClick={handleMoreOptionsClick} className="more-options-button">
        &#x2022; &#x2022; &#x2022; 
      </button> */}
      {openEditTag && (
        <div className="edit-tag-panel">
          <input type="text" placeholder="Update tag name" />
          <div className="colors-container">
            <p>Select color:</p>
            {colorsAvailable.map((colorItem) => (
              <div key={colorItem.name} className="color-option">
                <div
                  className="color-square"
                  style={{ backgroundColor: colorItem.hex }}
                  onClick={() => {
                    console.log(`Color picked: ${colorItem.name}`);
                    // Add logic to set color or close the dropdown
                  }}
                ></div>
                <p>{colorItem.name}</p>
              </div>
            ))}
          </div>
          <button onClick={() => setOpenEditTag(false)}>Close</button>
        </div>
      )}
    </div>
  );
};

const TagInput = ({tags, setTags, selectedTags, setSelectedTags}) => {

  const colorsAvailable = [
    { name: "Light gray", hex: "#F1F0F0" },
    { name: "Gray", hex: "#E3E2E0" },
    { name: "Brown", hex: "#EEDFDA" },
    { name: "Orange", hex: "#F9DEC9" },
    { name: "Yellow", hex: "#FDECC8" },
    { name: "Green", hex: "#DBECDB" },
    { name: "Blue", hex: "#D3E4EF" },
    { name: "Purple", hex: "#E7DEEE" },
    { name: "Pink", hex: "#F2DFEA" },
    { name: "Red", hex: "#FFE2DD" }
  ];
  const handleChange = (newValue, { action }) => {
    setSelectedTags(newValue);
  };
  const handleCreateOption = (inputValue) => {
    const randomColor = colorsAvailable[Math.floor(Math.random() * colorsAvailable.length)];
    
    //We have to create the tag here
    let fkOrganization = localStorage.getItem('fk_organization');
    const jsonBody =  { fk_organization: fkOrganization, name: inputValue, color: randomColor.hex, type: 0 };
    createTag(JSON.stringify(jsonBody)).then((data) =>{
      const newOption = { value: data.tag.id, label: inputValue, color: randomColor.hex };
      setTags([...tags, newOption]);
      setSelectedTags([...selectedTags, newOption]);
    });
    

  };
  return (
    <CreatableSelect
      components={{ Option: CustomOption }}
      value={selectedTags}
      isMulti
      styles={customStyles}
      options={tags}
      className="basic-multi-select"
      classNamePrefix="select"
      isClearable={true}
      onCreateOption={handleCreateOption}
      onChange={handleChange}

    />
  );
};

export default TagInput;
