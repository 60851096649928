import React, { useEffect, useState } from "react";
import "../../../index.scss";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "../../../assets/icons/white-create-24px.svg";
import {
  TextField,
  FormControlLabel,
  Switch,
  Checkbox,
  Select,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import { getFranchiseTypes } from "../../../services/utilsFranchiseType/utilsFranchiseType";
import {
  getIngredient,
  postIngredient,
  putIngredient,
} from "../../../services/utilsIngredient/utilsIndredient";
import "./ProductCategoryForm.scss";
import { getPaginatedProductCategories, getProductCategory, postProductCategory, putProductCategory } from "../../../services/utilsProductCategory/utilsProductCategory";
import { Alert } from "@mui/material";
import { useHistory } from "react-router-dom";
import {getAllSalePoint} from "../../../services/utilsSalePoint";
import {getAllSalePointType} from "../../../services/utilsSalePointType";
import CreatableSelect from "react-select/creatable";

export const ProductCategoryForm = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------

  const [productCategory, setProductCategory] = useState({});

  const [loading, setLoading] = useState(true);

  const [formTitle, setFormTitle] = useState("createProductCategory");

  const [submitLabel, setSubmitLabel] = useState("createProductCategoryLbl");

  const [salePointTypeList, setSalePointTypeList] = useState([]);

  const [selectedSalePointType, setSelectedSalePointType] = useState("");

  const [submitted, setSubmitted] = useState(false)

  const urlLocation = useLocation();

  const [snackbarMessage, setSnackbarMessage] = useState(<Alert severity="success">NONE</Alert>)

  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  const [successSnackbar, setSuccessSnackbar] = useState(false)

  const [selectedSpt, setSelectedSpt] = useState([]);

  const history = useHistory();

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------

  const fetchData = async () => {
    getAllSalePointType().then((data) => {
      let sptOptions = data["sale_points_type"].map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSalePointTypeList(sptOptions);
      setLoading(false);
    });

    if (urlLocation.pathname.includes("edit")) {
      let fk_product_category = localStorage.getItem("fk_product_category");
      setFormTitle("editProductCategory");
      setSubmitLabel("saveProductCategoryLbl");

      getProductCategory(fk_product_category).then((data) => {
        setProductCategory(data.product_category);
        // setSelectedSalePointType(data.product_category.sale_point_type.id);
        let salePointTypeData = data.product_category.sale_point_types.map(({ sale_point_type }) => ({
          value: sale_point_type.id,
          label: sale_point_type.name,
        }));
        setSelectedSpt(salePointTypeData);
        // setLoading(false);
      });
    } else {

    }
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------

  const handleInputChangeInner = (event) => {
    setProductCategory({ ...productCategory, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true)
    let formDataBody = new FormData();
    for (const key in productCategory) {
      formDataBody.append(key, productCategory[key]);
    }
    //Check the salePoint type
    let spt_array = selectedSpt.map((spt)=> {
      if(spt.value !== 0){
        return spt.value
      }else{
        return spt.label
      }
    });
    formDataBody.append("fk_sale_point_types", spt_array.join(','))
    // if (selectedSalePointType.id) {
    //   formDataBody.append("fk_sale_point_type", selectedSalePointType.id);
    // }
    if (urlLocation.pathname.includes("edit")) {
      formDataBody.append("fk_product_category", productCategory.id);

      putProductCategory(formDataBody).then((data) => {
        if(data.error === undefined){
          setSuccessSnackbar(true)
          setSnackbarMessage(<Alert severity="success">Se ha actualizado la categoría de producto con éxito</Alert>);
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false)
          setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    } else {
      // let supervisorId = localStorage.getItem("fk_supervisor")
      // formDataBody.append("fk_supervisor", supervisorId)
      let fk_organization = localStorage.getItem("fk_organization");
      formDataBody.append("fk_organization", fk_organization)
      postProductCategory(formDataBody).then((data)=> {
        if(data.error === undefined){
          setSuccessSnackbar(true)
          setSnackbarMessage(<Alert severity="success">Se ha creado la categoría de producto con éxito</Alert>);
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false)
          setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      })
    }



    
  };

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  const handleSnackbarClose = (event, reason, success=false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (successSnackbar){
      history.push("/productos");
    }
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <main className="section-body">
      <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}

                >
                 {snackbarMessage}
                </Snackbar>
      <h1 className="section-title">
        <FormattedMessage id={formTitle} />
      </h1>
      {!loading && (
        <form
          onSubmit={handleSubmit}
          className="row g-3"
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="col-6 mb-3">
            <TextField
              required
              className="form-control"
              id="standard-basic"
              label={<FormattedMessage id="nameLbl" />}
              value={productCategory.name ? productCategory.name : ""}
              name="name"
              onChange={handleInputChangeInner}
            />
          </div>
          <div className="mb-3 description-container">
            <TextField
              required
              className="form-control"
              id="standard-basic"
              label={<FormattedMessage id="descriptionLbl" />}
              value={productCategory.description ? productCategory.description : ""}
              name="description"
              onChange={handleInputChangeInner}
              rows={4}
              multiline
            />
          </div>
          <div className="row">
            <div className="col-6 mb-3">
              <label className="form-label" htmlFor="franchiseTypeInput">
                <FormattedMessage id="franchiseTypeLbl" />
              </label>
              {salePointTypeList && (
                <Autocomplete
                  options={salePointTypeList}
                  selectedOptions={selectedSpt}
                  setSelected={setSelectedSpt}
                />
              )}
              {/* <Select
                disabled={urlLocation.pathname.includes("edit")}
                required
                value={productCategory.fk_sale_point_type ? productCategory.fk_sale_point_type :(productCategory.sale_point_type)?productCategory.sale_point_type.id: ""}
                name="fk_sale_point_type"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="franchiseTypeInput"
              >
                {salePointTypeList &&
                  salePointTypeList.map((franchise_type, i) => (
                    <MenuItem key={i} value={franchise_type.id}>
                      {franchise_type.name}
                    </MenuItem>
                  ))}
              </Select> */}
            </div>
            <div className="col-6 mb-3 ">
            {/* <TextField
              required
              className="form-control"
              id="standard-basic"
              type="number"
              inputProps={{ min: 0 }}
              label={<FormattedMessage id="priorityLbl" />}
              value={productCategory.priority !== undefined? productCategory.priority : ""}
              name="priority"
              onChange={handleInputChangeInner}
            /> */}
            <label className="form-label" htmlFor="typeInput">
                <FormattedMessage id="typeLbl" />
              </label>
              <Select
                required
                value={productCategory.type !== undefined ? productCategory.type : ""}
                name="type"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="typeInput"
              >
                <MenuItem value={0}>Producto</MenuItem>
                {/*<MenuItem value={1}>Ingrediente</MenuItem>*/}
              </Select>
          </div>
          </div>
          <div className="row">
            <div className="col-6 mb-3">
              
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-12 d-flex justify-content-end">
              <Link className="" to={`/productos`}>
                <button className="btn btn-outline-primary m-2">
                  <FormattedMessage id="cancelLbl" />
                </button>
              </Link>
              <button className="btn btn-primary m-2" type="submit" disabled={submitted}
              >
                {!submitted && <FormattedMessage id={submitLabel} />}
                {submitted && (
                  <div className="d-flex justify-content-center spinner-container">
                    <div
                      className="spinner-border spinner-primary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </main>
  );
};

const Autocomplete = ({ options, selectedOptions, setSelected }) => {
  const handleChange = (newValue, { action }) => {
    setSelected(newValue);
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: 0, label: inputValue };
    setSelected([...selectedOptions, newOption]);
  };

  return (
    <CreatableSelect
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      isMulti
      isClearable
      isSearchable
      placeholder="Selecciona un tipo de negocio"
    />
  );
};
