export const Role = {
    SUPERVISOR: 1,
    ADMIN: 2
}

export const RoleType = {
    SUPERVISOR: 0,
    ACCOUNTING: 1,
    ADMIN: 2,
    SUPPORT: 3,
    MANAGER: 4
}
