import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getTagsOfOrganization } from "../../../services/utilsTags";
import { useHistory } from "react-router-dom";
import "./TagsListPage.scss"; // Assuming you have a CSS file for styles
import SearchBar from "../../../components/searchBar/searchBar";
import { LinearProgress } from "@material-ui/core";


const TagsListPage = () => {
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [loadingData, setLoadingData] = useState(false);

  let history = useHistory();

  useEffect(() => {
    let fkorganization = localStorage.getItem("fk_organization");
    let isMounted = true; // Flag to track mounting status
    setLoadingData(true);

    getTagsOfOrganization(fkorganization).then((data) => {
      if (isMounted) { // Only update state if component is mounted
        setTags(data.tags);
        setFilteredTags(data.tags);
        setLoadingData(false);
      }
    });

    return () => {
      isMounted = false; // Set flag to false when the component unmounts
    };
  }, []);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = tags.filter((tag) =>
      tag.name.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredTags(filtered);
  }, [searchQuery, tags]);

  const handleTextSearch = (event) => {
    console.log(event);
    setSearchQuery(event);
  };
  const goTags = (tag) => {
    localStorage.setItem("fk_tag", tag.id);
    // navigate('/manejo-etiquetas/:id');
    history.push("/manejo-etiquetas/:id");
  };
  return (
    <main className="section-body">
      <h1 className="section-title">
        <FormattedMessage id="tagsTitle" />
      </h1>
      <article>
        <section>
          <SearchBar
            input={searchQuery}
            onChange={handleTextSearch}
            nombre="Etiquetas"
          />
        </section>
        {loadingData && <LinearProgress />}
        <section className="tags-container">
          {filteredTags &&
            filteredTags.map((tag, index) => <TagItem key={index} tag={tag} />)}
        </section>
      </article>
    </main>
  );
};

const TagItem = ({ tag }) => {
  let history = useHistory();

  const tagStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100px", // Set desired width
    height: "100px", // Set desired height
    backgroundColor: tag.color,
    color: "white", // Or any contrasting color for readability
    cursor: "pointer", // To indicate it's clickable
    margin: "5px", // Optional, for spacing between tags
    borderRadius: "5px", // Optional, for rounded corners
    padding: "8px",
  };

  const goTags = () => {
    localStorage.setItem("tag", JSON.stringify(tag));
    // navigate('/manejo-etiquetas/:id');
    history.push("/manejo-etiquetas/:id");
  };

  const trasformType = (type) => {
    switch (type) {
      case 0:
        return "Excluyente";
      case 1:
        return "Perecedero";
      default:
        return "";
    }
  };
  return (
    <div style={tagStyle} onClick={goTags}>
      <div className="col">
      <div className="row">
        <p>{tag.name}</p>
      </div>
      <div className="row">
        <p>{trasformType(tag.type)}</p>{" "}
      </div>

      </div>
          </div>
  );
};

export default TagsListPage;
