import "./TransactionInProgress.scss";
const TransactionInProgress = () => {
  return (
    <main>
      <div className="bg-ot-primary">
        <div className="row">
          <div className="d-flex justify-content-center spinner-container">
            <div className="spinner-border spinner-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          <div className="col padingg-16">
            <h1>Gracias por usar OntTrack Market</h1>
            <h6>Tu transacción esta en progreso</h6>
            <h4>
              Seras re dirigido a la aplicación y el estado de tu transación
              sera actualizada automaticamente
            </h4>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TransactionInProgress;
