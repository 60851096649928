import { useEffect, useState } from "react";
import "./ProductInventory.scss";
import { getAllSalePoint } from "../../../services/utilsSalePoint";
import ProductInventoryDetailComponent from "../../../components/supervisor/ProductInventoryComponents/ProductInventoryDetailComponent";
import { useParams } from "react-router-dom";
import GraphInventoryComponent from "../../../components/supervisor/ProductInventoryComponents/GraphInventoryComponent";

const ProductInventory = () => {
  const fk_organization = parseInt(localStorage.getItem("fk_organization"));
  const fk_sale_point = parseInt(localStorage.getItem("fk_sale_point"));
  const { id } = useParams();
  const [salePoints, setSalePoints] = useState([]);
  const [selectedSalePoint, setSelectedSalePoint] = useState({});
  const [loading,setLoading] =useState(true)

  const updateSelectedSalePoint = (newSalePoint) => {
    setSelectedSalePoint(newSalePoint);
  };

  const fetchData = () => {
    getAllSalePoint(fk_organization).then((data) => {
      let salePointsArray = data.sale_points.map((salePoint) => {
        if (salePoint.id == fk_sale_point) {
          setSelectedSalePoint({ id: salePoint.id, name: salePoint.name });
        }
        return { id: salePoint.id, name: salePoint.name };
      });
      setSalePoints(salePointsArray);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log(selectedSalePoint);
  }, [selectedSalePoint]);

  return (
    <div className="productInventoryMain">
      <div className="columnProductInventoryDetail">{!loading && (
        <ProductInventoryDetailComponent
          fk_organization={fk_organization}
          fk_product={id}
          salePoints = {salePoints}
          selectedSalePoint={selectedSalePoint}
          updateSelectedSalePoint = {updateSelectedSalePoint}
        ></ProductInventoryDetailComponent>)}
      </div>
      <div className="columnProductInventoryGraph">{( !loading &&
        <GraphInventoryComponent
          selectedSalePoint={selectedSalePoint}
          fk_product={id}
        ></GraphInventoryComponent>)}
      </div>
    </div>
  );
};

export default ProductInventory;
