import React, { useContext } from "react";
import { Link } from "react-router-dom";
// import "./ProductCard.scss";
import ProductDefaultImg from "../../../assets/market-icons/producto.jpg";
import CurrencyFormat from "react-currency-format";
import EditIcon from "../../../assets/icons/edit-icon-24px.svg";
import SeeIcon from "../../../assets/icons/baseline-info-24px.svg";
import "./SalePointTypeCard.scss";
import SalePointTypeContext from "../../../context/StoreIdContext";

const SalePointTypeCard = ({ salePointType }) => {
  let getImage = () => {
    if (
      salePointType.image === undefined ||
      salePointType.image === null ||
      salePointType.image === "N/A"
    ) {
      return ProductDefaultImg;
    } else {
      return salePointType.image;
    }
  };

  let nameConverted = () => {
    let name = salePointType.name;
    if (name.length > 30) {
      name = name.substr(0, 30) + "...";
    }
    if (name.indexOf("%") > -1) {
      return name.replace("%", "-");
    } else if (name.indexOf("/") > -1) {
      return name.replace("/", "-");
    } else {
      return name;
    }
  };

  const { setCurrentSalePointTypeId } = useContext(SalePointTypeContext);

  const handleClickId = () => {
    console.log(salePointType)
    setCurrentSalePointTypeId(salePointType.id);
  };

  return (
    <div className="card">
      <img
        src={getImage()}
        alt=""
        className="card-img-spt"
      />
      <div className="card-body">
        <div className="row sale-point-type">
          <h5 className="card-title">{salePointType.name}</h5>
        </div>
        <div className="row info-card">
          <div className="col">
            <div className="icon-row">
              {/**<Link
                         className="product-card-icon"
                         to={`/productos/${productName()}/edit`}
                         onClick={goProduct}
                         >
                         <img src={SeeIcon} alt="see_icon" />
                         </Link>*/}
              <Link
                className="product-card-icon"
                to={`/pasillos/${nameConverted()}/edit`}
                onClick={handleClickId}
              >
                <img src={EditIcon} alt="edit_icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalePointTypeCard;
