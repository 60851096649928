import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { TextField } from "@material-ui/core";
import Select, { StylesConfig } from "react-select";
import { Link, useLocation } from "react-router-dom";

import chroma from "chroma-js";
import {
  editTag,
  getProductsOfTag,
  getSalePointsOfTag,
} from "../../../services/utilsTags";
import { useEffect } from "react";
import RowProduct from "../../../components/RowProduct/RowProduct";
import FeedbackAlert from "../../../components/FeedbackAlert/FeedbackAlert";
import "./TagsPageManagement.scss";

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 15,
    width: 15,
  },
});

const TagsPageManagement = () => {
  const [tag, setTag] = useState(() => {
    const storedTag = localStorage.getItem("tag");
    return storedTag ? JSON.parse(storedTag) : { name: "", color: "", type: 0 };
  });

  const [products, setProducts] = useState();

  const [salePoints, setSalePoints] = useState();

  const urlLocation = useLocation();

  const [menuIsOpen, setMenuOpen] = useState(false);

  const [successEditing, setSuccessEditing] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prevMenuIsOpen) => !prevMenuIsOpen);
  };

  const colorsAvailable = [
    { label: "Light gray", value: "#F1F0F0" },
    { label: "Gray", value: "#E3E2E0" },
    { label: "Brown", value: "#EEDFDA" },
    { label: "Orange", value: "#F9DEC9" },
    { label: "Yellow", value: "#FDECC8" },
    { label: "Green", value: "#DBECDB" },
    { label: "Blue", value: "#D3E4EF" },
    { label: "Purple", value: "#E7DEEE" },
    { label: "Pink", value: "#F2DFEA" },
    { label: "Red", value: "#FFE2DD" },
  ];

  let optionsSelect = [
    { value: "0", label: "Excluyente" },
    { value: "1", label: "Perecedera" },
  ];

  const colourStyles = {
    // control: (styles) => ({ ...styles, backgroundColor: "white" }),
    // input: (styles) => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...dot(data.value),
    }),
  };

  const handleChange = (selectedOption) => {
    setTag({ ...tag, type: selectedOption.value });
  };

  const handleColorChange = (selectedOption) => {
    setTag({ ...tag, color: selectedOption.value });
  };

  const handleInputChangeInner = (event) => {
    setTag({ ...tag, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    editTag(JSON.stringify(tag)).then((data) => {
      console.log(data, "DONE ");
      if (data.tag) {
        setSuccessEditing(true);
        localStorage.setItem("tag", JSON.stringify(data.tag));
      }
    });
  };

  const fetchData = async () => {
    let dataP = await getProductsOfTag(tag.id);

    let dataSP = await getSalePointsOfTag(tag.id);

    setSalePoints(dataSP.sale_points);
    setProducts(dataP.products);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <main className="row">
      <section className="col-7">
        <article className="section-body">
          <h1 className="section-title">Manejo de etiquetas</h1>
          {tag && (
            <form onSubmit={handleSubmit}>
              <div className="col-12 mb-2">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="nameLbl" />}
                  value={tag.name ? tag.name : ""}
                  name="name"
                  onChange={handleInputChangeInner}
                />
              </div>
              <div className="col-12 mb-2">
                <label className="form-label" htmlFor="franchiseTypeInput">
                  Color
                </label>
                <Select
                  components={{
                    Option: (optionProps) => (
                      <ColorOption
                        {...optionProps}
                        onSelect={handleColorChange}
                        toggleMenu={toggleMenu}
                      />
                    ),
                  }}
                  value={
                    tag.color
                      ? colorsAvailable.find(
                          (color) => color.value === tag.color
                        )
                      : null
                  }
                  options={colorsAvailable}
                  styles={colourStyles}
                  menuIsOpen={menuIsOpen} // Ensure menuIsOpen is passed here
                  onMenuOpen={() => setMenuOpen(true)} // Open menu when clicked
                  onMenuClose={() => setMenuOpen(false)} // Close menu when clicked outside
                  isSearchable={false}
                />
              </div>
              <div className="col-12 mb-2">
                <label className="form-label" htmlFor="franchiseTypeInput">
                  Tipo de etiqueta
                </label>
                <Select
                  defaultValue={
                    optionsSelect.find(
                      (option) => option.value === tag.type.toString()
                    ) || optionsSelect[0]
                  }
                  onChange={handleChange}
                  options={optionsSelect}
                  className="worker-form-select"
                  // id="typeInput"
                />
              </div>
              <div className="row justify-content-end">
                <div className="col-12 d-flex justify-content-end">
                  <Link className="" to={`/etiquetas`}>
                    <button className="btn btn-outline-primary m-2">
                      <FormattedMessage id="cancelLbl" />
                    </button>
                  </Link>
                  <button className="btn btn-primary m-2" type="submit">
                    <FormattedMessage id="saveTagLbl" />
                  </button>
                </div>
              </div>
            </form>
          )}
        </article>
      </section>
      <section className="col-5">
        <article className="section-body">
          <h2 className="section-title">Puntos de venta</h2>
          <div className="">
          {salePoints &&
            salePoints.map((sp) => {
              return (
                <div className="row-product">
                  <p className="row-product__name">{sp.name}</p>
                </div>
              );
            })}
          </div>
          
        </article>
        <article className="section-body">
          <h2 className="section-title">Productos</h2>
          <div className="container-row-tags">
          {products &&
            products.map((product) => <RowProduct product={product} />)}
          </div>
         
        </article>
      </section>
      <FeedbackAlert
        idmessage={"sptUpdatedSuccess"}
        open={successEditing}
        setOpen={setSuccessEditing}
      />
    </main>
  );
};

// Custom Option component to display color square and color name
const ColorOption = ({ data, isSelected, onSelect, toggleMenu }) => {
  const [isHovered, setIsHovered] = useState(false);
  const darkenBackgroundColor = (color) => chroma(color).darken(1.5).css();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        border: "none",
        background:
          isHovered || isSelected ? darkenBackgroundColor(data.value) : "none",
        color: isHovered || isSelected ? "white" : "black",
        cursor: "pointer",
        padding: "8px",
        borderRadius: "5px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        onSelect(data);
        toggleMenu(); // Toggle the menu when an option is selected
      }}
    >
      <div
        style={{
          width: "15px",
          height: "15px",
          backgroundColor: data.value,
          marginRight: "8px",
          borderRadius: 10,
        }}
      ></div>
      {data.label}
    </div>
  );
};

export default TagsPageManagement;
