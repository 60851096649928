import {
  URL_DOMAIN,
  URL_DOMAIN_MARKET,
  getJSON,
  URL_P2,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsDelete
} from "../globals";

const URL_FINAL = URL_DOMAIN + URL_P2[1];

const supervisorId = localStorage.getItem("fk_supervisor");
/**
 * Franchises
 * @returns
 */
 
const getWorkersOfOrganization = async (fkOrganization) => {
  return fetch(
    URL_DOMAIN +
      "workers/get-all-workers-of-organization?fk_organization=" +
      fkOrganization,
    requestOptionsGet
  ).then(getJSON);
};

const getWorkerById = async (id) => {
  return fetch(
    URL_DOMAIN + "workers/get-worker?fk_worker=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const postWorker = async (formData) => {
  requestOptionsPost.body = formData;
  console.log(formData.values)
  return fetch(URL_DOMAIN + "workers/create-worker", requestOptionsPost).then(
    getJSON
  );
};

const putWorker = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_DOMAIN + "workers/edit-worker", requestOptionsPut).then(
    getJSON
  );
};

const getSalePointsOfWorker = async (fkOrganization,id) => {
  return fetch(
    // URL_DOMAIN + "workers/get-worker?fk_worker=" + id,
    URL_DOMAIN + "sale-point-worker/get-sale-points?fk_organization=" + fkOrganization + "&fk_worker=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const createSalePointWorker = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(URL_DOMAIN + "sale-point-worker/create", requestOptionsPost).then(
    getJSON
  );
};

const deleteSalePointWorker = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(URL_DOMAIN + "sale-point-worker/delete", requestOptionsPost).then(
    getJSON
  );
};

export { getWorkersOfOrganization, getWorkerById, postWorker, putWorker, getSalePointsOfWorker, createSalePointWorker, deleteSalePointWorker};
