import CreatableSelect from "react-select/creatable";

const AutocompleteInput = ({ options, selectedOptions, setSelected, isClearable=false, isSearchable=true, isMulti=false, placeholder="", requiered=true }) => {
    const handleChange = (newValue, { action }) => {
      setSelected(newValue);
    };
  
    const handleCreateOption = (inputValue) => {
      const newOption = { value: 0, label: inputValue };
      setSelected([...selectedOptions, newOption]);
    };
  
    return (
      <CreatableSelect
        required={requiered}
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        onCreateOption={handleCreateOption}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        placeholder={placeholder}
      />
    );
  };

export default AutocompleteInput;
  