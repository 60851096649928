import React, { useEffect, useState } from "react";
import "../../../index.scss";
import { FormattedMessage } from "react-intl";
import SalePointDefaultImg from "../../../assets/market-icons/punto-de-venta.jpg";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "../../../assets/icons/white-create-24px.svg";
import {
  TextField,
  FormControlLabel,
  Switch,
  Snackbar,
  MenuItem,
  Select,
} from "@material-ui/core";

import CreatableSelect from "react-select/creatable";

import {
  getSalePointsOfOrganization,
  getFranchisesById,
  postFranchise,
  putFranchise,
} from "../../../services/utilsFranchise/utilsFranchise";
import { getFranchiseTypes } from "../../../services/utilsFranchiseType/utilsFranchiseType";
// import GoogleApiWrapper from "../../../components/supervisor/GoogleMap/Map";
import SimpleMap from "../../../components/supervisor/GoogleMap/GoogleMap";
import "./FranchiseForm.scss";
import { Alert } from "@mui/material";
import { useHistory } from "react-router-dom";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {
  getAllSalePointType,
  getSalePointTypeById,
} from "../../../services/utilsSalePointType";
import {
  getCoursesBySalePoint,
  getSalePoint,
  getSalePointById,
} from "../../../services/utilsSalePoint";
import RelatedCoursesComponent from "../../../components/supervisor/SalePoint/RelatedCoursesComponent";
import { getCoursesByOrganization } from "../../../services/utilsCourses/utilsCourses";
import {
  getProductsOfTag,
  getRestrictedProductsOfSalePoint,
  getTagsOfOrganization,
  getTagsOfSalePoint,
  modifyTagWithSalePoint,
} from "../../../services/utilsTags";
import TagInput from "../../../components/TagsInput/TagInput";
import RowProduct from "../../../components/RowProduct/RowProduct";

export const SalePointForm = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------

  const [salePointTypeList, setSalePointTypeList] = useState();

  const [selectedSpt, setSelectedSpt] = useState([]);

  const [salePointId, setSalePointId] = useState(
    localStorage.getItem("fk_sale_point")
  );

  const [organizationId, setOrganizationId] = useState(
    localStorage.getItem("fk_organization")
  );

  const [imgSource, setImgSource] = useState(SalePointDefaultImg);

  const [salePoint, setSalePoint] = useState({});

  const [loading, setLoading] = useState(true);

  const [courses, setCourses] = useState([]);

  const [organizationCourses, setOrganizationCourses] = useState([]);

  const [formTitle, setFormTitle] = useState("createNewFranchise");

  const [submitLabel, setSubmitLabel] = useState("createFranchiseLbl");

  const [sellPrice, setSellPrice] = useState();

  const [map, setMap] = useState(<div></div>);

  const [latLng, setLatLng] = useState({});

  let newProfilePicture;

  const hiddenFileInput = React.useRef(null);

  const urlLocation = useLocation();

  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );

  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [selectedCourses, setSelectedCourses] = useState();

  const history = useHistory();

  const colCities = [
    "Bogotá",
    "Medellín",
    "Cali",
    "Barranquilla",
    "Cartagena",
    "Cúcuta",
    "Bucaramanga",
    "Pereira",
    "Santa Marta",
    "Ibagué",
    "Valledupar",
    "Manizales",
    "Pasto",
    "Montería",
    "Neiva",
    "Tunja",
    "Riohacha",
    "Sincelejo",
    "Armenia",
    "Popayán",
    "Villavicencio",
    "Quibdó",
    "Florencia",
    "Yopal",
    "Mocoa",
    "Mitú",
    "San Andrés",
    "Leticia",
    "Puerto Carreño",
  ];

  //Tags management

  const [tags, setTags] = useState();

  const [selectedTags, setSelectedTags] = useState([]);

  const [productsInTags, setProductInTags] = useState([]);

  const [loadingProductsTags, setLoadingProductsTags] = useState(true);
  function uniques(listA, listB) {
    return listA.filter((objectA) => {
      return !listB.some((objectB) => objectB.id === objectA.id);
    });
  }

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------

  const fetchData = async () => {
    let fkOrganization = localStorage.getItem("fk_organization");
    getAllSalePointType(fkOrganization).then((data) => {
      let sptOptions = data["sale_points_type"].map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSalePointTypeList(sptOptions);
      setLoading(false);
      setLoadingProductsTags(false);
    });
    

    if (urlLocation.pathname.includes("edit")) {
      let salePointId = localStorage.getItem("fk_sale_point");
      setFormTitle("editSalePoint");
      setSubmitLabel("editSalePointlbl");

      await getCoursesBySalePoint(salePointId).then((data1) => {
        setCourses(data1.courses);
        getCoursesByOrganization(organizationId).then((data2) => {
          // const nuevaLista1 = data1.courses.filter(objeto1 => !data2.courses.some(objeto2 => objeto2.id === objeto1.id));


          const list1 = uniques(data2.courses, data1.courses);

          let transformedData = data2.courses.map((spt) => {
            return { label: spt.name, value: spt.id + "" };
          });
          setOrganizationCourses(transformedData);
        });
      });

      await getSalePointById(salePointId).then((data) => {
        let salePointData = data.sale_point;
        setSalePoint(salePointData);
        let salePointTypeData = salePointData.sale_point_type.map(
          ({ id, name }) => ({
            value: id,
            label: name,
          })
        );
        setSelectedSpt(salePointTypeData);
        salePointData.image
          ? setImgSource(salePointData.image)
          : setImgSource(imgSource);
      });

      await getCoursesBySalePoint(salePointId).then((data1) => {
        setCourses(data1.courses);
        getCoursesByOrganization(organizationId).then((data2) => {
          // const nuevaLista1 = data1.courses.filter(objeto1 => !data2.courses.some(objeto2 => objeto2.id === objeto1.id));

          let transformedData = data2.courses.map((spt) => {
            return { label: spt.name, value: spt.id + "" };
          });
          setOrganizationCourses(transformedData);
        });
      });

      await getTagsOfSalePoint(salePointId).then((data) => {
        let tagsTransformed = data.tags.map((tag) => {
          let tagsName = tag.name; // You're assigning the tag name to a variable, which is good.
          
          if (tag.type === 1) { 
            tagsName += " (P)"; // Correctly appending " (P)" if tag.type is 1.
          }
          
          return {
            value: tag.id,
            label: tagsName, // Updated to use the correctly formatted tagsName.
            color: tag.color,
          };
        });
        setSelectedTags(tagsTransformed);
      });

      await getRestrictedProductsOfSalePoint(salePointId).then((data) => {
        setProductInTags(data.products);
        setLoadingProductsTags(false);
      });

      await getTagsOfOrganization(fkOrganization).then((data) => {
        let tagsTransformed = data.tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
          color: tag.color,
        }));
        setTags(tagsTransformed);
      });
      // const nuevaLista1 = courses.filter(objeto1 => !organizationCourses.some(objeto2 => objeto2.id === objeto1.id));
      // console.log(nuevaLista1);
      // setOrganizationCourses(nuevaLista1);
      // getFranchisesById(salePointId).then((data) => {

      //   let salePointData = data.sale_point;

      //   setSalePoint(salePointData);
      //   setSelectedSpt(salePointData.sale_point_type);
      //   // setSalePointType(franchiseData.franchise_type);
      //   setSellPrice(salePointData.sales_goal);
      //   salePointData.image
      //     ? setImgSource(salePointData.image)
      //     : setImgSource(imgSource);
      //   if (salePointData.latitude && salePointData.longitude) {
      //     // setMap(
      //     //   <SimpleMap
      //     //     center={{
      //     //       lat: franchiseData.latitude,
      //     //       lng: franchiseData.longitude,
      //     //     }}
      //     //     handleLocationChanged={handleLocationChanged}
      //     //   />
      //     // );
      //   } else {
      //     setMap(<SimpleMap handleLocationChanged={handleLocationChanged} />);
      //   }
      // });
    } else {
      setMap(<SimpleMap handleLocationChanged={handleLocationChanged} />);
    }
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  useEffect(() => {
    if (selectedTags.length > 0) {
      let salePointId = localStorage.getItem("fk_sale_point");
      let idTags = selectedTags.map((tag) => tag.value);

      let jsonBody = {
        fk_sale_point: salePointId,
        tag_ids: idTags,
      };
      setLoadingProductsTags(true);
      modifyTagWithSalePoint(JSON.stringify(jsonBody)).then((data) => {
        getRestrictedProductsOfSalePoint(salePointId).then((data) => {
          setProductInTags(data.products);
          setLoadingProductsTags(false);
        });
      });
    }
  }, [selectedTags]);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------

  const handleEditImageClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (successSnackbar) {
      history.push("/pasillos");
    }
  };

  const handleOnImageChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile != null) {
      setSalePoint({
        ...salePoint,
        [event.target.name]: event.target.files[0],
      });
      newProfilePicture = newFile;
      const url = URL.createObjectURL(newProfilePicture);
      setImgSource(url);
    }
  };

  const handleInputChangeInner = (event) => {
    setSalePoint({ ...salePoint, [event.target.name]: event.target.value });
  };

  const handleImmediateDeliveryChange = (event) => {
    setSalePoint({
      ...salePoint,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (selectedSpt.length === 0) {
      setSuccessSnackbar(false);
      setSnackbarMessage(
        <Alert severity="warning">Debe selecionar o crear un pasillo</Alert>
      );
      setOpenSnackbar(true);
      setSubmitted(false);
    } else {
      //TODO check we have all the parameters
      let formDataBody = new FormData();
      let fk_organization = localStorage.getItem("fk_organization");
      formDataBody.append("fk_organization", fk_organization);

      for (const key in salePoint) {
        if (typeof salePoint[key] == File) {
          formDataBody.append(key, salePoint[key], "profilePicture.jpg");
        } else if (key === "immediate_delivery") {
          formDataBody.append(key, salePoint[key] ? 1 : 0);
        } else if (key === "sales_goal" && typeof salePoint[key] === "string") {
          let sellPrice = salePoint[key];
          let finalPrice = sellPrice.replaceAll(",", "");
          let finalFinal = finalPrice.replace(".", "");
          formDataBody.append(key, finalPrice);
        } else if (salePoint[key] !== undefined) {
          formDataBody.append(key, salePoint[key]);
        }
      }
      //Check the salePoint type
      let spt_array = selectedSpt.map((spt) => {
        if (spt.value !== 0) {
          return spt.value;
        } else {
          return spt.label;
        }
      });
      formDataBody.append("fk_sale_point_types", spt_array.join(","));

      if (latLng.lat !== undefined && latLng.lng !== undefined) {
        formDataBody.append("latitude", latLng.lat);
        formDataBody.append("longitude", latLng.lng);
      }

      if (urlLocation.pathname.includes("edit")) {
        putFranchise(formDataBody).then((data) => {
          if (data.error === undefined) {
            setSuccessSnackbar(true);
            setSnackbarMessage(
              <Alert severity="success">
                Se ha actualizado el punto de venta con éxito
              </Alert>
            );
            setOpenSnackbar(true);
          } else {
            setSuccessSnackbar(false);
            setSnackbarMessage(
              <Alert severity="error">Error: {data.error}</Alert>
            );
            setOpenSnackbar(true);
          }
          setSubmitted(false);
        });
      } else {
        postFranchise(formDataBody).then((data) => {
          if (data.error === undefined) {
            setSuccessSnackbar(true);
            setSnackbarMessage(
              <Alert severity="success">
                Se ha creado el punto de venta con éxito
              </Alert>
            );
            setOpenSnackbar(true);
          } else {
            setSuccessSnackbar(false);
            setSnackbarMessage(
              <Alert severity="error">Error: {data.error}</Alert>
            );
            setOpenSnackbar(true);
          }
          setSubmitted(false);
        });
      }
    }
  };

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  const handleLocationChanged = (lat, lng) => {
    setLatLng({ lat: lat, lng: lng });
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {snackbarMessage}
      </Snackbar>
      <div className="row">
        <div className="col-8">
          <main className="section-body">
            <h1 className="section-title">
              <FormattedMessage id={formTitle} />
            </h1>
            {!loading && (
              <form
                onSubmit={handleSubmit}
                className="row g-3"
                onKeyDown={(e) => checkKeyDown(e)}
              >
                <div className="d-flex justify-content-center mb-4">
                  <img
                    className="profile-picture"
                    src={imgSource}
                    alt="Avatar"
                  />
                  <div className="icon-container d-flex justify-content-center align-items-center">
                    <img
                      className="icon"
                      src={EditIcon}
                      alt="edit_icon"
                      onClick={handleEditImageClick}
                    />
                  </div>
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleOnImageChange}
                  name="file"
                  hidden="hidden"
                  accept=".jpg, .jpeg, .png, .gif"
                />
                <div className="col-6 mb-3">
                  <TextField
                    required
                    className="form-control"
                    id="standard-basic"
                    label={<FormattedMessage id="nameLbl" />}
                    value={salePoint.name ? salePoint.name : ""}
                    name="name"
                    onChange={handleInputChangeInner}
                  />
                </div>
                <div className="mb-3 ">
                  <TextField
                    required
                    className="form-control description-container"
                    id="standard-basic"
                    label={<FormattedMessage id="descriptionLbl" />}
                    value={salePoint.description ? salePoint.description : ""}
                    name="description"
                    onChange={handleInputChangeInner}
                    rows={4}
                    multiline
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="timezoneInput">
                        <FormattedMessage id="salePointTypelbl" />
                      </label>
                      {salePointTypeList && (
                        <Autocomplete
                          options={salePointTypeList}
                          selectedOptions={selectedSpt}
                          setSelected={setSelectedSpt}
                        />
                      )}
                      {/* {salePointTypesInSalePoint && } */}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <span></span>
                      <TextField
                        className="form-control"
                        id="standard-basic"
                        label={<FormattedMessage id="prefixLbl" />}
                        value={salePoint.preffix ? salePoint.preffix : ""}
                        name="preffix"
                        onChange={handleInputChangeInner}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="timezoneInput">
                        <FormattedMessage id="countryLbl" />
                      </label>
                      <Select
                        required
                        value={
                          salePoint.country !== undefined
                            ? salePoint.country
                            : ""
                        }
                        name="country"
                        onChange={handleInputChangeInner}
                        className="worker-form-select"
                        id="timezoneInput"
                      >
                        <MenuItem value={"Colombia"} key={1}>
                          Colombia
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="timezoneInput">
                        <FormattedMessage id="cityLbl" />
                      </label>
                      <Select
                        required
                        value={
                          salePoint.city !== undefined ? salePoint.city : ""
                        }
                        name="city"
                        onChange={handleInputChangeInner}
                        className="worker-form-select"
                        id="timezoneInput"
                      >
                        {colCities.map((city) => (
                          <MenuItem value={city} key={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              salePoint.immediate_delivery ? true : false
                            }
                            onChange={handleImmediateDeliveryChange}
                            name="immediate_delivery"
                            color="primary"
                          />
                        }
                        label={<FormattedMessage id="immediateDeliveryLbl" />}
                      />
                    </div>
                  </div>
                  {/*<div className="col-6">*/}
                  {/*    <div className="mb-3">*/}
                  {/*        {urlLocation.pathname.includes("edit") && <CurrencyTextField*/}
                  {/*            label={<FormattedMessage id="salesGoal"/>}*/}
                  {/*            variant="standard"*/}
                  {/*            className="form-control"*/}
                  {/*            value={sellPrice}*/}
                  {/*            name="sales_goal"*/}
                  {/*            currencySymbol="$"*/}
                  {/*            outputFormat="number"*/}
                  {/*            onChange={handleInputChangeInner}*/}

                  {/*        />}*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                </div>
                {/*<div className="row">*/}
                {/*  <div className="col-6">*/}
                {/*    <label className="form-label">*/}
                {/*      <FormattedMessage id="locationLbl" />*/}
                {/*    </label>*/}
                {/*    {map}*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row justify-content-end">
                  <div className="col-12 d-flex justify-content-end">
                    <Link className="" to={`/pasillos`}>
                      <button className="btn btn-outline-primary m-2">
                        <FormattedMessage id="cancelLbl" />
                      </button>
                    </Link>
                    <button className="btn btn-primary m-2" type="submit">
                      {!submitted && <FormattedMessage id={submitLabel} />}
                      {submitted && (
                        <div className="d-flex justify-content-center spinner-container">
                          <div
                            className="spinner-border spinner-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
            {loading && (
              <div className="d-flex justify-content-center spinner-container">
                <div className="spinner-border spinner-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </main>
        </div>
        <div className="col-4">
          <div className="row h-50 ">
            <div className="col section-body">
              <div>
                <div className="col-12">
                  <span className="section-title">Productos restringidos</span>
                  <TagInput
                    tags={tags}
                    setTags={setTags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                  />
                </div>
              </div>
              {!loadingProductsTags ? (
                <div>
                  <label className="form-label" htmlFor="timezoneInput">
                    Vista previa de productos  (
                    {productsInTags.length})
                  </label>
                  <div className="products-restricted">
                    {productsInTags.map((product) => (
                      <RowProduct product={product} />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="">
                  <div className="d-flex justify-content-center align-items-center spinner-container products-p">
                    <div
                      className="spinner-border spinner-primary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row h-50">
            <div className="col section-body">
              {!loading ? (
                <>
                  <RelatedCoursesComponent
                    courses={courses}
                    setCourses={setCourses}
                    organizationCourses={organizationCourses}
                    setSelectedCourses={setSelectedCourses}
                    selectedCourses={selectedCourses}
                    salePointId={salePointId}
                    organizationId={organizationId}
                    setOrganizationCourses={setOrganizationCourses}
                  />
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center spinner-container">
                    <div
                      className="spinner-border spinner-primary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Autocomplete = ({ options, selectedOptions, setSelected }) => {
  const handleChange = (newValue, { action }) => {
    setSelected(newValue);
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: 0, label: inputValue };
    setSelected([...selectedOptions, newOption]);
  };

  return (
    <CreatableSelect
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      required
      isMulti
      isClearable
      isSearchable
      placeholder="Selecciona o crear un tipo de negocio"
    />
  );
};

