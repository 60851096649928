import SalePointTypeCard from "../../components/supervisor/SalePointTypeCard/SalePointTypeCard";
import { useEffect, useState } from "react";
import { getAllSalePointType } from "../../services/utilsSalePointType";
import { LinearProgress } from "@material-ui/core";
import SignalWifiConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiConnectedNoInternet4";
const SalePointTypeList = () => {
  const [salePointTypeList, setSetPointTypeList] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const fetchData = async () => {
    let fkOrganization = localStorage.getItem("fk_organization");

    getAllSalePointType(fkOrganization)
      .then((data) => {
        let transformedData = data.sale_points_type.map((spt) => {
          return { name: spt.name, image: spt.principal_image, id: spt.id };
        });

        setSetPointTypeList(transformedData);
        setLoadingData(false);
      })
      .catch((error) => {
        setLoadingData(false);
        setLoadingError(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      {loadingData && <LinearProgress />}
      <div className="cards-container sale-point-type">
        {!loadingData && !loadingError &&
          salePointTypeList &&
          salePointTypeList.map((salePointType) => (
            <SalePointTypeCard
              salePointType={salePointType}
              key={salePointType.id}
            />
          ))}
        {!loadingData && loadingError && (
            <div className="center-container-wifi">
            <div className="wifi">
              <SignalWifiConnectedNoInternet4Icon />
            </div>
            <p>
              Lo sentimos, parece que no tienes conexión a Internet en este
              momento.
            </p>
          </div>
        )

        }
      </div>
    </div>
  );
};

export default SalePointTypeList;
