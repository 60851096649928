import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  TextField,
  FormControlLabel,
  Switch,
  Snackbar,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";

import { Alert } from "@mui/material";
import { getOrganizationById, postOrganization, putOrganization } from "../../../services/utilsOrganization";

const OrganizationForm = () => {
  const [organization, setOrganization] = useState({});

  const [formTitle, setFormTitle] = useState("createNewOrganization");

  const [submitLabel, setSubmitLabel] = useState("createOrganizationLbl");

  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const urlLocation = useLocation();

  const [submitted, setSubmitted] = useState(false);

  let colCities = [
    "Bogotá",
    "Medellín",
    "Cali",
    "Barranquilla",
    "Cartagena",
    "Cúcuta",
    "Bucaramanga",
    "Pereira",
    "Santa Marta",
    "Ibagué",
    "Valledupar",
    "Manizales",
    "Pasto",
    "Montería",
    "Neiva",
    "Tunja",
    "Riohacha",
    "Sincelejo",
    "Armenia",
    "Popayán",
    "Villavicencio",
    "Quibdó",
    "Florencia",
    "Yopal",
    "Mocoa",
    "Mitú",
    "San Andrés",
    "Leticia",
    "Puerto Carreño"
  ]

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------

  const fetchData = async () => {
    if (urlLocation.pathname.includes("edit")) {
      let organizationId = localStorage.getItem("fk_organization");
      setFormTitle("editOrganization");
      setSubmitLabel("saveOrganizationLbl");
      getOrganizationById(organizationId).then((data)=>{
        setOrganization(data.organization);
      })
    }
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------
  const handleInputChangeInner = (event) => {
    setOrganization({
      ...organization,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    let formDataBody = new FormData();
    for (const key in organization) {
      formDataBody.append(key, organization[key]);
    }

    if (urlLocation.pathname.includes("edit")) {
      let organizationId = localStorage.getItem('fk_organization');
      formDataBody.append('id', organizationId);
      setFormTitle("editOrganization");
      setSubmitLabel("saveCompanyLbl");

      putOrganization(formDataBody).then((data)=>{
        if(data.error === undefined){
          setSnackbarMessage(<Alert severity="success">Se ha actualizado la organización con éxito</Alert>);
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      })

    } else {
      postOrganization(formDataBody).then((data) => {
        if (data.error === undefined) {
          setSnackbarMessage(
            <Alert severity="success" onClose={handleClose}>
              Se ha creado la organización con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(
            <Alert severity="error" onClose={handleClose}>
              Error: {data.error}
            </Alert>
          );
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    }
  };

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  return (
    <main className="section-body">
      <h1 className="section-title">
        <FormattedMessage id={formTitle} />
      </h1>
      <form
        onSubmit={handleSubmit}
        className="row g-3"
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="col-6 mb-3">
          <TextField
            required
            className="form-control"
            id="standard-basic"
            label={<FormattedMessage id="nameLbl" />}
            value={organization.name ? organization.name : ""}
            name="name"
            onChange={handleInputChangeInner}
          />
        </div>
        <div className="mb-3 ">
          <TextField
            required
            className="form-control description-container"
            id="standard-basic"
            label={<FormattedMessage id="descriptionLbl" />}
            value={organization.description ? organization.description : ""}
            name="description"
            onChange={handleInputChangeInner}
            rows={4}
            multiline
          />
        </div>
        <div className="row">
          <div className="col-6">
            <div className="mb-3">
            <label className="form-label" htmlFor="timezoneInput">
                <FormattedMessage id="countryLbl" />
              </label>
              <Select
                required
                value={
                  organization.country !== undefined ? organization.country : ""
                }
                name="country"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="timezoneInput"
              >
                <MenuItem value={"Colombia"}>Colombia</MenuItem>
              </Select>
              
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
            <label className="form-label" htmlFor="timezoneInput">
                <FormattedMessage id="cityLbl" />
              </label>
              <Select
                required
                value={organization.city !== undefined ? organization.city : ""}
                name="city"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="timezoneInput"
              >
                {colCities.map( city => (<MenuItem value={city}>{city}</MenuItem>))}
              </Select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="div mb-3">
              <label className="form-label" htmlFor="timezoneInput">
                <FormattedMessage id="timezoneLbl" />
              </label>
              <Select
                required
                value={
                  organization.timezone !== undefined
                    ? organization.timezone
                    : ""
                }
                name="timezone"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="timezoneInput"
              >
                <MenuItem value={"America/Bogota"}>America/Bogota</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-12 d-flex justify-content-end">
            <Link className="" to={`/organizaciones`}>
              <button className="btn btn-outline-primary m-2">
                <FormattedMessage id="cancelLbl" />
              </button>
            </Link>
            <button className="btn btn-primary m-2" type="submit">
              {!submitted && <FormattedMessage id={submitLabel} />}
              {submitted && (
                <div className="d-flex justify-content-center spinner-container">
                  <div className="spinner-border spinner-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {snackbarMessage}
      </Snackbar>
    </main>
  );
};

export default OrganizationForm;
