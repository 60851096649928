import { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  getBestSellingProduct,
  getSalePointTypeTransaction,
  getTotalSalesSalePointType,
  getTransactionForSalePointType,
} from "../../services/utilsDashboard";
// import Empanada from "../../assets/img/empanadas-de-pollo-1635.jpeg";
import Student from "../../assets/img/student-img.webp";
// import CocaCola from "../../assets/img/cocacola.webp";
import ProductDefault from "../../assets/market-icons/producto.jpg";
import "./HomeMarket.scss";
import CurrencyFormat from "react-currency-format";
import { Select, MenuItem } from "@material-ui/core";
import { getAllSalePointType } from "../../services/utilsSalePointType";
import SignalWifiConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiConnectedNoInternet4";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

import { ResponsiveBar } from "@nivo/bar";
import SalePointTypeSalesItem from "../../components/homeComponents/SalePointTypeSalesItem";
import { convertUTCtoLocalTimeString } from "../../components/timezonefuncions";

const TransactionsListItem = ({
  transaction,
  setProductsInTransaction,
  activeTransaction,
  setActiveTransaction,
}) => {
  let handleClick = (event) => {
    event.preventDefault();
    setProductsInTransaction(transaction.products);
    setActiveTransaction(transaction.id); 
  };

  const date = new Date(convertUTCtoLocalTimeString(transaction.datetime));
  
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    
  };

  const formatter = new Intl.DateTimeFormat("es-ES", options);
  const formattedDate = formatter.format(date); // "martes, 20 de febrero de 2023 6:36 p.m."
  
  const parts = formattedDate.split(",");
  const formattedDateWithoutYear = `${parts[0]}, ${
    parts[1]
  }, ${parts[2].trim()}`; // "martes, 20 de febrero, 6:36 p.m."
  return (
    <div
      className={
        "row transactions-item-container" +
        (activeTransaction === transaction.id ? " active-container" : "")
      }
      onClick={handleClick}
    >
      <div className="col-2">
        <img
          src={
            transaction.customer.image ? transaction.customer.image : Student
          }
          alt=""
          className="student-icon center-items"
        />
      </div>
      <div className="col">
        <div className="container-info">
          <section className="section-into">
            <p className="text-content">
              Comprador: {transaction.customer.name}
            </p>
            <h6>
              <CurrencyFormat
                value={transaction.transaction_value / 100}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </h6>
            <p className="text-content"> {formattedDateWithoutYear}</p>
          </section>
          <div className="row aside">
            <div className="col">
              {/* <p className="text-content">{transaction.trackable.dependency}</p> */}
            </div>
            <div className="col">
              {/* <p className="text-content"> {formattedDateWithoutYear}</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductTransactionDetail = ({ productInfo }) => {
  console.log("Product info:", productInfo);
  return (
    <div className="row product-item-container">
      <div className="col-3">
        <img
          src={productInfo.image_url ? productInfo.image_url : ProductDefault}
          alt=""
          className="product-icon center-items"
        />
      </div>
      <div className="col">
        <div className="row product-info">
          <p className="text-content">{productInfo.name}</p>
          <p className="text-content">
            {productInfo.product_category
              ? productInfo.product_category.name
              : ""}
          </p>
          <p className="text-content">{`(${productInfo.quantity} UND)`}</p>
        </div>
      </div>
      <div className="col-3 product-price">
        <p className="text-content text-blue-light ">
          {" "}
          <CurrencyFormat
            value={productInfo.sell_price / 100}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </p>
      </div>
    </div>
  );
};

const MyResponsiveBar = ({ data /* see data tab */ }) => {
  console.log("DATA", data);
  return (
    <ResponsiveBar
      data={data}
      keys={["value"]}
      indexBy="day"
      margin={{ top: 15, right: 0, bottom: 110, left: 50 }}
      padding={0.4}
      valueScale={{ type: "linear" }}
      valueFormat=" >-$,"
      colors="#009ED2"
      animate={true}
      enableLabel={false}
      axisTop={null}
      axisRight={null}
      borderRadius={4}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      theme={{
        tooltip: {
          container: {
            background: "black",
            color: "white",
            fontSize: "inherit",
            borderRadius: "2px",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
            padding: "5px 9px",
          },
        },
      }}
    />
  );
};

const HomeMarket = () => {
  const [totalSalesSalePointType, setTotalSalesSalePointType] = useState();

  const [transDataGraph, setTransDataGraph] = useState([]);

  const [transSalePointType, setTransSalePointType] = useState();

  const [bestSellingProduct, setBestSellingProduct] = useState();

  const [spyList, setSptList] = useState();

  const [allTransactionSalePointType, setAllTransactionSalePointType] =
    useState();

  const [productsInTransaction, setProductsInTransaction] = useState();

  const [activeTransaction, setActiveTransaction] = useState();

  const [infoGraphToAnalize, setInfoGraphToAnalize] = useState();

  const [infoToAnalize, setInfoToAnalize] = useState();

  const [loading, setLoading] = useState(true);

  const [loadingTransactions, setLoadingTransactions] = useState(false);

  const [loadingGraphInfo, setLoadingGraphInfo] = useState(false);

  const [loadingError, setLoadingError] = useState(false);

  const [loadingTransactionsError, setLoadingTransactionsError] =
    useState(false);

  const [loadingGraphInfoError, setLoadingGraphInfoError] = useState(false);

  const [noTransactionsMessages, setNoTransactionsMessages] = useState(false);

  const [titleTransactions, setTitleTransactions] = useState();

  const [diabledSelectSpt, setDisabledSelectSpt] = useState(true);

  /**
   * API CALLS
   */

  let fetchData = () => {
    let dates = getLastWeekDates();
    let fkOrganization = localStorage.getItem("fk_organization");

    getTotalSalesSalePointType(fkOrganization, dates.start_date, dates.end_date)
      .then((data) => {
        setTotalSalesSalePointType(data.sale_point_types);
        setTransSalePointType(data.sale_point_types);
        setLoading(false);
      })
      .catch((error) => {
        setLoadingError(true);
        setLoading(false);
      });
    setLoadingGraphInfo(true);
    getAllSalePointType(fkOrganization)
      .then((data) => {
        setSptList(data.sale_points_type);
        setDisabledSelectSpt(false);
        setLoadingGraphInfo(false);
      })
      .catch(() => {
        setLoadingGraphInfo(false);
        setLoadingGraphInfoError(true);
      });
  };

  let getGraphData = (fkSalePointType) => {
    setLoadingGraphInfo(true);
    let dates = getLastWeekDates();
    getTransactionForSalePointType(
      fkSalePointType,
      dates.start_date,
      dates.end_date
    ).then((data) => {
      let transformDatePerDay = (datetime) => {
        let newDate = datetime.split("T")[0].split("-");

        return `${newDate[1]}/${newDate[2]}`;
      };
      let converterTransactions = data.transactions.map((tran) => {
        return {
          day: transformDatePerDay(tran.datetime),
          value: tran.transaction_value,
        };
      });

      const finalDataTransctions = converterTransactions.reduce((acc, curr) => {
        const index = acc.findIndex((item) => item.day === curr.day);
        if (index !== -1) {
          acc[index].value += curr.value / 100;
        } else {
          acc.push({ day: curr.day, value: curr.value / 100 });
        }
        return acc;
      }, []);
      setTransDataGraph(finalDataTransctions);
      setLoadingGraphInfo(false);
    });
  };

  /**
   * Transformar fe TODAY, LAST WEEK, LAST MONTH
   */

  let getTodayDates = () => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0); // set time to 00:00:00.000
    const startOfDayUTC = today.toISOString().replace("T", " ").slice(0, -5); // "2023-02-16 00:00:00"

    const endOfDay = new Date();
    endOfDay.setUTCHours(23, 59, 59, 999); // set time to 23:59:59.999
    const endOfDayUTC = endOfDay.toISOString().replace("T", " ").slice(0, -5); // "2023-02-16 23:59:59"
    return { start_date: startOfDayUTC, end_date: endOfDayUTC };
  };

  let getLastWeekDates = () => {
    const today = new Date();
    today.setUTCHours(23, 59, 59, 999); // set time to 23:59:59.999
    const endOfDayUTC = today.toISOString().replace("T", " ").slice(0, -5); // "2023-02-16 23:59:59"

    const oneWeekAgo = new Date();
    oneWeekAgo.setUTCDate(oneWeekAgo.getUTCDate() - 7); // set date to 7 days ago
    oneWeekAgo.setUTCHours(0, 0, 0, 0); // set time to 00:00:00.000
    const startOfWeekUTC = oneWeekAgo
      .toISOString()
      .replace("T", " ")
      .slice(0, -5); // "2023-02-09 00:00:00"

    return { start_date: startOfWeekUTC, end_date: endOfDayUTC };
  };

  let getLastMonthDates = () => {
    const today = new Date(); // current date and time
    today.setUTCHours(23, 59, 59, 999); // set time to 23:59:59.999
    const endOfDayUTC = today.toISOString().replace("T", " ").slice(0, -5); // "2023-03-01 23:59:59"

    const oneMonthAgo = new Date();
    oneMonthAgo.setUTCDate(1); // set day of month to 1
    if (oneMonthAgo.getUTCMonth() === 0) {
      // if current month is January
      oneMonthAgo.setUTCMonth(11); // set previous month to December
      oneMonthAgo.setUTCFullYear(oneMonthAgo.getUTCFullYear() - 1); // decrement year by 1
    } else {
      oneMonthAgo.setUTCMonth(oneMonthAgo.getUTCMonth() - 1); // set month to 1 month ago
    }

    const prevMonthDays = new Date(
      oneMonthAgo.getUTCFullYear(),
      oneMonthAgo.getUTCMonth() + 1,
      0
    ).getUTCDate();
    if (today.getUTCDate() < prevMonthDays) {
      oneMonthAgo.setUTCDate(today.getUTCDate());
    }
    oneMonthAgo.setUTCHours(0, 0, 0, 0);
    const startOfMonthUTC = oneMonthAgo
      .toISOString()
      .replace("T", " ")
      .slice(0, -5); // "2023-01-19 00:00:00"

    return { start_date: startOfMonthUTC, end_date: endOfDayUTC };
  };

  let handleAnalizeChange = (event) => {
    setInfoToAnalize({
      ...infoToAnalize,
      [event.target.name]: event.target.value,
    });
  };

  let handleGraphAnalize = (event) => {
    setInfoGraphToAnalize({
      ...infoGraphToAnalize,
      [event.target.name]: event.target.value,
    });

    getGraphData(event.target.value);
  };

  let handleSubmitGetTransactions = (event) => {
    event.preventDefault();
    let fkSalePointType = infoToAnalize.salePointType;
    let dates;

    if (infoToAnalize.datesOption === 1) {
      dates = getTodayDates();
      setTitleTransactions("todaySalesTitle");
    } else if (infoToAnalize.datesOption === 2) {
      dates = getLastWeekDates();
      setTitleTransactions("lastWeekSalesTitle");
    } else if (infoToAnalize.datesOption === 3) {
      dates = getLastMonthDates();
      setTitleTransactions("lastMonthSalesTitle");
    }
    setLoadingTransactions(true);
    getSalePointTypeTransaction(
      fkSalePointType,
      dates.start_date,
      dates.end_date
    ).then((data) => {
      console.log("SALEPOINT TYPE TRANSACTIONS", data);
      if (data.transactions.length === 0) {
        setNoTransactionsMessages(true);
      } else {
        setAllTransactionSalePointType(data.transactions);
      }
      setLoadingTransactions(false);
    }).catch((error)=>{
      setLoadingTransactionsError(true);
      setLoadingTransactions(false);
    });

    getBestSellingProduct(
      fkSalePointType,
      dates.start_date,
      dates.end_date
    ).then((data) => {
      console.log("BEST SELLING PRODUCT", data);
      setBestSellingProduct(data.best_selling_product);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="home-wrapper">
      <div className="home-container">
        <div className="row ">
          <h2 className="home-title">
            <FormattedMessage id="resumeSalePointTypeTitle" />
          </h2>
          <div className="col-6 ">
            <div className="section-body  item-spt-sales">
              {loading && (
                <div className="center-container-spinner">
                  <div className="spinner">
                    <CircularProgress />
                  </div>
                </div>
              )}
              {totalSalesSalePointType &&
                !loading &&
                totalSalesSalePointType.map((spt) => {
                  return <SalePointTypeSalesItem salePointType={spt} />;
                })}
              {loadingError && !loading && (
                <div className="center-container-wifi">
                  <div className="wifi">
                    <SignalWifiConnectedNoInternet4Icon />
                  </div>
                  <p>
                    Lo sentimos, parece que no tienes conexión a Internet en
                    este momento.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="section-body full-height">
              <h6 className="dashboard-sub-section-title text-blue">
                <FormattedMessage id="lastDaysSalesTitle" />{" "}
              </h6>
              <form action="" className="information-form">
                <label
                  className="form-label no-margin"
                  htmlFor="franchiseInput"
                >
                  <FormattedMessage id="salePointTypelbl" />
                </label>
                <div className="row">
                  <div className="col">
                    <Select
                      required
                      value={
                        infoGraphToAnalize
                          ? infoGraphToAnalize.salePointType
                            ? infoGraphToAnalize.salePointType
                            : ""
                          : ""
                      }
                      name="salePointType"
                      className="select-item"
                      id="salePointType"
                      onChange={handleGraphAnalize}
                      disabled={diabledSelectSpt || loadingGraphInfo}
                    >
                      {spyList &&
                        spyList.map((spt, i) => (
                          <MenuItem key={i} value={spt.id}>
                            {spt.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                  <div className="col-2">
                    {loadingGraphInfo && (
                      <div className="center-container-spinner">
                        <div className="spinner">
                          <CircularProgress />
                        </div>
                      </div>
                    )}
                    {loadingGraphInfoError && !loadingGraphInfo && (
                      <div className="center-container-spinner">
                        <div className="wifi">
                          <SignalWifiConnectedNoInternet4Icon></SignalWifiConnectedNoInternet4Icon>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <MyResponsiveBar data={transDataGraph} />
            </div>
          </div>
        </div>
        <div className="row">
          <h2 className="home-title">
            <FormattedMessage id="detailsTitle" />
          </h2>
          <div className="row">
            <div className="section-body">
              <form
                action=""
                className="information-form"
                onSubmit={handleSubmitGetTransactions}
              >
                <div className="container-horizonal">
                  <div className="item-container">
                    <label className="form-label" htmlFor="franchiseInput">
                      <FormattedMessage id="salePointTypelbl" />
                    </label>
                    <Select
                      required
                      value={
                        infoToAnalize
                          ? infoToAnalize.salePointType
                            ? infoToAnalize.salePointType
                            : ""
                          : ""
                      }
                      name="salePointType"
                      className="select-item"
                      id="salePointType"
                      onChange={handleAnalizeChange}
                      disabled={diabledSelectSpt}
                    >
                      {spyList &&
                        spyList.map((spt, i) => (
                          <MenuItem key={i} value={spt.id}>
                            {spt.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                  <div className="item-container">
                    <label className="form-label" htmlFor="franchiseInput">
                      <FormattedMessage id="choseeRangeDateLbl" />
                    </label>
                    <Select
                      required
                      value={
                        infoToAnalize
                          ? infoToAnalize.datesOption
                            ? infoToAnalize.datesOption
                            : ""
                          : ""
                      }
                      name="datesOption"
                      className="select-item"
                      id="datesOption"
                      onChange={handleAnalizeChange}
                    >
                      <MenuItem key={1} value={1}>
                        Ventas de hoy
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        Ventas de la última semana
                      </MenuItem>
                      <MenuItem key={3} value={3}>
                        Ventas del último mes
                      </MenuItem>
                    </Select>
                  </div>
                  <div className="item-container center-item">
                    <button className="btn btn-primary m-2 " type="submit">
                      <FormattedMessage id="analyzelbl" />
                    </button>
                  </div>
                </div>
                <div className="margin-top-1rem ">
                  {loadingTransactions && <LinearProgress />}
                </div>
              </form>
            </div>
          </div>
          {!loadingTransactions &&
            allTransactionSalePointType &&
            bestSellingProduct && (
              <div className="row">
                <div className="col-3">
                  <div className="section-body">
                    {(bestSellingProduct || bestSellingProduct !== {}) && (
                      <div className="row best-product-info">
                        <h6 className="dashboard-sub-section-title center-items">
                          <FormattedMessage id="bestProductTitle" />
                        </h6>
                        <div className="margin-top-1rem"></div>
                        {console.log(bestSellingProduct)}
                        <img
                          src={
                            bestSellingProduct.image
                              ? bestSellingProduct.image
                              : ProductDefault
                          }
                          alt=""
                          className=""
                        />
                        <div className="margin-top-1rem"></div>
                        <p className="center-items text-blue">
                          {bestSellingProduct.name}
                        </p>
                        <p className="center-items">
                          {" "}
                          {bestSellingProduct.product_category.name}
                        </p>
                        <div className="margin-top-1rem"></div>
                        <div className="stats-continer">
                          <p className="text-blue">Cantidad vendida:</p>
                          <p className="">{bestSellingProduct.total_count}</p>
                        </div>
                        <div className="stats-continer">
                          <p className="text-blue">Valor:</p>
                          <p>
                            {" "}
                            <CurrencyFormat
                              value={bestSellingProduct.total_sale / 100}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </p>
                        </div>
                        <div className="divider"></div>
                        <p>{bestSellingProduct.description}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="size-1rem"></div>
                <div className="col">
                  <div className="section-body">
                    <h6 className="dashboard-sub-section-title  text-blue">
                      <FormattedMessage id={titleTransactions} />{" "}
                    </h6>
                    {allTransactionSalePointType &&
                      allTransactionSalePointType.map((transaction) => {
                        return (
                          <TransactionsListItem
                            transaction={transaction}
                            setProductsInTransaction={setProductsInTransaction}
                            activeTransaction={activeTransaction}
                            setActiveTransaction={setActiveTransaction}
                          />
                        );
                      })}
                  </div>
                </div>
                <div className="size-1rem"></div>
                <div className="col-3">
                  <div className="section-body">
                    <h6 className="dashboard-sub-section-title text-blue">
                      <FormattedMessage id="saleDetailsTitle" />{" "}
                    </h6>
                    {productsInTransaction &&
                      productsInTransaction.map((product) => {
                        return (
                          <ProductTransactionDetail productInfo={product} />
                        );
                      })}
                  </div>
                </div>
              </div>
            )}

          {noTransactionsMessages && !loadingTransactions && (
            <h2>Aun no hay transacciones</h2>
          )}
          {loadingTransactionsError && !loadingTransactions && (
            <div className="center-container-spinner">
            <div className="wifi">
              <SignalWifiConnectedNoInternet4Icon></SignalWifiConnectedNoInternet4Icon>
            </div>
          </div>
          )}
        </div>
      </div>

      {/* {loading && (
        <div className="center-container">
          <CircularProgress />
        </div>
      )} */}
    </div>
  );
};

export default HomeMarket;
