import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { FormattedMessage } from "react-intl";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FeedbackAlert = ({ idmessage, open, setOpen, vertical="bottom", horizontal="right", type="success" }) => {
//   const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: vertical, horizontal: horizontal}}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          <FormattedMessage id={idmessage} />
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default FeedbackAlert;
