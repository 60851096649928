import React from "react";
import PropTypes from "prop-types";
import FranchiseTypeListPage from "../FranchiseType/FranchiseTypeListPage/FranchiseTypeListPage";
import InvoiceSchemaListPage from "../Invoice Schemas/InvoiceSchemaListPage/InvoiceSchemaListPage";
import CustomTab from "../../components/CustomTab/CustomTab";

import SalePointListPage from "../Franchise/SalePointList/SalePointListPage";
import "../../index.scss";
import { FormattedMessage } from "react-intl";
import SalePointTypeList from "../SalePointType/SalePointTypeList";

export default function MyFranchisesPage() {
  const components = [
    {
      navTitle: "salePointTypeNav",
      component: <SalePointTypeList />,
      url: "pasillos",
    },
    {
      navTitle: "franchiseNav",
      component: <SalePointListPage />,
      url: "puntos-de-venta",
    },
   
    
    // {
    //   navTitle: "invoiceSchemaNav",
    //   component: <InvoiceSchemaListPage />,
    //   url: "resoluciones-facturacion",
    // },
  ];
  return (
    <div>
      <main className="section-body">
        <h1 className="section-title">
          <FormattedMessage id="franchiseTitle" />
        </h1>
        {components && <CustomTab props={components}/>}
      </main>
    </div>
  );
}
