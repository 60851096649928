import {
  URL_DOMAIN,
  URL_DOMAIN_MARKET,
  getJSON,
  URL_P2,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsGet,
  requestOptionsDelete
} from "./globals";

const organizationId = localStorage.getItem('fk_organization');

const getAllSalePointType = async (fkOrganization= organizationId) => {
  try{
    const response = await fetch(
        URL_DOMAIN +
          "sale-point-type/get-sale-point-types-of-organization?id=" +
          fkOrganization,
        requestOptionsGet
      )
    if (!response.ok){
      throw new Error('Error al obtener los datos de ventas');
    }
    return getJSON(response);
  }catch(error){
    console.log('qwerty')
    throw new Error('Error al obtener los datos de ventas: ' + error.message); 
  }
  // return fetch(
  //   URL_DOMAIN +
  //     "sale-point-type/get-sale-point-types-of-organization?id=" +
  //     fkOrganization,
  //   requestOptionsGet
  // ).then(getJSON);
};

const postSalePointType = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(
    URL_DOMAIN + "sale-point-type/create_sale_point_type",
    requestOptionsPost
  ).then(getJSON);
};

const putSalePointType = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(
    URL_DOMAIN + "sale-point-type/edit_sale_point_type",
    requestOptionsPut
  ).then(getJSON);
};

const getSalePointTypeById = async (fkSpt) => {
  return fetch(
    URL_DOMAIN +
      "sale-point-type/get_sale_point_type_detail?id=" +
      fkSpt,
    requestOptionsGet
  ).then(getJSON);
};

const addRelationSalePoint = async (formData) =>{
  requestOptionsPost.body = formData;
  return fetch(
    URL_DOMAIN + "sale-point-type/add_sale_point",
    requestOptionsPost
  ).then(getJSON);
};

const deleteRelationSalePoint = async (id, idsSalePoint) =>{
  return fetch(
    URL_DOMAIN + "sale-point-type/remove_sale_point?id="+id+"&ids_sale_point="+idsSalePoint,
    requestOptionsDelete
  ).then(getJSON);
};

const getRelationsSalePoint = async (id) =>{
  return fetch(URL_DOMAIN + "sale-point-type/get_sale_points?id="+id, requestOptionsGet).then(getJSON);

}
export {
  getAllSalePointType,
  postSalePointType,
  getSalePointTypeById,
  putSalePointType,
  addRelationSalePoint,
  deleteRelationSalePoint,
  getRelationsSalePoint
};
