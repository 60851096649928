import React, { useEffect, useState } from "react";
import "../../../index.scss";
import "./OperatorForm.scss";
import WorkerDefaultImg from "../../../assets/market-icons/avatar-worker.jpg";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "../../../assets/icons/white-create-24px.svg";
import { getSalePointsOfOrganization } from "../../../services/utilsFranchise/utilsFranchise";
import { FormattedMessage } from "react-intl";
import {
  getWorkerById,
  postWorker,
  putWorker,
  getSalePointsOfWorker,
  createSalePointWorker,
  deleteSalePointWorker
} from "../../../services/utilsOperator/utilsOperator";
import { TextField, Select, MenuItem, Snackbar, FormControlLabel, Switch } from "@material-ui/core";
import { Alert } from "@mui/material";
import { useHistory } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { styled, Box } from "@mui/system";
import { Button } from "bootstrap";


export const WorkerForm = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------
  let newProfilePicture;

  const [imgSource, setImgSource] = useState(WorkerDefaultImg);

  const [worker, setWorker] = useState({ fk_supervisor: 23 });

  const [salePoints, setSalePoints] = useState([]);
  const [initialSalePoints, setInitialSalePoints] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [loadingDelete, setLoadingDelete] = useState(false);

  const [passwordEdited, setPasswordEdited] = useState({
    password: true,
    repeat_password: true,
  });

  const [formTitle, setFormTitle] = useState("createNewWorker");

  const [submitLabel, setSubmitLabel] = useState("createWorkerLbl");

  const [submitted, setSubmitted] = useState(false);

  const hiddenFileInput = React.useRef(null);

  const location = useLocation();

  const passwordsMatch = function () {
    return worker.password === worker.repeat_password;
  };

  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [formInDemo, setFormInDemo] = useState("section-body");

  const history = useHistory();

  const [submittedDelete, setSubmittedDelete] = useState(false);

  const style = {
    position: "absolute",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "grid",
    placeItems: "center",
  };



  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const fetchData = async () => {
    console.log("entré a la form");

    if (location.pathname.includes("demo")) {
      setFormInDemo("section-in-demo");
    }
    if (location.pathname.includes("edit")) {
      setLoading(true);
      let workerId = localStorage.getItem("fk_worker");
      let fkOrganization = localStorage.getItem("fk_organization");
      setPasswordEdited({ password: false, repeat_password: false });
      setFormTitle("editWorker");
      setSubmitLabel("saveWorkerLbl");

      getWorkerById(workerId).then((data) => {
        console.log(data, "fetch operator");
        let workerData = data.worker;
        let userData = workerData.user;
        setWorker({
          id: workerData.id,
          username: userData.username,
          first_name: userData.first_name,
          last_name: userData.last_name,
          password: "        ",
          repeat_password: "        ",
          email: userData.email,
          pin: userData.pin,
        });
        workerData.image
          ? setImgSource(workerData.image)
          : setImgSource(imgSource);
        setLoading(false);
      });

      getSalePointsOfWorker(fkOrganization, workerId).then((data) => {

        const salePointsWithInitialDelivery = data.sale_points.map((salePoint) => {
          // Check sale points assigned to worker
          const salePointWorker = salePoint.sale_point_worker;
          const isWorkerAssigned =
            salePointWorker?.fk_worker == workerId
          return {
            ...salePoint,
            switch_flag: isWorkerAssigned || false,
          };

        });

        setSalePoints(salePointsWithInitialDelivery)
        setInitialSalePoints(salePointsWithInitialDelivery)
        setLoading(false);
      });

    }
  };

  useEffect(() => {
    fetchData().then(() => { });
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------
  const handleEditImageClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleOnImageChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile != null) {
      setWorker({ ...worker, [event.target.name]: event.target.files[0] });
      newProfilePicture = newFile;
      const url = URL.createObjectURL(newProfilePicture);
      setImgSource(url);
    }
  };

  const handleFirstTimePasswordEdit = (event) => {
    setWorker({
      ...worker,
      [event.target.name]: event.target.value.trim(),
    });
    setPasswordEdited({ ...passwordEdited, [event.target.name]: true });
  };

  const handleInputChangeInner = (event) => {
    setWorker({ ...worker, [event.target.name]: event.target.value });
  };

  const handleSwitchChange = (id) => {
    setSalePoints((prevSalePoints) =>
      prevSalePoints.map((salePoint) =>
        salePoint.id === id
          ? { ...salePoint, switch_flag: !salePoint.switch_flag }
          : salePoint
      )
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    for (const key in worker) {
      if (typeof worker[key] == File) {
        formDataBody.append(key, worker[key], "profilePicture.jpg");
      } else {
        formDataBody.append(key, worker[key]);
      }
    }
    if (location.pathname.includes("edit")) {
      // Create formdatabody to update Worker
      let workerId = localStorage.getItem("fk_worker");
      formDataBody.append("fk_worker", workerId);
      console.log(formDataBody);
      putWorker(formDataBody).then((data) => {
        console.log(data);
        if (data.error === undefined) {
          setSuccessSnackbar(true);
          setSnackbarMessage(
            <Alert severity="success">
              Se ha actualizado el operario con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false);
          setSnackbarMessage(
            <Alert severity="error">Error: {data.error}</Alert>
          );
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });

      // Create formdatabody to update worker-sale point relationship
      const salePointsToSendRequest = salePoints.filter((salePoint, index) => {
        return salePoint.switch_flag !== initialSalePoints[index].switch_flag;
      });

      salePointsToSendRequest.forEach((salePoint) => {
        // Send an HTTP request for the sale point with a changed switch state
        sendHttpRequestForSalePoint(salePoint);
      });

    } else {
      if (passwordsMatch()) {
        let fkOrganization = localStorage.getItem("fk_organization");
        formDataBody.append("fk_organization", fkOrganization);
        postWorker(formDataBody).then((data) => {
          console.log(data);
          if (data.error === undefined) {
            setSuccessSnackbar(true);
            setSnackbarMessage(
              <Alert severity="success">
                Se ha creado el operario con éxito
              </Alert>
            );
            setOpenSnackbar(true);
          } else {
            setSuccessSnackbar(false);
            setSnackbarMessage(
              <Alert severity="error">Error: {data.error}</Alert>
            );
            setOpenSnackbar(true);
          }
          setSubmitted(false);
        });
      } else {
        setSuccessSnackbar(false);
        setSnackbarMessage(
          <Alert severity="error">Error: Las contraseñas no son iguales</Alert>
        );
        setOpenSnackbar(true);
        setSubmitted(false);
      }
    }
  };
  const handleDelete = (event) => {
    setSubmittedDelete(true);
    setOpenDeleteModal(true);
    setSubmittedDelete(false);

  }

  const handleConfirmDelete = (event) => {
    console.log("confirm");
    setSubmittedDelete(true);
    setLoadingDelete(true);
    sleep(1000).then((data) => {
      console.log(data);

      setSuccessSnackbar(true);
      setOpenDeleteModal(false);
      setSnackbarMessage(
        <Alert severity="success">
          Se ha eliminado el operario con éxito
        </Alert>
      );
      sleep(100).then((data) => {
        setOpenSnackbar(true);
        setSubmittedDelete(false);
      });
    });
    // setSubmittedDelete(false);
    // setOpenDeleteModal(false);
  }

  const handleReturn = (event) => {
    console.log("return");
    setOpenDeleteModal(false);
  }
  const handleDeleteClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDeleteModal(false);
  };

  const sendHttpRequestForSalePoint = (salePoint) => {
    // Create a new FormData object
    const formData = new FormData();
    const formDataDelete = new FormData();

    let workerId = localStorage.getItem("fk_worker");

    // Add the fk_sale_point and fk_worker values to the FormData object
    formData.append('fk_sale_point', salePoint.id);
    formData.append('fk_worker', workerId);

    // Form Data to delete salepoint worker relationship
    formDataDelete.append('fk_sale_point_worker', salePoint.sale_point_worker?.id)

    if (salePoint.switch_flag) {
      createSalePointWorker(formData).then((data) => {
        console.log(data);
        if (data.error === undefined) {
          setSuccessSnackbar(true);
          setSnackbarMessage(
            <Alert severity="success">
              Se ha actualizado el operario con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false);
          setSnackbarMessage(
            <Alert severity="error">Error: {data.error}</Alert>
          );
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    }
    else {
      deleteSalePointWorker(formDataDelete).then((data) => {
        console.log(data);
        if (data.error === undefined) {
          setSuccessSnackbar(true);
          setSnackbarMessage(
            <Alert severity="success">
              Se ha actualizado el operario con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false);
          setSnackbarMessage(
            <Alert severity="error">Error: {data.error}</Alert>
          );
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    }

  };

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (successSnackbar) {
      history.push("/trabajadores");
    }
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <main className={formInDemo}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        {snackbarMessage}
      </Snackbar>
      <h1 className="section-title">
        <FormattedMessage id={formTitle} />
      </h1>
      {!loading && (
        <form
          onSubmit={handleSubmit}
          className="row g-3"
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="d-flex justify-content-center mb-4">
            <img className="profile-picture" src={imgSource} alt="Avatar" />
            <div className="icon-container d-flex justify-content-center align-items-center">
              <img
                className="icon"
                src={EditIcon}
                alt="edit_icon"
                onClick={handleEditImageClick}
              />
            </div>
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleOnImageChange}
            name="file"
            hidden="hidden"
            accept=".jpg, .jpeg, .png, .gif"
          />
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="userLbl" />}
                  value={worker.username ? worker.username : ""}
                  name="username"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="emailLbl" />}
                  value={worker.email ? worker.email : ""}
                  name="email"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="firstNameLbl" />}
                  value={worker.first_name ? worker.first_name : ""}
                  name="first_name"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="lastNameLbl" />}
                  value={worker.last_name ? worker.last_name : ""}
                  name="last_name"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>

            {location.pathname.includes("edit") && (
              <div className="col-6">
                <div className="mb-4">
                  <div>
                    <p>Puntos de Venta:</p>
                    <ul>
                      {salePoints.map((salePoint) => (
                        <li key={salePoint.id}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={salePoint.switch_flag ? true : false}
                                onChange={() => handleSwitchChange(salePoint.id)}
                                name="sale_point_switch"
                                color="primary"
                              />
                            }
                            label={salePoint.name}
                          />


                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>

          {!location.pathname.includes("edit") && (
            <div className="row">
              <div className="col-6">
                <div className="mb-4">
                  <TextField
                    required
                    type="password"
                    className="form-control"
                    id="standard-basic"
                    label={<FormattedMessage id="passwordLbl" />}
                    value={worker.password ? worker.password : ""}
                    name="password"
                    onChange={
                      passwordEdited.password
                        ? handleInputChangeInner
                        : handleFirstTimePasswordEdit
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-4">
                  <TextField
                    required
                    type="password"
                    className="form-control"
                    id="standard-basic"
                    label={<FormattedMessage id="repeatPasswordLbl" />}
                    value={
                      worker.repeat_password ? worker.repeat_password : ""
                    }
                    name="repeat_password"
                    onChange={
                      passwordEdited.repeat_password
                        ? handleInputChangeInner
                        : handleFirstTimePasswordEdit
                    }
                  />
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                {/* <TextField
                  type="number"
                  className="form-control"
                  id="standard-basic"
                  label="PIN"
                  value={worker.pin ? worker.pin : ""}
                  name="pin"
                  onChange={handleInputChangeInner}
                /> */}
              </div>
            </div>
            {location.pathname.includes("demo") && (
              <div className="col-6 mb-4">
                <p className="mesagge-attention">
                  ¡ATENCIÓN! Guarda el usuario y la contraseña en lugar seguro.
                  Lo necesitarás para probar el perfil de trabajador en la
                  aplicación "Kurúah" para dispositivos Android.
                </p>
              </div>
            )}
          </div>
          <div className="row justify-content-end">
            <div className="col-6">
              {/* <button className="btn btn-outline-danger m-2"
                type="button"
                disabled={submittedDelete}
                onClick={handleDelete}>
                <FormattedMessage id="deleteWorkerLbl" />

              </button> */}
            </div>
            <div className="col-6 d-flex justify-content-end">
              {!location.pathname.includes("demo") && (
                <Link className="" to={`/trabajadores`}>
                  <button className="btn btn-outline-primary m-2">
                    <FormattedMessage id="cancelLbl" />
                  </button>
                </Link>
              )}

              {/* <Link className="" to={`/trabajadores`}> */}
              <button
                className="btn btn-primary m-2"
                type="submit"
                disabled={submitted}
              >
                {!submitted && <FormattedMessage id={submitLabel} />}
                {submitted && (
                  <div className="d-flex justify-content-center spinner-container">
                    <div
                      className="spinner-border spinner-primary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
              {/* </Link> */}
            </div>
          </div>
        </form>
      )}
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <Modal
        open={openDeleteModal}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-box">
            {!loadingDelete ? <><h2 className="modal-title">
              <FormattedMessage id="deletedWorkertLbl" />
            </h2>
              <div className="text-center modal-options">
                <button className="btn btn-custom m-2" onClick={handleReturn}>
                  <FormattedMessage id="returnLbl" />
                </button>
                <button className="btn btn-outline-danger m-2" onClick={handleConfirmDelete}>
                  <FormattedMessage id="checkLbl" />
                </button>
              </div></> : <div className="d-flex justify-content-center spinner-container">
              <div className="spinner-border spinner-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>}
          </div>
        </Box>
      </Modal>
    </main>
  );
};

export default WorkerForm;
