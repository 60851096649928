import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import "./navBarProfile.scss";
import Context from "../../../context/UserContext";
import { useMediaQuery } from "react-responsive";
// import ProfileImg from "../../../assets/icons/ICONS-LOGO.jpeg";
import ProfileImg from "../../../assets/market-icons/img-supervisor.jpg";
import MenuIcon from "@mui/icons-material/Menu";

export const NavBarProfile = () => {
  const { supervisorProfile, supervisorImage } = useContext(Context);
  const { showNavbar, setShowNavbar } = useContext(Context);
  const [supervisorName, setSupervisorName] = useState("Market");
  const [supervisorImg, setSupervisorImg] = useState(ProfileImg);
  const [isSearchBarVisible, setSearchBarVisible] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleMenuIconClick = async () => {
    await setShowNavbar(!showNavbar);
    console.log(showNavbar)
  };

  useEffect(() =>{
    let fullName = supervisorProfile["profile"]['user']["first_name"] +' ' +supervisorProfile["profile"]['user']["last_name"]
    setSupervisorName(fullName);
    setSupervisorImg(supervisorImage);
  }, []);

  return (
    <nav className="navbar" id="navBarPorfile">
      <div className="container-fluid" id="nav-noflex">
        <span className="navbar-brand mb-0 h1">
          {isMobile?<div className="d-flex align-items-center justify-content-between">
            {" "}
            {/* Use justify-content-between to push content to opposite ends */}
             <div
              className="d-flex align-items-center"
              onClick={handleMenuIconClick}
            >
              {" "}
              {/* This div contains the MenuIcon */}
              <MenuIcon></MenuIcon>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              {/* This div contains the <p> and <img> */}
              <p className="text-end profile-name">
                <Link to={`/perfil/${supervisorName}`}>{supervisorName}</Link>
              </p>
              <Link to={`/perfil/${supervisorName}`}>
                <img
                  src={supervisorImg}
                  alt="user_img"
                  className="profile-img"
                />
              </Link>
            </div>
          </div>:
          <div className="d-flex flex-row-reverse">
          
          <p className="text-end profile-name"><Link to={`/perfil/${supervisorName}`}>{supervisorName}</Link></p>
          <Link to={`/perfil/${supervisorName}`}>
              <img
                src={supervisorImg}
                alt="user_img"
                className="profile-img"
              />
          </Link>

          </div>
          }
        </span>
      </div>
    </nav>
  );
};
