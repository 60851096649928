import React from "react";
import { Link } from "react-router-dom";
import "./ProductCard.scss";
import ProductDefaultImg from "../../../assets/market-icons/producto.jpg";
import CurrencyFormat from "react-currency-format";
import EditIcon from "../../../assets/icons/edit-icon-24px.svg";
import SeeIcon from "../../../assets/icons/baseline-info-24px.svg";

const ProductCard = (product) => {
  let producto = product["product"];
  
  function productName() {
    let name = producto.name;
    if(name.length > 30){
      name = name.substr(0,30)+ "...";
    }
    if((name).indexOf("%")> -1){
       return (name).replace("%","-");
    }
    else if((name).indexOf("/")> -1){
      return (name).replace("/","-");
    }
    else {
      return name;
    }
  }

  function productImg(){  
    if((producto.image === undefined || producto.image === "null" || producto.image === "N/A")){
      return ProductDefaultImg;
    }
    else{
      return producto.image
    }
    
  }
  
  let originalPrice = (producto.sell_price)/100;


  let goProduct = () => {
    localStorage.setItem("fk_product",producto.id);
  };
  return (
    <div className="card product">
      <img
        src={productImg()}
        className="card-img-top round product"
        alt={"img " + producto.name}
      />
      <div className="card-body">
        <div className="row">
          <h5 className="card-title product">{productName()}</h5>
        </div>
        <div className="row info-card-down">
          <div className="col">
            <p className="card-text product-price">
              <CurrencyFormat
                value={originalPrice}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </p>
          </div>
          <div className="col-5">
            <div className="icon-row">
              {/**<Link
                className="product-card-icon"
                to={`/productos/${productName()}/edit`}
                onClick={goProduct}
              >
                <img src={SeeIcon} alt="see_icon" />
              </Link>*/}
              <Link
                className="product-card-icon"
                to={`/productos/${productName()}/edit`}
                onClick={goProduct}
              > 
                <img src={EditIcon} alt="edit_icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
 