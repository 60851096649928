import "./ResumeProductInventoryDetail.scss";
import React from "react";

const ResumeProductInvetoryDetail = (props) => {
  const productInfo = props.productInfo;
  const product = props.product;
  return (
    <div className="r-p-i-d-row">
      <div className="r-p-i-d-first-column">
        CANTIDAD ACTUAL
      </div>
      <div className="r-p-i-d-second-column"><h6>{productInfo.quantity} UND </h6></div>
    </div>
  );
};

export default ResumeProductInvetoryDetail;