import {
    URL_HELP,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut,
  getJSON,
  URL_DOMAIN_SUPERADMIN,
} from "./globals";

const createEInvoiceForCustomer = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "invoice/electronic-invoice/electronic-invoice-consumer",
    requestOptionsPost
  ).then(getJSON);
};

const createMassiveEInvoice = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "invoice/electronic-invoice/electronic-invoice-organization,",
    requestOptionsPost
  ).then(getJSON);
};

const eInvoiceSummaryAllOrganization = async (fkOrganization) => {
  return fetch(
    URL_HELP +
      "/invoice/electronic-invoice/last-invoice-summary?fk_organization=" +
      fkOrganization,
    requestOptionsGet
  ).then(getJSON);
};

export {
  createEInvoiceForCustomer,
  createMassiveEInvoice,
  eInvoiceSummaryAllOrganization,
};
