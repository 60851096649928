import {
  URL_DOMAIN,
  URL_AUTH,
  URL_DOMAIN_MARKET,
  URL_DOMAIN_SUPERADMIN,
  getJSON,
  URL_P2,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsDelete,
  requestOptionsPut,
} from "../globals";


const URL_SUPERVISOR = URL_DOMAIN + URL_P2[5];

/**
 * Franchises
 * @returns
 */

const getProfile = async () => {
  return fetch(URL_AUTH + "users/profile", requestOptionsGet).then(getJSON);
};

const verifyTemporalAccessToken= async(token)=>{
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
    return fetch(`${URL_AUTH}/verify-temporal-access-token?temporal_access_token=${token}`, requestOptions).then(getJSON);
  
}

const changePasswordWithTemporalAccessToken = async (formData) => {
  var requestOptions = {
    method: "DELETE",
    body: formData,
    redirect: "follow",
  };
  return fetch(`${URL_AUTH}/change-password-with-temporal-access-token`, requestOptions).then(getJSON)
}

const changeProfilePicture = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(`${URL_DOMAIN_SUPERADMIN}supervisor/change-supervisor-image`, requestOptionsPut).then(getJSON);
}

const changePassword = async (formData) => {
  let mobileUrl = URL_AUTH.replace("/web", "") 
  requestOptionsPost.body = formData;
  return fetch(`${mobileUrl}/change-password`, requestOptionsPost).then(getJSON)
}

const logInService = async (formData) => {
  let requestOptions = {
    method: 'POST',
    body: formData,
    redirect: 'follow'
  };
  return fetch(URL_DOMAIN + "users/login", requestOptions).then(getJSON);

  // return fetch(URL_AUTH + "/login", requestOptions).then(getJSON);
};

const logOut = async () => {
  return fetch(URL_DOMAIN + "users/logout", requestOptionsDelete).then(getJSON);
};

export { getProfile, logInService, logOut, verifyTemporalAccessToken, changePasswordWithTemporalAccessToken, changePassword, changeProfilePicture};
