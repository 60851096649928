import { useEffect, useState } from "react";
import DefaultImg from "../../../assets/market-icons/avatar-supervisor.jpg";
import { convertUTCtoLocalTimeStringMovements } from "../../timezonefuncions";

const formatMoney = (number) => {
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};

const WalletTransaction = ({ movement }) => {
  const [openDetail, setOpenDetail] = useState(false);

  const [openModalCancelSale, setOpenModalCancelSale] = useState(false);

  const [isPreSale, setIsPresale] = useState(false);

  const statusToString = (status) => {
    switch (status) {
      case 0:
        return "Programada";
      case 1:
        return "En progreso";
      case 2:
        return "Listo para entrega";
      case 3:
        return "Entregado";
      case 4:
        return "Cancelado";
      case 5:
        return "Pendiente por entregar (tardia)";
      default:
        return "";
    }
  };

  const handleOpenDetailClick = () => {
    setOpenDetail((prevState) => !prevState);
  };

  const handleOpenModalCS = () => {
    setOpenModalCancelSale((prevState) => !prevState);
  };

  const onCancelSale = () => {
    // console.log("CANCELAR VENTA");
  };
  

  useEffect(() => {
    if (movement.sale_information.sale_status === 0) {
      setIsPresale(true);
    }
  }, [movement]);

  return (
    <>
      <div className="row-wallet-information" onClick={handleOpenDetailClick}>
        <div className="row-wallet-information-rechage">
          <li className="time-text">
            {convertUTCtoLocalTimeStringMovements(movement.datetime)}
          </li>
          <div className="row-info-transaction">
            <div className="row-wallet-information-image">
              <img
                src={movement.customer.image || DefaultImg}
                alt=""
                className="image-wallet-balance"
                onError={(e) => {
                  e.target.src = DefaultImg;
                }}
              />
            </div>
            <div className="row-wallet-information-text">
              <li className="movement-title">{movement.customer.name}</li>
              <div>
                <li className="movement-sub-title">
                  {isPreSale ? (
                    <>
                      Programada para:{" "}
                      {convertUTCtoLocalTimeStringMovements(
                        movement.datetime.split(" ")[0]
                      )}
                    </>
                  ) : (
                    <>
                      {statusToString(movement.sale_information.sale_status)}:{" "}
                      {movement.organization.sale_point_type?.sale_point?.name}
                    </>
                  )}
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="row-wallet-information-amount transaction">
          <li>-{formatMoney(movement.transaction_value / 100)}</li>
        </div>
      </div>
      {openDetail && (
        <div className={`info-container sale`}>
          {/* Products */}
          {!isPreSale && (
            <div className="row">
              <div className="col-4">
                <div className="col detail-container">
                  <p className={`info-detail-subtitle sale`}>Tipo de negocio</p>
                  <p>{movement.organization.sale_point_type.name}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="col detail-container">
                  <p className={`info-detail-subtitle sale`}>Punto de venta</p>
                  <p>{movement.organization.sale_point_type.sale_point.name}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="col detail-container">
                  <p className={`info-detail-subtitle sale`}>Trabajador</p>
                  <p>
                    {
                      movement.organization.sale_point_type.sale_point.worker
                        .full_name
                    }
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="row-product-transaction">
              <div className="table-responsive">
                <table table className="table">
                  <thead>
                    <tr>
                      <th className="col-2 text-blue"></th>
                      <th className="col text-blue">Cant</th>
                      <th className="col-3 text-blue">Nombre</th>
                      <th className="col text-blue">Precio</th>
                      <th className="col text-blue">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {movement.products.map((product) => (
                      <ProductRow product={product} />
                    ))}
                    <tr>
                      <td className="col-2"></td>
                      <td className="col product-row-item"></td>
                      <td className="col product-row-item"></td>
                      <td className="col product-row-item">Total</td>
                      <td className="col product-row-item">
                        {formatMoney(movement.transaction_value / 100)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* {movement.sale_information.sale_status === 3 && (
                  <>
                    <div className="button-container">
                      <button className="btn btn-primary button-cancel m-2 sale" onClick={handleOpenModalCS}>
                        Cancelar
                      </button>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      )}
      {openModalCancelSale && (
        <ModalCS
          open={openModalCancelSale}
          onClose={handleOpenModalCS}
          onCancelSale={onCancelSale}
        />
      )}
    </>
  );
};

export default WalletTransaction;

const ProductRow = ({ product }) => {
  return (
    <tr>
      <td className="col-2">
        <img
          src={product.image_url || DefaultImg}
          alt=""
          className="image-wallet-balance product-img product-row-item"
          onError={(e) => {
            e.target.src = DefaultImg;
          }}
        />
      </td>
      <td className="col product-row-item">{product.quantity}</td>
      <td className="col-4 product-row-item">{product.name}</td>
      <td className="col product-row-item">
        {formatMoney(product.sell_price / 100)}
      </td>
      <td className="col product-row-item">
        {formatMoney((product.sell_price * product.quantity) / 100)}
      </td>
    </tr>
  );
};
// TODO modificar el back para que permita hacer cancelaciones de venta desde el supervisor
const ModalCS = ({ open, onClose, onCancelSale }) => {
  if (!open) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div className="modal-overlay" onClick={handleOverlayClick}></div>
      <div className="modal-content-container">
        <div className="modal-content">
          <h2>Cancelación de venta</h2>
          <p>Seguro que deseas cancelar la venta?</p>

          <div className="button-container">
            <button
              className="btn btn-primary button-cancel m-2 sale"
              onClick={onClose}
            >
              Regresar
            </button>
            <button
              className="btn btn-primary button-cancel m-2 cancel"
              onClick={onCancelSale}
            >
              Cancelar venta
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
