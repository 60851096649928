import {
    URL_DOMAIN,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut,
    URL_DOMAIN_MARKET
  } from "../globals"; 
  
  const URL_FINAL = URL_DOMAIN + URL_P2[2];
  
  
  
  const getPaginatedProductCategories = async (page, rowsPerPage, searchQuery=null) => {
    const fk_organization = localStorage.getItem("fk_organization")
    let url = `${URL_DOMAIN}product-category/get-paginated-all-product-category?fk_organization=${fk_organization}&results_per_page=${rowsPerPage}&page_number=${page+1}`
    if (searchQuery !== null){
      url += `&query_string=${searchQuery}`
    }
    try{
      const response = await fetch(
          url,
          requestOptionsGet
        )
        if (!response.ok) {
          throw new Error("Error al obtener los datos de ventas");
        }
    
        return getJSON(response);
    }catch(error){
      throw new Error("Error al obtener los datos de ventas: " );
    }
    // return fetch(
    //   url,
    //   requestOptionsGet
    // ).then(getJSON);
  };

  const getProductCategory = async (id) => {
    return fetch(
      `${URL_DOMAIN}product-category/get-product-category?fk_product_category=${id}`,
      requestOptionsGet
    ).then(getJSON);
  }

  const postProductCategory = async (formData) => {
    requestOptionsPost.body = formData;
    return fetch(URL_DOMAIN + "product-category/create-product-category", requestOptionsPost).then(
      getJSON
    );
  };
  
  const putProductCategory = async (formData) => {
    requestOptionsPut.body = formData;
    return fetch(URL_DOMAIN + "product-category/edit-product-category", requestOptionsPut).then(
      getJSON
    );
  };
  
  const getProductCategoriesofOrganization = async (fkOrganization)=>{
    return fetch(URL_DOMAIN_MARKET + "/product-category/get-all-product-category?fk_organization="+fkOrganization, requestOptionsGet).then(
      getJSON
    );
  }  
  export {getPaginatedProductCategories, getProductCategory, postProductCategory, putProductCategory, getProductCategoriesofOrganization}