import {
  URL_DOMAIN_MARKET,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut,
  getJSON,
  URL_DOMAIN,
  URL_DOMAIN_SUPERADMIN,
} from "./globals";

const getTagsOfOrganization = async (fkOrganization) => {
  return fetch(
    URL_DOMAIN +
      "tags/get-tags-of-organization?fk_organization=" +
      fkOrganization,
    requestOptionsGet
  ).then(getJSON);
};

const createTag = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(URL_DOMAIN + "tags/create-tags", requestOptionsPost).then(
    getJSON
  );
};

const editTag = async (jsonBody) => {
  requestOptionsPut.body = jsonBody;
  return fetch(URL_DOMAIN + "tags/edit-tags", requestOptionsPut).then(
    getJSON
  );
};

const associateTagWithProduct = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_DOMAIN + "tags/associate-tag-with-product",
    requestOptionsPost
  ).then(getJSON);
};

const getTagsOfProduct = async (fkProduct) => {
  return fetch(
    URL_DOMAIN + "tags/get-tags-of-product?fk_product=" + fkProduct,
    requestOptionsGet
  ).then(getJSON);
};

const modifyTagWithProduct = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_DOMAIN + "tags/modify-tag-of-product",
    requestOptionsPost
  ).then(getJSON);
};

const getProductsOfTag = async (fkTag) => {
  return fetch(
    URL_DOMAIN + "tags/get-products-of-tag?fk_tag=" + fkTag,
    requestOptionsGet
  ).then(getJSON);
};


const getSalePointsOfTag = async (fkTag) => {
  return fetch(
    URL_DOMAIN + "tags/get-sale-points-of-tag?fk_tag=" + fkTag,
    requestOptionsGet
  ).then(getJSON);
};


const getTagsOfSalePoint = async (fkSalePoint) => {
  return fetch(
    URL_DOMAIN + "tags/get-tag-of-sp?fk_sale_point=" + fkSalePoint,
    requestOptionsGet
  ).then(getJSON);
};

const getRestrictedProductsOfSalePoint = async (fkSalePoint) => {
  return fetch(
    URL_DOMAIN +
      "tags/get-product-restricted-by-tag-in-sale-point?fk_sale_point=" +
      fkSalePoint,
    requestOptionsGet
  ).then(getJSON);
};

const modifyTagWithSalePoint = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_DOMAIN + "tags/modify-tag-of-sale-point",
    requestOptionsPost
  ).then(getJSON);
};

const movePerishableProducts = async (jsonBody)  =>{
  requestOptionsPut.body = jsonBody;
  return fetch(
    URL_DOMAIN + "tags/move-perishable-products",
    requestOptionsPut
  ).then(getJSON);
};

export {
  getTagsOfOrganization,
  createTag,
  editTag,
  associateTagWithProduct,
  getTagsOfProduct,
  modifyTagWithProduct,
  getTagsOfSalePoint,
  getProductsOfTag,
  getRestrictedProductsOfSalePoint,
  modifyTagWithSalePoint,
  movePerishableProducts,
  getSalePointsOfTag
};
