import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
// Constants
import { Role } from "../constants/parameter";
// Context
import UserContext from "../context/UserContext";
import useUser from "../services/usersManage/useUser";

const GuardedRoute = ({ component: Component, role, type, ...rest }) => {
  const { userRole, supervisorProfile, demoProgress, suspensionCompany } =
    useContext(UserContext);
  const { isLogged } = useUser();

  const private_paths = [
    "/home",
    "/puntos-de-venta",
    "/puntos-de-venta/:id",
    "/puntos-de-venta/:id/edit",
    "/puntos-de-venta/create",
    "/trabajadores",
    "/trabajadores/:id",
    "/trabajadores/:id/edit",
    "/trabajadores/create",
    "/productos",
    "/productos/:id",
    "/productos/:id/edit",
    "/productos/create",
    "/promociones",
    "/resoluciones-facturacion/create",
    "/resoluciones-facturacion/edit",
    "/subscribe",
    "/subscribe",
    "/ingredientes/:id/edit",
    "/ingredientes/create",
    "/informes",
    "/credenciales/:id",
    "perfil/:id",
    "/demo",
    "/inventario",
  ];

  const checkRole = (position) => {
    const type = typeof role;
    switch (type) {
      case "number":
        return position != role;
      case "object":
        return !role.includes(position);
      default:
        return true;
    }
  };

  const checkType = (subrole) => {
    const value = typeof type;
    switch (value) {
      case "number":
        return subrole != type;
      case "object":
        return !type.includes(subrole);
      default:
        return true;
    }
  };

  const verifyPath = () => {
    if (private_paths.includes(window.location.pathname) && !isLogged) {
      return <Redirect to="/" />;
    } else if (
      private_paths.includes(window.location.pathname) === false &&
      window.location.pathname !== "/"
    ) {
      return <Redirect to="/" />;
    }
  };

//   const checkDemo = () => {
//     if (isLogged) {
//       if (demoProgress === "1" || demoProgress === "2") {
//         return <Redirect to="/demo" />;
//       }
//     }
//   };

//   const checkSuspension = () => {
//     if (isLogged) {
//       if (
//         suspensionCompany === "1" ||
//         suspensionCompany === "2" ||
//         suspensionCompany === "3"
//       ) {
//         if (suspensionCompany != 0) {
//           return <Redirect to="/suspension" />;
//         }
//       }
//     }
//   };

  const handleRedirection = (props) => {
    verifyPath();

    if (!userRole) {
      return <Redirect to="/" />;
    }

    if (userRole != null && role != null && checkRole(+userRole)) {
      return <Redirect to="/" />;
    }

    if (userRole == Role.SUPERVISOR) {
      if (!supervisorProfile) {
        return <Redirect to="/" />;
      }

      const profile =
        (supervisorProfile.profile.user.supervisor &&
          supervisorProfile.profile.user.supervisor.type) ??
        supervisorProfile.profile.user.supervisor.type;

      if (profile != null && type != null && checkType(profile)) {
        console.log('redirect')
        return <Redirect to="/" />;
      }
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={(props) => handleRedirection(props)} />;
};

export default GuardedRoute;
