import React from "react";
import "./ProductInventoryDetailComponent.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { getAllSalePoint } from "../../../services/utilsSalePoint";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import { Link } from "react-router-dom";

import {
  getProductInfo,
  getProductInventoryDetail,
} from "../../../services/utilsProductInventory";
import OperationsProductInvetoryDetail from "./OperationsProductInvetoryDetail";
import ResumeProductInvetoryDetail from "./ResumeProductInventoryDetail";
import { red100 } from "material-ui/styles/colors";
import { CircularProgress } from "@mui/material";

const ProductInventoryDetailComponent = (props) => {
  const {
    fk_organization,
    fk_product,
    updateSelectedSalePoint, // No need to destructure from a second parameter
  } = props;
  const [salePoints, setSalePoints] = useState(props.salePoints);
  const [selectedSalePoint, setSelectedSalePoint] = useState(
    props.selectedSalePoint
  );
  const [product, setProduct] = useState(null);
  const [loadingSalePoints, setLoadingSalePoints] = useState(true);
  const [productInventoryLoading, setProductInventoryLoading] = useState(false);
  const [productInventoryInfo, setProductInventoryInfo] = useState(null);
  const [selectedTab, setSelectedTab] = useState("resumen");

  const handleChangeSalePoint = async (event) => {
    for (let i = 0; i < salePoints.length; i++) {
      if (salePoints[i].id === parseInt(event.target.value)) {
        setSelectedSalePoint(salePoints[i]);
        updateSelectedSalePoint(salePoints[i]);
      }
    }
  };

  const fetchData = () => {
    getProductInfo(fk_product)
      .then((data) => {
        setProduct(data);
        setLoadingSalePoints(false);
      })
      .then(fetchProductInventoryInfo);
  };

  const fetchProductInventoryInfo = () => {
    setProductInventoryLoading(true);
    getProductInventoryDetail(fk_product, selectedSalePoint.id).then((data) => {
      setProductInventoryInfo(data);
    });
    setProductInventoryLoading(false);
  };

  useEffect(() => {
    setLoadingSalePoints(true);
    fetchData();
  }, []);

  useEffect(() => {
    fetchProductInventoryInfo();
  }, [selectedSalePoint]);

  return (
    <>
      <div className="main-product-inventory-detail">
        {loadingSalePoints ? (
          <CircularProgress></CircularProgress>
        ) : (
          <>
            <div className="product-inventory-header">
              <div className="p-i-rowComponent">
              <Link to="/inventarios">
                <ArrowBackIcon></ArrowBackIcon>
              </Link>
              </div>
              <div className="p-i-product-name">
                <h6>{product.name}</h6>
              </div>
            </div>
            <div className="product-inventory-image">
              <img src={product.image_url} alt="Imagen del producto" />
            </div>
            <div className="product-inventory-sale-points">
              <div className="product-inventory-sale-points-content">
                Puntos de Venta
                <select
                  value={selectedSalePoint.id}
                  onChange={handleChangeSalePoint}
                  className="sale-point-form-select"
                >
                  {salePoints.map((salePoint, index) => (
                    <option key={index} value={salePoint.id}>
                      {salePoint.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {productInventoryInfo && !productInventoryLoading && (
              <div className="product-inventory-bar-options">
                <Tabs defaultValue={"resumen"} className="tabs-p-i">
                  <TabList
                    sx={{
                      pt: 0,
                      color: "#0070A1",
                      [`&& .${tabClasses.root}`]: {
                        bgcolor: "transparent",
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                        [`&.${tabClasses.selected}`]: {
                          color: "#0070A1",
                          "&::after": {
                            bgcolor: "#5FCFFF",
                          },
                        },
                        [`&.${tabClasses.colorPrimary}`]: {
                          color: "#0070A1",
                          "&::after": {
                            bgcolor: "#0070A1",
                          },
                        },
                      },
                      "& .oli": {
                        color: "#0070A1",
                        fontSize: "14px",
                      },
                    }}
                  >
                    <Tab value={"resumen"} className="oli">
                      Resumen
                    </Tab>
                    <Tab value={"operaciones"} className="oli">
                      Operaciones
                    </Tab>
                  </TabList>
                  <TabPanel value="resumen">
                    <div className="tab-panel-content">
                      <ResumeProductInvetoryDetail
                        productInfo={productInventoryInfo}
                        product={product}
                      ></ResumeProductInvetoryDetail>
                    </div>
                  </TabPanel>
                  <TabPanel value="operaciones" className="tab-panels">
                    <div className="tab-panel-content">
                      <OperationsProductInvetoryDetail
                        productInfo={productInventoryInfo}
                        product={product}
                        salePoints={salePoints}
                        selectedSalePoint={selectedSalePoint}
                      ></OperationsProductInvetoryDetail>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ProductInventoryDetailComponent;
