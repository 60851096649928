import React, { useState, useEffect } from "react";
import "../../index.scss";
import "./login.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
//import LogoKuruah from "../../assets/img/LOGO-KURUAH.svg";
import LogoMarket from "../../assets/market-icons/logo-login_color.png";
import { TextField, Button } from "@material-ui/core";
import LogInFormHook from "../../services/usersManage/logInHook";
import useUser from "../../services/usersManage/useUser";
import * as Joi from "joi";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const schema = Joi.object({
  username: Joi.string().required(),
  password: Joi.string().pattern(new RegExp("^[a-zA-Z0-9 !@#$%^&*()_+}{|\"?><~`,./;'\\:]{3,30}$")).required()
});

export const LogIn = () => {
  const { handleSubmit, handleInputChange, errors, worngCredential, setCredentialStatus } =
    LogInFormHook(schema);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (worngCredential) {
      setOpen(true);
    }
  }, [worngCredential]);

  const handleClose = () => {
    setOpen(false);
    setCredentialStatus(null);
  };
  return (
    <main className="row principal-section-login">
      <div className="purple-section col-6 center-continer">
        <img src={LogoMarket} alt="logo_kuruah" id="kuruah-logo"/>
      </div>
      <div className="white-section col-6 center-continer">
        <div className="container login-content-container">
          <h1 className="login-title">Iniciar sesión</h1>
          <form action="" onSubmit={handleSubmit}>
            <TextField
              id="standard-basic"
              label="Usuario"
              className="full-input-field"
              name="username"
              onChange={handleInputChange}
            />
            <TextField
              id="standard-basic"
              label="Contraseña"
              className="full-input-field"
              name="password"
              type="password"
              onChange={handleInputChange}
            />

            <div className="row login-options">
              <Button
                variant="contained"
                color="primary"
                className="loginButton"
                type="submit"
                fullWidth
              >
                <FormattedMessage id="loginBtn" />
              </Button>

              {/* <a
                className="prurple-link no-underline space-link"
                href="/olvdiso"
              >
                ¿Olvidaste tu contraseña?
              </a>
              <div className="space-link">
                ¿No estás registrado?{" "}
                <Link to="/subscribe">
                  <a className="prurple-link" href="/">
                    Suscríbete
                  </a>
                </Link>
              </div> */}
            </div>
          </form>

          <div>
            <Snackbar
              severity="error"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={open}
              onClose={handleClose}
              key={"bottomright"}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Usuario o contraseña erróneos
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    </main>
  );
};
