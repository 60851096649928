import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@mui/material";

import { FormattedMessage } from "react-intl";
import "../../../index.scss";
import "./SalePointTypeForm.scss";
import MultiSelectAutocomplete from "../../../components/MultiSelectAutocomplete/MultiSelectAutocomplete";
import FeedbackAlert from "../../../components/FeedbackAlert/FeedbackAlert";
import { Link, useHistory } from "react-router-dom";

import { useRef, useState } from "react";
import { getAllSalePoint } from "../../../services/utilsSalePoint";
import { useEffect } from "react";
import { getProductCategoriesofOrganization } from "../../../services/utilsProductCategory/utilsProductCategory";
import { postSalePointType } from "../../../services/utilsSalePointType";
import CircularProgress from "@mui/material/CircularProgress";

//ICONS
import { ReactSVG } from "react-svg";
import html2canvas from "html2canvas";
// Import necessary libraries (Canvg and html2canvas)
import domtoimage from "dom-to-image";

const imagesIcons = {};

const importAll = (r) => {
  r.keys().forEach((key) => (imagesIcons[key] = r(key)));
};

importAll(
  require.context("../../../assets/market-icons/sptIconsSvg", true, /^\.\/ico-/)
);

const SalePointTypeForm = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------

  const [newSalePointType, setNewSalePointType] = useState({});

  const [salePointOptionList, setSalePointOptionList] = useState([]);

  const [selectedSalePointOptions, setSelectedSalePointOptions] = useState();

  const [productCategoryOptionList, setProductCategoryOptionList] = useState();

  const [selectedImage, setSelectedImage] = useState(null);

  const [submited, setSubmited] = useState(false);

  const [salePointTypeCreatedSuccess, setSalePointTypeCreatedSuccess] =
    useState(false);

  const inputRef = useRef();

  const history = useHistory();

  //sale point icons
  const [selectedColor, setSelectedColor] = useState(null);

  const [selectedImageIcon, setSelectedImageIcon] = useState(null);

  const [svgDataSelected, setDataSvgSelected] = useState();

  /**
   * Snackbar
   */

  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------

  const fetchData = () => {
    let fk_organization = localStorage.getItem("fk_organization");
    getAllSalePoint(fk_organization).then((data) => {
      let transformedData = data.sale_points.map((spt) => {
        return { label: spt.name, value: spt.id + "" };
      });

      setSalePointOptionList(transformedData);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

 

  // const convertSvgToJpg = async () => {
  //   const svgString = '<svg>Your SVG content here</svg>'; // Replace with your actual SVG content

  //   try {
  //     const pngDataUrl = await svgToPng(svgString, { type: 'image/jpeg', encoderOptions: 1 });

  //     // You can now do something with the PNG data URL, such as converting it to a Blob or displaying it.
  //   } catch (error) {
  //     // Handle any errors that occur during the conversion process
  //     console.error('Error converting SVG to JPG:', error);
  //   }
  // };

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------

  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (successSnackbar) {
      setTimeout(() => {
        history.push("/pasillos");
      }, 2000);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmited(true);
    if(!selectedSalePointOptions || !svgDataSelected){
      let message = "";
      if(!selectedSalePointOptions){
        message = "Debe selecionar un punto de venta";
      }
      if(!svgDataSelected){
        message = "Debe selecionar un ícono";
      }
      setSuccessSnackbar(false);
      setSnackbarMessage(
        <Alert severity="warning">{message}</Alert>
      );
      setOpenSnackbar(true);
      setSubmited(false);
    }else{
      let formDataBody = new FormData();
      for (const key in newSalePointType) {
        formDataBody.append(key, newSalePointType[key]);
      }
      /**
       * SalePoints manage
       */
      let transformSelectedOptions = selectedSalePointOptions.map(
        (selectedOptions) => selectedOptions.value
      );
      formDataBody.append("ids_sale_point", transformSelectedOptions.join(","));
  
      formDataBody.append("principal_image", svgDataSelected, "image.png");

      postSalePointType(formDataBody).then((data) => {
        if (data.error) {
          // Handle error message
          setSuccessSnackbar(false);
          setSnackbarMessage(
            <Alert severity="error">Error: {data.error}</Alert>
          );
          setOpenSnackbar(true);
        } else {
          
          setSalePointTypeCreatedSuccess(true);
          setSuccessSnackbar(true);
          setSnackbarMessage(
            <Alert severity="success">
              Se ha creado el pasillo con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        }

        setSubmited(false);
      });
    }

    
  };

  const handleInputChange = (event) => {
    setNewSalePointType({
      ...newSalePointType,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
    }
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setSelectedImageIcon(null);
  };

  const handleImageSelect = (image) => {
    setSelectedImageIcon(image);
  };
  /**
   * Do the converter of the image
   */

  const fetchImageAsBlob = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const convertedBlob = await convertBlobToJPEG(blob);
    return convertedBlob;
  };

  const convertBlobToJPEG = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((jpegBlob) => {
            resolve(jpegBlob);
          }, "image/jpeg");
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = base64data;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  };

  return (
    <>
    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {snackbarMessage}
      </Snackbar>
    <div className="row" id="container-product-form">
      <main className="section-body col">
        <h1 className="section-title">
          <FormattedMessage id={"createSalePointTypeTitle"} />
        </h1>
        <article>
          {salePointOptionList && (
            <form action="" className="row g-3" onSubmit={handleSubmit}>
              <div className="col-6 mb-3">
                <TextField
                  required
                  className="form-control"
                  label={<FormattedMessage id="nameLbl" />}
                  value={newSalePointType.name ? newSalePointType.name : ""}
                  name="name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <TextField
                  className="form-control"
                  label={<FormattedMessage id="descriptionLbl" />}
                  rows={4}
                  multiline
                  value={
                    newSalePointType.description
                      ? newSalePointType.description
                      : ""
                  }
                  name="description"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <label className="form-label" htmlFor="franchiseInput">
                    Ícono
                  </label>
                  <ColorSelector onSelect={handleColorSelect} />
                  {selectedColor && (
                    <ImageSelector
                      color={selectedColor}
                      onSelect={handleImageSelect}
                      setDataSvgSelected={setDataSvgSelected}
                    />
                  )}
                  <div className="icons-container">
                    {/* <img
                      alt=""
                      id="img1"
                      className="icon-sale-point-type active"
                      src={CafeIcon}
                      onClick={handleImageClick}
                    />
                    <img
                      alt=""
                      id="img2"
                      className="icon-sale-point-type"
                      src={DinnerIcon}
                      onClick={handleImageClick}
                    />
                    <img
                      alt=""
                      id="img3"
                      className="icon-sale-point-type"
                      src={StationeryIcon}
                      onClick={handleImageClick}
                    />
                    <img
                      alt=""
                      id="img4"
                      className="icon-sale-point-type"
                      src={UniformIcon}
                      onClick={handleImageClick}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-6 mb-3">
                  <label className="form-label" htmlFor="franchiseInput">
                    o...
                  </label>
                  <div className="row">
                  
                    <input type="file" onChange={handleFileInputChange}  accept="image/png, image/jpeg"/>
                  {selectedImage && <img src={selectedImage} alt={""} />}
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <label className="form-label" htmlFor="franchiseInput">
                    <FormattedMessage id="franchisesLbl" />
                  </label>
                  <MultiSelectAutocomplete
                    options={salePointOptionList}
                    setSelectedOptions={setSelectedSalePointOptions}
                  />
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-12 d-flex justify-content-end">
                  <Link className="" to={`/pasillos`}>
                    <button className="btn btn-outline-primary m-2">
                      <FormattedMessage id="cancelLbl" />
                    </button>
                  </Link>
                  <button className="btn btn-primary m-2" type="submit">
                    {!submited && (
                      <FormattedMessage id={"createSalePointTypelbl"} />
                    )}
                    {submited && <CircularProgress color="inherit" />}
                  </button>
                </div>
              </div>
              <FeedbackAlert
                vertical={"bottom"}
                horizontal={"center"}
                idmessage={"sptCreatedSuccess"}
                open={salePointTypeCreatedSuccess}
                setOpen={setSalePointTypeCreatedSuccess}
              />
            </form>
          )}
        </article>
      </main>
    </div>
    </>
    
  );
};

export default SalePointTypeForm;

//Auxiliar components
const ColorSelector = ({ onSelect }) => {
  /**
   * Handlers
   */

  const inputColorSelect = (event) => {
    const color = event.target.value;
    onSelect(color);
  };
  /**
   * Render
   */
  return (
    <div>
      <p>Selecciona un color para asignarle un ícono:</p>
      <input type="color" onChange={inputColorSelect} />
    </div>
  );
};

const ImageSelector = ({ color, onSelect, setDataSvgSelected }) => {
  const [filteredImages, setFilteredImages] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);

  function filterImagesByColor() {
    const arr = Object.values(imagesIcons);
    return arr;
  }

  useEffect(() => {
    let images = filterImagesByColor();
    setFilteredImages(images);
  }, [color]);

  const handleImageSelect = async (image) => {
    setSelectedImage(image);
    onSelect(image);

    const svgPath = image.default; // Replace with the actual path to your SVG file
    convertSvgToJpg(svgPath, color)
      .then((imgDataUrl) => {
        let blobFile = dataURLToBlob(imgDataUrl);
        setDataSvgSelected(blobFile);
        // Further processing or saving of the image data URL
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="grip-images">
      {filteredImages &&
        filteredImages.map((image, i) => {
          return (
            <div onClick={() => handleImageSelect(image)}>
              <ReactSVG
                src={image.default}
                beforeInjection={(svg) => {
                  // Set the color of the SVG
                  svg.setAttribute("fill", color);
                  svg.setAttribute("width", "70");
                  svg.setAttribute("height", "70");
                }}
                className={
                  selectedImage === image ? "icon-spt active" : "icon-spt"
                }
              />
            </div>
          );
        })}
    </div>
  );
};

const convertSvgToJpg = async (svgPath, color) => {
  const response = await fetch(svgPath);
  const svgText = await response.text();

  const div = document.createElement('div');
  div.innerHTML = svgText;

  const svgElement = div.querySelector('svg');
  svgElement.setAttribute('fill', color);
  svgElement.setAttribute('width', '100');
  svgElement.setAttribute('height', '100');

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Increase resolution for better quality
  const resolutionMultiplier = 2;
  const originalWidth = parseFloat(svgElement.getAttribute('width'));
  const originalHeight = parseFloat(svgElement.getAttribute('height'));

  const width = originalWidth * resolutionMultiplier;
  const height = originalHeight * resolutionMultiplier;

  const scaleRatio = Math.min(width / originalWidth, height / originalHeight);
  const scaledWidth = originalWidth * scaleRatio;
  const scaledHeight = originalHeight * scaleRatio;

  canvas.width = width;
  canvas.height = height;

  const offsetX = (width - scaledWidth) / 2;
  const offsetY = (height - scaledHeight) / 2;

  const svgXML = new XMLSerializer().serializeToString(svgElement);
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = () => {
      context.fillStyle = 'white'; // Set white background color
      context.fillRect(0, 0, width, height);
      context.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }, 'image/jpeg');
    };

    img.onerror = reject;

    img.src = `data:image/svg+xml;base64,${btoa(svgXML)}`;
  });
};

function dataURLToBlob(dataURL) {
  const base64Data = dataURL.split(",")[1];
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: "image/jpeg" });
}
