import React from "react";
import CurrencyFormat from "react-currency-format";
import "./SalePointTypeSalesItem.scss";

const SalePointTypeSalesItem = (props) => {
    let salePointType = props.salePointType;
    return (
        <div className="sales-item">
          <div className="column-image">
            <img
              src={
                salePointType.principal_image ? salePointType.principal_image : ""
              }
              alt=""
              className="icon-image"
            />
          </div>
          <div className="column-spt">
            <p className="name">{salePointType.name}</p>
            <h6 className="price">
              <CurrencyFormat
                value={(salePointType.total_sales) / 100}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </h6>
          </div>
          <div className="column-products">
            <p className="purchaces">{salePointType.total_purchases} <br></br> compras</p>
          </div>
        </div>
      );
}


export default SalePointTypeSalesItem;